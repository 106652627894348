import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import imageSrc from "./img/image.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VaryaMessage>
          Витя, Роби, смотрите, какие красивые туфли! Когда вырасту, у меня
          будет минимум 30 пар разноцветных лодочек на каблуке, чтобы каждый
          день новые. Жалко, у нас в Москве таких не делают.
        </VaryaMessage>
        <VityaMessage>
          Как же не делают?! В нашем городе работает знаменитая фабрика
          «Парижская коммуна», которая, кстати, недавно отметила свое столетие.
        </VityaMessage>
        <RobyMessage>
          Фабрике, конечно, сто лет, но технологии и дизайн там самые передовые.
          Их обувь – одна из лучших в стране.
        </RobyMessage>
        <VaryaMessage>
          Замечательно! Немедленно едем выбирать туфли!
        </VaryaMessage>
        <Image src={imageSrc} alt="Варя" className={cn.image} />
      </WhiteBlock>
    </div>
  );
};

export default Slide;
