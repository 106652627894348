import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";
import classNames from "classnames";
import Background from "games/Kits/Background/Background";
import VaryaGame from "games/Kits/Game/Game/Varya/Varya";
import { FC, useEffect, useState } from "react";
import { chapter_type } from "types";

import light from "../assets/img/light.png";
import cn from "./Game.module.scss";
import barkImg from "./img/bark.png";
import coconutImg from "./img/coconut.png";
import coniferousImg from "./img/coniferous.png";
import cranberryImg from "./img/cranberry.png";
import explanationImg from "./img/explanation.png";
import leafsImg from "./img/leafs.png";

const items = [
  {
    img: coniferousImg,
    title: "Хвойные растения",
    isError: false,
    isExtra: false,
    isSelected: false,
  },
  {
    img: barkImg,
    title: "Кора дерева",
    isError: false,
    isExtra: false,
    isSelected: false,
  },
  {
    img: leafsImg,
    title: "Листья березы",
    isError: false,
    isExtra: false,
    isSelected: false,
  },
  {
    img: cranberryImg,
    title: "Клюква",
    isError: false,
    isExtra: false,
    isSelected: false,
  },
  {
    img: coconutImg,
    title: "Кокос",
    isError: false,
    isExtra: true,
    isSelected: false,
  },
];

type ownProps_type = {
  nextChapter: chapter_type;
  onGameFinished: () => void;
  returnBack: () => void;
};

const Game: FC<ownProps_type> = ({
  nextChapter,
  onGameFinished,
  returnBack,
}) => {
  const [list, setList] = useState(items);
  const [gameState, setGameState] = useState<"static" | "failure" | "finish">(
    "static"
  );
  const [varyaStateData] = useState({
    static:
      "Мы собираем аромат, который называется «Русская усадьба». Посмотри на картинки и найди лишний ингредиент.",
    failure: "Почти получилось! Попробуй еще раз!",
    finish: "Верный выбор! Вряд ли в саду русской усадьбы растут кокосы.",
  });

  useEffect(() => {
    const index = list.findIndex((item) => item.isError);

    if (index !== -1) {
      setGameState((gameState) =>
        gameState === "finish" ? gameState : "failure"
      );

      setTimeout(() => {
        setList((list) => {
          const items = [...list],
            item = { ...items[index] };

          item.isError = false;
          item.isSelected = false;
          items[index] = item;

          return items;
        });
      }, 2000);

      setTimeout(
        () =>
          setGameState((gameState) =>
            gameState === "finish" ? gameState : "static"
          ),
        2000
      );
    }
  }, [list]);

  const handleClick = (index: number) => {
    if (list[index].isSelected) {
      return false;
    }

    setGameState((gameState) =>
      gameState === "finish" ? gameState : "static"
    );

    if (list[index].isExtra) {
      setGameState("finish");
      onGameFinished();
      return false;
    }

    setList((list) => {
      const items = [...list],
        item = { ...items[index] };

      item.isSelected = true;
      item.isError = !item.isExtra;
      items[index] = item;

      return items;
    });
  };

  return (
    <div className={cn.game}>
      <Background
        colorFrom={"#120081"}
        colorTo={"#120081"}
        baseCloudColor="#220CAD"
      >
        <img className={cn.background__light_top} src={light} alt="Свет" />
        <img className={cn.background__light_bottom} src={light} alt="Свет" />
      </Background>
      <div
        className={classNames(cn.items, cn.items_list, {
          [cn.items_hidden]: gameState === "finish",
        })}
      >
        {list.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(cn.items__item, cn.items_list__item, {
                [cn.items__item_error]: item.isError,
              })}
            >
              <div
                className={classNames(
                  cn.items__item__image,
                  cn.items_list__item__image
                )}
                onClick={() => {
                  handleClick(index);
                }}
              >
                <div className={cn.items__item__tooltip}>
                  <InfoIcon />
                  <span>{item.title}</span>
                </div>
                <p className={classNames("title", cn.items__item__title)}>
                  {item.title}
                </p>
                <img src={item.img} alt="Изображение" />
              </div>
            </div>
          );
        })}
      </div>

      {gameState === "finish" && (
        <div className={classNames(cn.items, cn.items_finish)}>
          {list
            .filter((item) => !item.isExtra)
            .map((item, index) => {
              return (
                <div key={index} className={classNames(cn.items__item)}>
                  <div className={cn.items__item__image}>
                    <p className={classNames("title", cn.items__item__title)}>
                      {item.title}
                    </p>
                    <img src={item.img} alt="Изображение" />
                  </div>
                </div>
              );
            })}
        </div>
      )}

      <div
        className={classNames(cn.game__explanation, {
          [cn.items_hidden]: gameState === "finish",
        })}
      >
        <img src={explanationImg} alt="Пояснение" />
        <p>Выбери лишнюю карточку нажатием</p>
      </div>

      <VaryaGame
        gameState={gameState}
        stateData={varyaStateData}
        nextGameLink={nextChapter.link}
        returnBack={returnBack}
      />
    </div>
  );
};

export default Game;
