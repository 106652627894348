import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import engineerImg from "../../assets/img/engineer.png";
import carsImg from "./img/cars.jpg";
import electronicsImg from "./img/electronics.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          На заводе ребятам прежде всего выдали каски и оранжевые жилеты.
        </Text>
        <Message name="Главный инженер" image={engineerImg}>
          У нас тут интенсивное движение.
        </Message>
        <Text>
          И действительно, по цеху сновали погрузчики – желтые и красные, с
          оранжевыми мигалками на крыше. Рядом с высоченным курганом из
          сломанных микроволновок, утюгов и миксеров трудился манипулятор –
          железной лапой он захватывал технику и поднимал на конвейер.
        </Text>
        <Image src={carsImg} alt="Погрузчики" />
        <VaryaMessage>Откуда к вам попадает вся эта техника?</VaryaMessage>
        <Message name="Главный инженер" image={engineerImg}>
          Чаще всего из офисов и магазинов. Видела рекламу «Сдай старый
          холодильник и купи новый со скидкой»?
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#126624" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          ЭТО ИНТЕРЕСНО!
        </p>
        <p>
          Электрохлам составляет около 3% всех отходов, но на него приходится
          70% содержащихся в отходах опасных веществ, поэтому его так важно
          перерабатывать.
        </p>
        <img
          src={electronicsImg}
          alt="Электроника"
          className={cn.block_bottom__electronics}
        />
      </div>
    </div>
  );
};

export default Slide;
