import Intro from "games/Kits/Intro/Intro";
import { FC } from "react";

import iceCream from "./img/ice-cream.svg";
import star from "./img/star.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div className={cn.slide}>
      <Intro
        {...props}
        factoryName="Фабрика «Московское мороженое»"
        title="Холодное сердце"
        intro="Глава, в которой Витя, Варя и Роби учатся делать правильное мороженое"
        withShadow
      >
        <img src={star} className={cn.game__star} alt="Звезда" />
        <img src={iceCream} className={cn.game__iceCream} alt="Мороженое" />
      </Intro>
    </div>
  );
};

export default Slide;
