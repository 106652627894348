import { ComponentProps, FC } from "react";

import Message from "./Message";

const RobyMessage: FC<ComponentProps<"div">> = ({ children, ...restProps }) => {
  return (
    <Message {...restProps} type="Roby">
      {children}
    </Message>
  );
};

export default RobyMessage;
