import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyInfo } from "games/Kits/Characters";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <RobyMessage>
          Так, шмели и клопы у вас работают. А есть ли в теплицах роботы?
        </RobyMessage>
        <Message image={directorImg} name="Директор">
          Наши новые теплицы, по сути, и есть большие роботы. Всем заправляют
          твои коллеги, Роби. Они включают и выключают полив и вентиляцию,
          открывают и закрывают жалюзи, регулируя освещенность, следят за
          влажностью, температурой и освещенностью. Они же перемещают растения
          из одной зоны роста в другую. Помните, как мы сажали салат в горшочки?
          После проращивания он будет целый месяц двигаться по этой теплице,
          пока не превратится во взрослое растение и не отправится в магазин.
        </Message>
        <VaryaMessage>
          Эти разноцветные салатные листья такие красивые, их можно дарить как
          цветы!
        </VaryaMessage>
        <Message image={directorImg} name="Директор">
          Согласен! Но настоящие цветы у нас тоже есть.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D2D2D2" bottomColor="#18446C" doubled />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          15 гектаров теплиц в «Московском» полностью автоматизированы. В цеху
          работают всего 4 человека, которые контролируют качество товара и
          занимаются упаковкой.
        </p>
        <RobyInfo className={cn.block_bottom__roby} />
      </div>
    </div>
  );
};

export default Slide;
