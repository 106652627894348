import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyStatic } from "games/Kits/Characters";
import { FC } from "react";

import backImg from "./img/back.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={backImg} alt="Фон" className={cn.image}>
          <RobyStatic className={cn.image__roby} />
        </Image>
        <VityaMessage>Роби, что ты там возишься с альбомом?</VityaMessage>
        <RobyMessage>
          Да вот, нашел у себя марку с роботом Федором – с тем, которого в
          космос запускали. Хочу добавить ее в коллекцию, а то там никого из
          моих нет. Думаю, прадедушка был бы не против.
        </RobyMessage>
        <VityaMessage>
          Уверен, он бы даже обрадовался. А хочешь увидеть своих собратьев, так
          сказать, живьем? Да и Варе было бы любопытно. Правда, сестренка?
        </VityaMessage>
        <VaryaMessage>Конечно! Чем больше роботов, тем лучше!</VaryaMessage>
        <VityaMessage>
          Тогда поехали в лабораторию «Фора Роботикс».
        </VityaMessage>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
