import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow-next.svg";
import { ReactComponent as CheckIcon } from "assets/images/icons/check-circle.svg";
import { ReactComponent as PlayIcon } from "assets/images/icons/play.svg";
import { ReactComponent as VkIcon } from "assets/images/icons/vk.svg";
import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import Modal from "components/Kits/Modals/Modal/Modal";
import { AppContext } from "context/app-context";
import {
  RobyWinner,
  VaryaHello,
  VaryaWinner,
  VityaHello,
  VityaWinner
} from "games/Kits/Characters";
import { FC, useCallback, useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { VKShareButton } from "react-share";
import { chapter_type } from "types";

import backImg from "./img/back.png";
import backImgMob from "./img/back_mob.png";
import diplomImg from "./img/diplom.png";
import diplomImgMob from "./img/diplom_mob.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  chapters: Array<chapter_type>;
  chaptersCount: number;
  completedChaptersCount: number;
  setFirstSlide: () => void;
  [x: string]: any;
};

const Slide: FC<ownProps_type> = ({
  chapters,
  chaptersCount,
  completedChaptersCount,
  setFirstSlide,
  ...props
}) => {
  const { isMobile } = useContext(AppContext);

  const giftButtonDisabled = useMemo(() => {
    return chaptersCount !== completedChaptersCount;
  }, [chaptersCount, completedChaptersCount]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
  }, [setIsModalVisible]);

  return (
    <>
      <div {...props} className={classNames(props.className, cn.slide)}>
        {isMobile && (
          <div className={cn["dialog-mobile"]}>
            <VaryaMessage>Надеюсь, тебе понравились наши игры!</VaryaMessage>
            <RobyMessage>
              Ты прошел с нами по московским заводам и помог наладить
              производство!
            </RobyMessage>
            <VityaMessage>Ты молодец, настоящий мастер!</VityaMessage>
          </div>
        )}
        <Image
          src={isMobile ? backImgMob : backImg}
          alt="Привет!"
          className={cn.image}
        >
          <VaryaHello className={cn.image__varya} />
          <RobyWinner className={cn.image__roby} />
          <VityaHello className={cn.image__vitya} />
        </Image>
        <div className={cn.games}>
          {chapters
            .filter((chapter) => chapter.type === "game")
            .map((chapter, idx) => (
              <Link
                to={chapter.link}
                key={idx}
                className={classNames(cn.games__item, {
                  [cn.games__item_completed]: chapter.isCompleted,
                })}
              >
                <div className={cn.games__item__icon}>
                  {chapter.isCompleted && (
                    <div className={cn.games__item__icon__check}>
                      <CheckIcon />
                    </div>
                  )}
                  <img src={chapter.icon} alt={chapter.name} />
                  <div className={cn.games__item__play}>
                    <PlayIcon />
                    Играть
                  </div>
                </div>
                <p className={cn.games__item__chapter}>{chapter.title}</p>
                <p className={cn.games__item__title}>{chapter.name}</p>
              </Link>
            ))}
        </div>
        <div className={cn.buttons}>
          <button
            onClick={setFirstSlide}
            className={classNames(cn.buttons__button, cn.buttons__button_back)}
          >
            <ArrowIcon />
          </button>
          <button
            disabled={giftButtonDisabled}
            className={classNames(
              "title",
              cn.buttons__button,
              cn.buttons__button_big
            )}
            onClick={() => setIsModalVisible(true)}
          >
            {isMobile ? "Диплом" : "Получить диплом"}
          </button>
        </div>
      </div>
      {!giftButtonDisabled && (
        <Modal
          visible={isModalVisible}
          closeModal={closeModal}
          closeIconColor="#7E4700"
        >
          <div className={cn.modal}>
            <img src={isMobile ? diplomImgMob : diplomImg} alt="Диплом" />
            <div className={cn.modal__body}>
              <p className={cn.modal__title}>Диплом</p>
              <p>
                За активное участие в проекте «Заводные путешествия» и внесение
                предложений по улучшению производственных процессов московских
                предприятий.
              </p>
              <div className={cn.buttons}>
                <button
                  onClick={setFirstSlide}
                  className={classNames(
                    cn.buttons__button,
                    cn.buttons__button_back
                  )}
                >
                  <ArrowIcon />
                </button>
                <VKShareButton
                  url="https://children.prommoscow.info/"
                  image="https://children.prommoscow.info/share.png"
                >
                  <button
                    className={classNames(
                      "title",
                      cn.buttons__button,
                      cn.buttons__button_big
                    )}
                    onClick={() => setIsModalVisible(true)}
                  >
                    <VkIcon />
                    {isMobile ? "поделиться" : "поделиться вконтакте"}
                  </button>
                </VKShareButton>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Slide;
