import Intro from "games/Kits/Intro/Intro";
import { FC } from "react";

import Yogurt from "./img/yogurt.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div className={cn.slide}>
      <Intro
        factoryName="Лианозовский молочный комбинат"
        title="Творим творог"
        intro="Глава, в которой Витя, Варя и Роби узнают секрет детских творожков и
        понимают, почему их так любят взрослые"
        withShadow
      >
        <img src={Yogurt} className={cn.game__yogurt} alt="Йогурт" />
      </Intro>
    </div>
  );
};

export default Slide;
