import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyStatic } from "games/Kits/Characters";
import { FC } from "react";

import engineerImg from "../../assets/img/engineer.png";
import factoryImg from "./img/factory.png";
import shoeImg from "./img/shoe.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VityaMessage>Позволите?</VityaMessage>
        <Text>Витя потянулся к компьютерной мышке.</Text>
        <Message name="Конструктор" image={engineerImg}>
          Попробуй.
        </Message>
        <VityaMessage>
          Удивительно удобная программа. Смотрите, она позволяет работать с
          верхом, внутренней частью и подошвой обуви по отдельности и сразу же
          передавать выкройку на раскройный комплекс.
        </VityaMessage>
        <Message name="Конструктор" image={engineerImg}>
          Ты мог бы у нас работать!
        </Message>
        <VaryaMessage>Витя – ученый-инженер. Он сам сделал Роби.</VaryaMessage>
        <RobyMessage>
          Мне обувь не нужна, конечно. Но сейчас я об этом даже немного жалею.
          Мне у вас очень нравится.
        </RobyMessage>
        <VityaMessage>
          Нужно сделать для тебя еще один механизм передвижения.
        </VityaMessage>
        <RobyMessage>
          Это было бы прекрасно. И я бы сам для себя разработал обувь.
        </RobyMessage>
        <Image src={factoryImg} alt="Фабрика">
          <div className={cn.roby}>
            <RobyStatic className={cn.roby__image} />
            <img src={shoeImg} alt="Ботинок" className={cn.roby__shoe} />
          </div>
        </Image>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
