import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaDialogue } from "games/Kits/Characters";
import VaryaInvitation from "games/Kits/Game/Invitation/Varya/Varya";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import perfumeImg from "./img/image.png";
import imageSrc from "./img/image2.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Message name="Парфюмер" image={specialistImg}>
          У нас наладили производство флаконов. Ко мне они поступают из Вышнего
          Волочка в Тверской области и из Ижевска.
        </Message>
        <VaryaMessage>И в них вы разливаете растворы, верно?</VaryaMessage>
        <Message name="Парфюмер" image={specialistImg}>
          Нет, Варенька. Ингредиенты приходят ко мне в лабораторию, я их
          смешиваю по своей формуле, получаю концентрат и передаю его на завод.
          Там он две-три недели созревает, за это время ароматные вещества
          умудряются как-то «подружиться». Затем из концентрата готовят
          спиртовой раствор, нагревают его до 25 градусов, остужают до 5 и
          смотрят, не выпадет ли осадок. При необходимости фильтруют и только
          потом разливают по флаконам.
        </Message>
        <Image src={imageSrc} alt="Друзья" />
        <VaryaMessage>Значит, мы так ничего и не увидим?..</VaryaMessage>
        <Message name="Парфюмер" image={specialistImg}>
          Не переживай. Хочешь, я сделаю для тебя флакончик парфюмерной воды с
          ароматом мороженого?
        </Message>
        <VaryaMessage>Очень хочу! Моего любимого, клубничного!</VaryaMessage>
        <Image src={perfumeImg} alt="Изображение">
          <VaryaDialogue className={cn.varya_image} />
        </Image>
        <Text>Девочка захлопала в ладоши от радости.</Text>
      </WhiteBlock>
      <VaryaInvitation />
    </div>
  );
};

export default Slide;
