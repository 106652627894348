import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyInfo } from "games/Kits/Characters";
import VityaInvitation from "games/Kits/Game/Invitation/Vitya/Vitya";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import factoryImg from "./img/factory.png";
import thermometerImg from "./img/thermometer.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          На последнем, пятом заводе с необычным названием «Москабель-Фуджикура»
          производили очень важный для Вари кабель – волоконно-оптический, для
          интернета.
        </Text>
        <Message name="PR-директор" image={specialistImg}>
          В сердце нашего кабеля – проводники из стекла толщиной всего 10
          микрон, в одном кабеле их может быть до тысячи. По ним передаются
          видео, голосовые сообщения и любая цифровая информация.
        </Message>
        <VaryaMessage>
          Я понимаю – провод из меди. Но провод из стекла? Оно же хрупкое.
        </VaryaMessage>
        <Message name="PR-директор" image={specialistImg}>
          Его защищают арамидные нити – вы их можете знать под названием кевлар,
          из него еще делают бронежилеты. А от воды волокна оберегает вот эта
          лента. Если ее чуть намочить, она превращается в гель и разбухает, не
          пропуская воду. Так что без интернета ты, Варя, не останешься.
        </Message>
        <Text>
          Стеклянные волокна в кабеле толщиной всего 10 микрон, это в десять раз
          тоньше человеческого волоса.
        </Text>
        <Image src={factoryImg} alt="Фабрика" />
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#A84147" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          ЭТО ИНТЕРЕСНО!
        </p>
        <p>
          Волоконно-оптический кабель для интернета тестируют при температурах
          от –70 до +60 градусов.
        </p>
        <RobyInfo className={cn.block_bottom__image} />
        <img
          src={thermometerImg}
          alt="Термометр"
          className={cn.block_bottom__thermometer}
        />
      </div>
      <VityaInvitation />
    </div>
  );
};

export default Slide;
