import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import busStopImg from "./img/bus-stop.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          Скучая в пробке, Варя обратила внимание на ярко подсвеченный участок
          пасмурного московского неба.
        </Text>
        <VaryaMessage>
          Смотри, Витя, какое странное сияние. Кажется, будто за лесом села
          летающая тарелка. А может, там пожар?
        </VaryaMessage>
        <RobyMessage>
          Нет, просто мы подъезжаем. Это светятся 55 гектаров теплиц
          «Московского».
        </RobyMessage>
        <Text>
          У предприятия ребят ждал директор по производству «Московского» Андрей
          Радченко.
        </Text>
        <VaryaMessage>
          Покажите, пожалуйста, где у вас капуста кейл? Мне про нее с утра все
          уши прожужжали – какая она полезная и вкусная.
        </VaryaMessage>
        <Message image={directorImg} name="Директор">
          Все посмотрим! А начнем с самого начала – с участка, где сеют семена.
        </Message>
        <Image src={busStopImg} alt="Остановка" />
      </WhiteBlock>
    </div>
  );
};

export default Slide;
