import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow-big.svg";
import { FC, useEffect, useMemo, useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import {
  getActiveChapter,
  getChapters,
  getChaptersCount,
  getCompletedChaptersCount,
  getFirstGameChapter,
  getNextChapter,
  getNextNonCompletedChapter
} from "redux/selectors/app_selector";
import { AppStateType } from "redux/store";

import Menu from "../Menu/Menu";
import { ReactComponent as MenuIcon } from "./assets/menu.svg";
import cn from "./Header.module.scss";

type ownProps_type = {
  isMenuVisible: boolean;
  setIsMenuVisible: (isMenuVisible: boolean) => void;
};

const Header: FC<reduxProps_type & ownProps_type> = ({
  isMenuVisible,
  setIsMenuVisible,
  completedChaptersCount,
  activeChapter,
  nextChapter,
  nextNonCompletedChapter,
  firstGameChapter,
}) => {
  const menuRef = useRef<null | HTMLDivElement>(null),
    menuOpenerRef = useRef<null | HTMLElement>(null);

  const nextLink = useMemo(() => {
    if (!activeChapter) {
      return <></>;
    }

    let linkText = "Следующая глава",
      linkTo = nextChapter?.link;

    if (activeChapter.type !== "game") {
      if (!completedChaptersCount || !nextNonCompletedChapter) {
        linkText = "Начать игру";
        linkTo = firstGameChapter.link;
      } else {
        linkText = "Продолжить игру";
        linkTo = nextNonCompletedChapter?.link;
      }
    }

    return linkTo ? (
      <Link to={linkTo} className={cn.header__link}>
        {linkText} <ArrowIcon />
      </Link>
    ) : (
      <></>
    );
  }, [
    activeChapter,
    completedChaptersCount,
    nextChapter,
    nextNonCompletedChapter,
    firstGameChapter,
  ]);

  useEffect(() => {
    window.onclick = (event: any) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        isMenuVisible &&
        menuOpenerRef.current &&
        !menuOpenerRef.current.contains(event.target)
      ) {
        setIsMenuVisible(false);
      }
    };
  }, [isMenuVisible, menuOpenerRef, setIsMenuVisible]);

  return (
    <>
      <div className={cn.header}>
        <div className={cn.header__wrapper}>
          <span
            ref={menuOpenerRef}
            className={cn.header__menu}
            onClick={() => setIsMenuVisible(true)}
          >
            <MenuIcon />
          </span>
          {activeChapter && (
            <span className={cn.header__chapter}>{activeChapter.title}</span>
          )}
          {activeChapter?.name && (
            <span className={cn.header__name}>{activeChapter.name}</span>
          )}
          {nextLink}
        </div>
      </div>
      <div ref={menuRef}>
        <Menu
          isVisible={isMenuVisible}
          setIsMenuVisible={setIsMenuVisible}
          completedChaptersCount={completedChaptersCount}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  activeChapter: getActiveChapter(state),
  nextChapter: getNextChapter(state),
  nextNonCompletedChapter: getNextNonCompletedChapter(state),
  chapters: getChapters(state),
  chaptersCount: getChaptersCount(state),
  completedChaptersCount: getCompletedChaptersCount(state),
  firstGameChapter: getFirstGameChapter(state),
});

const connector = connect(mapStateToProps, {});
type reduxProps_type = ConnectedProps<typeof connector>;

export default connector(Header);
