import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import VityaInvitation from "games/Kits/Game/Invitation/Vitya/Vitya";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import imageSrc from "./img/image.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          Никто из ребят не ожидал, что переработка окажется таким
          завораживающим зрелищем. Но настало время прощаться с главным
          инженером Сергеем и «Экотехпромом». Ребят ждал завод «Экопласт», где
          их встретил директор Владимир.
        </Text>
        <Image src={imageSrc} alt="Друзья" />
        <Message name="Директор" image={directorImg}>
          Сейчас бульдозер загрузил в сепаратор пластик от корпусов телевизоров,
          телефонов и чайников.
        </Message>
        <VityaMessage>
          Мы видели, как их отправили в шредер на «Экотехпроме».
        </VityaMessage>
        <Message name="Директор" image={directorImg}>
          Да, это они. Сам процесс разделения увидеть не получится: все
          происходит в закрытых резервуарах с соляным раствором. Тяжелые частицы
          оседают на дно, а нужный нам пластик всплывает. Еще несколько ванн с
          растворами разной плотности – и мы разделим его на три вида.
        </Message>
        <VaryaMessage>
          И тогда из него уже можно будет делать новые телевизоры, пластиковые
          рамы и вешалки для одежды?
        </VaryaMessage>
        <Message name="Директор" image={directorImg}>
          Не так быстро. Сначала он попадает вот в этот агрегат, похожий на
          мясорубку. Пластик разогревают до 250 градусов, он становится вязким и
          выдавливается через отверстия, а нож нарезает эти «макароны» на
          аккуратные гранулы. Теперь из них можно делать стройматериалы,
          автомобильные запчасти и другие полезные вещи.
        </Message>
        <VaryaMessage>
          А игрушки? Игрушки можно сделать из этих гранул?
        </VaryaMessage>
        <Message name="Директор" image={directorImg}>
          Вот как раз игрушки нельзя. Ни игрушки, ни медицинские изделия, ни
          упаковку пищевых продуктов из вторичного пластика не изготавливают. А
          вот новый корпус для Роби или бампер для твоего будущего
          электромобиля, Варя, – сколько угодно.
        </Message>
        <VityaMessage>Удовлетворена?</VityaMessage>
        <VaryaMessage>
          Вполне. Теперь я знаю, куда буду относить всю нашу сломанную технику.
        </VaryaMessage>
      </WhiteBlock>
      <VityaInvitation />
    </div>
  );
};

export default Slide;
