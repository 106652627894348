import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaDialogue } from "games/Kits/Characters";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import specialistImg from "../../assets/img/specialist.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Message image={directorImg} name="Директор">
          Так, друзья, идем в японский сад. Здесь у нас растет репа, похожая по
          вкусу на яблоко, и капуста мизуна с очень красивыми узорчатыми
          листьями. Ее еще называют капустой для ленивых: она почти не требует
          ухода. Так что, Роби, предлагаю тебе выращивать на подоконнике именно
          ее.
        </Message>
        <VityaMessage>
          Послушайте, а как же насекомые-вредители? Они же должны слететься на
          вашу сладкую репку, как пчелы на мед.
        </VityaMessage>
        <Message image={directorImg} name="Директор">
          С ними у нас сражается спецотряд насекомых-хищников, которым заведует
          Ольга, главный агроном по защите растений.
        </Message>
        <VaryaMessage>Вы их выращиваете, что ли?</VaryaMessage>
        <Message image={specialistImg} name="Агроном">
          Именно, вот на таких ковриках из пшеницы.
        </Message>
        <VaryaMessage>
          Ой, а я этих клопиков у бабушки в деревне уничтожала, а они,
          оказывается, полезные.
        </VaryaMessage>
        <Message image={specialistImg} name="Агроном">
          Хищных насекомых много, всех ты и не могла знать. Наши малыши
          уничтожают тлей и других вредителей и позволяют нам не опрыскивать
          растения ядохимикатами. Так что можете все пробовать без опаски!
        </Message>
      </WhiteBlock>
      <Clips topColor="#D2D2D2" bottomColor="#18446C" doubled />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          Самый известный хищник среди насекомых – божья коровка. В фазе личинки
          она съедает больше 1200 тлей в день, а когда вырастает – только 40.
        </p>
        <VaryaDialogue className={cn.block_bottom__varya} />
      </div>
    </div>
  );
};

export default Slide;
