import classNames from "classnames";
import { ComponentProps, FC } from "react";

import cn from "./Text.module.scss";

const Text: FC<ComponentProps<"div">> = ({ children, ...restProps }) => {
  return (
    <div {...restProps} className={classNames(cn.text, restProps.className)}>
      {children}
    </div>
  );
};

export default Text;
