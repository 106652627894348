import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";
import classNames from "classnames";
import RobyGame from "games/Kits/Game/Game/Roby/Roby";
import { FC, useEffect, useMemo, useState } from "react";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import { chapter_type } from "types";

import light from "../assets/img/light.png";
import cn from "./Game.module.scss";
import explanationImg from "./img/explanation.png";
import shoe1Img from "./img/shoe_1.png";
import shoe2Img from "./img/shoe_2.png";
import shoe3Img from "./img/shoe_3.png";
import shoe4Img from "./img/shoe_4.png";
import shoe5Img from "./img/shoe_5.png";
import shoe6Img from "./img/shoe_6.png";

const items = [
  {
    img: shoe3Img,
    title: "Пошив верха",
    correctIndex: 2,
    isError: false,
    isHovered: false,
  },
  {
    img: shoe4Img,
    title: "Сборка и придание формы",
    correctIndex: 3,
    isError: false,
    isHovered: false,
  },
  {
    img: shoe5Img,
    title: "Полировка",
    correctIndex: 4,
    isError: false,
    isHovered: false,
  },
  {
    img: shoe1Img,
    title: "Раскройка кожи",
    correctIndex: 0,
    isError: false,
    isHovered: false,
  },
  {
    img: shoe2Img,
    title: "Изготовление стельки",
    correctIndex: 1,
    isError: false,
    isHovered: false,
  },
];

type ownProps_type = {
  nextChapter: chapter_type;
  onGameFinished: () => void;
  returnBack: () => void;
};

const Game: FC<ownProps_type> = ({
  nextChapter,
  onGameFinished,
  returnBack,
}) => {
  const [list, setList] = useState(items);
  const [gameState, setGameState] = useState<"static" | "failure" | "finish">(
    "static"
  );
  const [robyStateData] = useState({
    static:
      "Витя решил купить новые ботинки. Я узнал, какие есть этапы создания обуви. Но они перепутаны. Расставь карточки в правильной последовательности.",
    failure: "Попробуй еще! Ты почти угадал!",
    finish: "Молодец! Ты настоящий эксперт! Приходи работать на фабрику!",
  });

  const gameFinished = useMemo(() => {
    return !list.filter((item, idx) => item.correctIndex !== idx).length;
  }, [list]);

  useEffect(() => {
    if (gameFinished) {
      setGameState("finish");
      onGameFinished();
    }
  }, [gameFinished, onGameFinished]);

  const getListWithSwitchedError = (
    list: any,
    index: number,
    isError: boolean
  ) => {
    const items = [...list],
      item = { ...items[index] };

    item.isError = isError;
    items[index] = item;

    return items;
  };

  const handleDrop = (e: any, dragToIndex: number) => {
    const correctIndex = e.dragData.correctIndex,
      dragFromIndex = e.dragData.index;

    if (dragFromIndex === dragToIndex) {
      return false;
    }

    if (correctIndex !== dragToIndex) {
      setList((list) => getListWithSwitchedError(list, dragFromIndex, true));

      setGameState((gameState) =>
        gameState === "finish" ? gameState : "failure"
      );

      setTimeout(() => {
        setList((list) => getListWithSwitchedError(list, dragFromIndex, false));
      }, 1000);

      setTimeout(() => {
        setGameState((gameState) =>
          gameState === "finish" ? gameState : "static"
        );
      }, 4000);

      return false;
    }

    setList((list) => {
      const items = [...list];

      [items[dragFromIndex], items[dragToIndex]] = [
        items[dragToIndex],
        items[dragFromIndex],
      ];
      return items;
    });
  };

  const setIsHovered = (index: number) => {
    setList((list) => {
      const items = [...list],
        item = { ...items[index] };

      item.isHovered = !item.isHovered;
      items[index] = item;

      return items;
    });
  };

  const setAllUnhoverd = () => {
    setList((list) => {
      list.map((item) => (item.isHovered = false));

      return list;
    });
  };

  return (
    <div className={cn.game}>
      <div className={cn.background}>
        <div className={cn.background__color}></div>
        <img className={cn.background__light_top} src={light} alt="Свет" />
        <img className={cn.background__light_bottom} src={light} alt="Свет" />
      </div>
      <div className={cn.cards}>
        {list.map((item, index) => {
          return (
            <DropTarget
              targetKey="drop"
              onHit={(e: any) => handleDrop(e, index)}
              onDragEnter={() => setIsHovered(index)}
              onDragLeave={() => setIsHovered(index)}
              data-index={index}
              key={index}
            >
              <div
                key={index}
                className={classNames(cn.cards__item, {
                  [cn.cards__item_correct]: index === item.correctIndex,
                  [cn.cards__item_candrop]: item.isHovered,
                  [cn.cards__item_error]:
                    item.isError && index !== item.correctIndex,
                })}
              >
                <DragDropContainer
                  targetKey="drop"
                  noDragging={index === item.correctIndex}
                  onDragStart={() => {
                    setGameState((gameState) =>
                      gameState === "finish" ? gameState : "static"
                    );
                  }}
                  onDragEnd={setAllUnhoverd}
                  dragData={{ ...item, index }}
                >
                  <div
                    className={cn.cards__item__image}
                    draggable={index !== item.correctIndex}
                  >
                    <div className={cn.cards__item__tooltip}>
                      <InfoIcon />
                      <span>{item.title}</span>
                    </div>
                    <p className={classNames("title", cn.cards__item__title)}>
                      {item.title}
                    </p>
                    <img src={item.img} alt={item.title} draggable={false} />
                  </div>
                </DragDropContainer>
              </div>
            </DropTarget>
          );
        })}
        <div className={classNames(cn.cards__item, cn.cards__item_last)}>
          <div className={cn.cards__item__image}>
            <img src={shoe6Img} alt="Ботинок" draggable={false} />
          </div>
        </div>
        <div
          className={classNames(cn.cards__last, {
            [cn.cards__last_hidden]: gameState === "finish",
          })}
        >
          <img src={explanationImg} alt="Пояснение" />
          <p>Перемещай карточки перетягиванием</p>
        </div>
      </div>

      <RobyGame
        gameState={gameState}
        stateData={robyStateData}
        nextGameLink={nextChapter.link}
        returnBack={returnBack}
      />
    </div>
  );
};

export default Game;
