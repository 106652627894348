import Intro from "games/Kits/Intro/Intro";
import { FC } from "react";

import wiresImg from "./img/wires.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div className={cn.slide}>
      <Intro
        factoryName="Завод «Москабельмет»"
        title="Войти в сеть"
        intro="Глава, в которой Витя, Варя и Роби узнают, как производят медный кабель и что скрывает Эйфелева башня"
      >
        <img src={wiresImg} className={cn.game__wires} alt="Провода" />
      </Intro>
    </div>
  );
};

export default Slide;
