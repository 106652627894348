import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaGlasses } from "games/Kits/Characters";
import { FC } from "react";

import factoryImg from "./img/factory.jpg";
import harryImg from "./img/harry.png";
import imageSrc from "./img/image.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={imageSrc} alt="Роби и Варя" />
        <VaryaMessage>
          Роби, а что это за странный человек в очках как у Гарри Поттера?
        </VaryaMessage>
        <RobyMessage>
          Это Махатма Ганди, борец за освобождение Индии. Очкариков на марки
          помещают довольно часто, а вот рисунков собственно очков почти не
          найти. Например, с 50-х по 70-е годы прошлого века было выпущено 75
          тысяч марок, а очки изображены только на двух: одну напечатали в
          Пакистане, а вторую в Германии. А мне как раз совершенно необходимы
          очки.
        </RobyMessage>
        <VityaMessage>
          Так их надо не на марках искать, а в магазинах оптики. Или там, где их
          делают.
        </VityaMessage>
        <RobyMessage>
          Тогда предлагаю съездить на производство компании «Айкрафт». Согласно
          моим данным, сегодня это лидер оптического рынка России.
        </RobyMessage>
        <VaryaMessage>
          Давайте! Я себе тоже очки хочу, солнцезащитные.
        </VaryaMessage>
        <Image src={factoryImg} alt="Фабрика" className={cn.factory}>
          <VaryaGlasses className={cn.factory__varya} />
        </Image>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#09726D" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          На заводе «Айкрафт» много моделей для детей. Нежные розовые и голубые
          – для юных принцесс. Зеленые и желтые – для энергичных весельчаков.
          Черные и коричневые – для серьезных отличников. А для поклонников
          Гарри Поттера – круглые!
        </p>
        <img
          src={harryImg}
          alt="Гарри Поттер"
          className={cn.block_bottom__harry}
        />
      </div>
    </div>
  );
};

export default Slide;
