import classNames from "classnames";
import Carousel from "components/Blocks/Carousel/Carousel";
import Text from "components/Blocks/Text/Text";
import { FC, useEffect } from "react";

import cn from "./Slide.module.scss";

const slides = [
  "SAM06219.jpg",
  "SAM06325.jpg",
  "SAM06484.jpg",
  "SAM06962.jpg",
  "SAM07142.jpg",
  "SAM07184.jpg",
  "SAM07335.jpg",
  "SAM07355.jpg",
  "SAM07418.jpg",
];

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = ({
  changeBackColor,
  changeCloudsColor,
  ...props
}) => {
  useEffect(() => {
    changeBackColor("#DE5DC9", "#D037B8");
    changeCloudsColor("#D037B8 ");

    return () => {
      changeBackColor();
      changeCloudsColor();
    };
  }, [changeBackColor, changeCloudsColor]);

  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <Text className={classNames("title", cn.slide__title)}>
        Ребята уже привычно переоделись в одноразовые комбинезоны, шапочки и
        бахилы и даже прошли по специальной дорожке, которая вымыла и почистила
        им обувь.
      </Text>
      <Carousel
        slides={slides}
        dir="Mos_morozhennoe"
        arrowColor="rgba(225, 58, 208, 0.6)"
        arrowColorHover="rgba(225, 58, 208, 0.3)"
      />
    </div>
  );
};

export default Slide;
