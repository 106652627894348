import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import factoryImg from "./img/factory.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VaryaMessage>На лугу пасутся ко…</VaryaMessage>
        <RobyMessage>Коровы?</RobyMessage>
        <VaryaMessage>
          Правильно, коровы! Пейте, дети, молоко – будете здоровы!
        </VaryaMessage>
        <VityaMessage>
          Я так понимаю, самое время отправиться на Лианозовский молочный
          комбинат. Посмотрим, как готовят творожки и йогурты.
        </VityaMessage>
        <VaryaMessage>И продегустируем.</VaryaMessage>
        <RobyMessage>
          Лично меня интересует исключительно технология процесса.
        </RobyMessage>
        <Image src={factoryImg} alt="Фабрика" className={cn.factory} />
      </WhiteBlock>
    </div>
  );
};

export default Slide;
