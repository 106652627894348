import classNames from "classnames";
import { FC } from "react";

import iceCreamImg from "./img/ice-cream.png";
import shoeImg from "./img/shoe.png";
import wiresImg from "./img/wires.png";
import yogurtImg from "./img/yogurt.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <p className={classNames("title", cn.title)}>
        Об этом Департамент инвестиционной и промышленной политики города Москвы
        выпустил целую серию мультфильмов, которые ты легко найдешь в интернете
        по хештегу #моспром и на нашем сайте в разделе{" "}
        <a
          href="https://prommoscow.info/publication/TV/for_children/"
          target="_blank"
          rel="noreferrer"
        >
          для детей
        </a>
      </p>
      <p>
        В нашей электронной книге ты узнаешь, как благодаря альбому с марками
        Витиного и Вариного прадедушки ребята побывали на четырнадцати
        предприятиях Москвы.
      </p>
      <p className={cn.subtitle}>Среди них:</p>
      <div className={cn.list}>
        <div className={cn.list__item}>
          <div className={cn.list__item__image}>
            <img src={yogurtImg} alt="Йогурт" />
          </div>
          <p className="title">
            Лианозовский молочный комбинат, выпускающий знаменитую «Агушу»
          </p>
        </div>
        <div className={cn.list__item}>
          <div className={cn.list__item__image}>
            <img src={wiresImg} alt="Провода" />
          </div>
          <p className="title">
            Завод «Москабельмет», по проводам которого к нам в дом приходят
            электричество и интернет
          </p>
        </div>
        <div className={cn.list__item}>
          <div className={cn.list__item__image}>
            <img src={iceCreamImg} alt="Мороженое" />
          </div>
          <p className="title">
            Фабрика мороженого, где в год делают 16 тонн мороженого
          </p>
        </div>
        <div className={cn.list__item}>
          <div className={cn.list__item__image}>
            <img src={shoeImg} alt="Ботинок" />
          </div>
          <p className="title">«Парижская коммуна», где Варе сшили туфельки</p>
        </div>
      </div>
      <p className={classNames("title", cn.title)}>
        Словом, любознательная компания увидела много всего нового и
        интересного!
      </p>
    </div>
  );
};

export default Slide;
