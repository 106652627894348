import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import Clips from "components/Blocks/Clips/Clips";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaDialogue } from "games/Kits/Characters";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          Из холодильника ребят провели на участок разделки. Здесь в дело
          включались вооруженные ножами обвальщики. Рабочие в шапочках, голубых
          халатах, перчатках и нарукавниках выглядели вполне мирно, но
          впечатлительной Варе все равно представились пираты с кривыми ножами
          из сказки про Айболита.
        </Text>
        <Message image={directorImg} name="Директор">
          У нас здесь главное – техника безопасности. Все здешние сотрудники
          носят фартуки и перчатки из кольчуги – при всем желании не порежешься.
          Посмотри, сколько женщин у нас на конвейере. Видишь? Без них на стол
          не попадут твои любимые сосиски.
        </Message>
        <Text>
          При этих словах директора пираты куда-то испарились, а Варя выдохнула
          с облегчением.
        </Text>
        <Message image={directorImg} name="Директор">
          Дальше мясо по конвейеру попадает к сотрудникам, которые убирают все
          грубые жилы, что тоже важно. Ведь на следующем участке сырье ждет
          мясорубка, а даже самая мощная мясорубка любит деликатный подход.
          Кстати, наши мясорубки перерабатывают 200 кг мяса всего за 2 минуты. А
          теперь мы отправимся на участок производства колбас.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8 " />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          Скорость вращения ножей в машине по производству колбасного фарша –
          3500 оборотов в минуту.
        </p>

        <VaryaDialogue className={cn.block_bottom__varya} />
      </div>
    </div>
  );
};

export default Slide;
