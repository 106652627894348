import classNames from "classnames";
import Carousel from "components/Blocks/Carousel/Carousel";
import Text from "components/Blocks/Text/Text";
import { FC, useEffect } from "react";

import cn from "./Slide.module.scss";

const slides = [
  "DSC_2760.jpg",
  "DSC_2867.jpg",
  "DSC_3153.jpg",
  "DSC_3158.jpg",
  "DSC_3204.jpg",
  "DSC_3573.jpg",
  "DSC_3682.jpg",
  "slide_01.jpg",
  "slide_02.jpg",
  "slide_03.jpg",
];

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = ({
  changeBackColor,
  changeCloudsColor,
  ...props
}) => {
  useEffect(() => {
    changeBackColor("#FF000F", "#FF000F");
    changeCloudsColor("#FF333F");

    return () => {
      changeBackColor();
      changeCloudsColor();
    };
  }, [changeBackColor, changeCloudsColor]);

  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <Text className={classNames("title", cn.slide__title)}>
        Прогулка по заводу «Дымов»
      </Text>
      <Carousel
        slides={slides}
        dir="Dymov"
        arrowColor="rgba(255, 51, 63, 0.6)"
        arrowColorHover="rgba(255, 51, 63, 0.3)"
      />
    </div>
  );
};

export default Slide;
