import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyStatic } from "games/Kits/Characters";
import { FC } from "react";

import iceCream from "../../assets/img/ice-cream.png";
import technologistImg from "../../assets/img/technologist.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <img src={iceCream} alt="Мороженое" className={cn.white__iceCream} />
        <VaryaMessage>
          А шоколад? Как искупать эскимо в горячем шоколаде, чтобы оно не
          растаяло?
        </VaryaMessage>
        <Message name="Технолог" image={technologistImg}>
          Ну, во-первых, не такой уж он и горячий – всего 46 градусов. А
          во-вторых, робот погружает эскимо в глазурь всего на пару секунд, так
          что растаять оно не успевает. Затем мы мороженое упаковываем,
          раскладываем по коробкам, и вот на этом конвейере оно уезжает на
          склад, там у нас холодно: –35. А теперь самое интересное – лаборатория
          мороженого. Познакомьтесь с нашим дегустатором Натальей.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D2D2D2" bottomColor="#18446C" doubled />
      <div className={classNames("block", cn.interesting)}>
        <p
          style={{ fontSize: 48, lineHeight: "61px", marginBottom: 30 }}
          className="title"
        >
          СПРАВКА
        </p>
        <p className={cn.interesting__text}>
          Мороженое – это полезный продукт, в нем много белка, витаминов,
          кальция и фосфора. К тому же технологи следят, чтобы в 100 граммах
          мороженого было не больше 250 килокалорий. Так что ешьте на здоровье!
        </p>
        <RobyStatic className={cn.interesting__image} />
      </div>
    </div>
  );
};

export default Slide;
