import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import classNames from "classnames";
import { FC, useCallback, useEffect } from "react";

import c from "./Modal.module.scss";

type props_type = {
  visible?: boolean;
  children: React.ReactNode;
  closeModal: () => void;
  closeIconColor?: string;
};

const Modal: FC<props_type> = ({
  children,
  visible = false,
  closeModal,
  closeIconColor,
}) => {
  const close = useCallback(closeModal, [closeModal]);

  useEffect(() => {
    const trackModalEscClick = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        close();
      }
    };

    document.addEventListener("keydown", trackModalEscClick);

    return () => {
      document.removeEventListener("keydown", trackModalEscClick);
    };
  }, [close]);

  return (
    <div
      className={classNames([c.modalBack, { [c.modalBack_visible]: visible }])}
    >
      <div className={c.modal}>
        <div className={c.modal__body}>{children}</div>
        <div className={c.modal__actions}>
          <span onClick={close}>
            <CloseIcon fill={closeIconColor} className={c.modal__close} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Modal;
