import Intro from "games/Kits/Intro/Intro";
import { FC } from "react";

import garbageImg from "./img/garbage.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div className={cn.slide}>
      <Intro
        factoryName="Корпорация «Экополис»"
        title="Золотоискатели"
        intro="Глава, в которой Варя, Витя и Роби узнают, как перерабатывается мусор "
      >
        <img src={garbageImg} className={cn.game__garbage} alt="Мусор" />
      </Intro>
    </div>
  );
};

export default Slide;
