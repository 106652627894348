import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import flameImg from "./img/flame.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>Юрий встретил ребят около огромного бело-голубого здания. </Text>
        <Message name="Эколог" image={specialistImg}>
          Добро пожаловать в «Биосферу»! Этот комплекс очищает всю воду, которую
          использует предприятие. Сейчас мы с вами стоим на крыше бассейна в
          полтора раза больше Патриарших прудов – в нем 33 тысячи кубометров.
          Здесь у нас живут бактерии, которые питаются продуктами
          нефтепереработки.
        </Message>
        <VaryaMessage>А им вкусно?</VaryaMessage>
        <Message name="Эколог" image={specialistImg}>
          Ты не поверишь, им очень вкусно! Потом вода попадает вот в этот цех.
          Варя, у вас на кухне есть угольный фильтр?
        </Message>
        <VaryaMessage>
          Конечно! Мама не разрешает в чайник воду из-под крана наливать.
        </VaryaMessage>
        <Message name="Эколог" image={specialistImg}>
          Так вот, здесь у нас 22 огромных угольных фильтра, в них почти 200
          тонн активированного угля. Это второй этап очистки. А на третьем –
          вода проходит через полторы тысячи мембран, после чего ее можно даже
          пить, но мы отправляем ее обратно в производство. В результате расход
          воды на нашем предприятии снизился в три раза.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#404E5C" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          Раньше символом Капотни был постоянно горящий факел — это сжигали газ,
          образовывающийся при переработке нефти. Сейчас факел горит не больше
          24 часов в год — весь газ используется для нужд предприятия.
        </p>
        <img src={flameImg} alt="Факел" className={cn.block_bottom__flame} />
      </div>
    </div>
  );
};

export default Slide;
