import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VaryaMessage>
          А эти фигурки у вас, случайно, не дети рисовали? Я так тоже могу!
        </VaryaMessage>
        <Message name="Директор" image={directorImg}>
          Нет, это Политехнический музей делает книжки для слабовидящих детей. И
          как в любых других книжках, в них должны быть иллюстрации. Так вот,
          они печатают на наших принтерах объемные изображения, а потом
          приклеивают их на страницы.
        </Message>
        <VaryaMessage>
          Здорово! По ним можно водить пальчиком и представлять, как выглядят
          герои. А можно мне примерить вон тот браслет?
        </VaryaMessage>
        <VityaMessage>Похоже, серебряный.</VityaMessage>
        <Message name="Директор" image={directorImg}>
          Это не серебро, а серебристый пластик. И это тоже напечатано. Есть
          один дизайнер из Уфы, который занимается восстановлением башкирского
          национального костюма. А он включает в себя большое количество
          металлических деталей, которые раньше делали вручную. Изготовить вот
          такие ажурные сережки на станке невозможно. Но тут дизайнер
          познакомился с нашими 3D-принтерами, и дело пошло. К тому же этот
          пластик на первый взгляд невозможно отличить от металла, он даже
          переливается на солнце. А костюм в итоге получается значительно легче.
        </Message>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
