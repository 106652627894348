import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import engineerImg from "../../assets/img/engineer.png";
import specialistImg from "../../assets/img/specialist.png";
import imageSrc from "./img/image.jpg";
import shoeImg from "./img/shoe.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <RobyMessage>
          А можно познакомиться с художниками-конструкторами, которые
          придумывают обувь? Всегда хотел попробовать себя в дизайне. Видите ли,
          в моей цифровой личности явно страдает творческая составляющая.
        </RobyMessage>
        <Image src={imageSrc} alt="Роби" />
        <Message name="Начальник раскройного цеха" image={specialistImg}>
          Пойдемте в Центр моделирования, у нас там сейчас как раз рисуют эскиз
          новых кроссовок. Представлю вас конструктору Михаилу.
        </Message>
        <Message name="Конструктор" image={engineerImg}>
          Сначала я рисую эскиз на бумаге. А потом, продумав все детали,
          переношу в специальную компьютерную программу, которая не только
          создаст точную выкройку модели и распланирует технологические
          операции, но и посчитает расход материалов, так что сразу можно
          заказать нужное количество.
        </Message>
        <VaryaMessage>
          А вы часто видите на улице людей в вашей обуви? Наверное, это очень
          здорово.
        </VaryaMessage>
        <Message name="Конструктор" image={engineerImg}>
          Не то слово. Хочется просто подойти и расцеловать этого человека как
          родного.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#17694B" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          В музее фабрики выставлены колодки Леонида Брежнева и Никиты Хрущева –
          главам государства не приходилось приезжать на примерки.
        </p>

        <img src={shoeImg} className={cn.block_bottom__shoe} alt="Ботинок" />
      </div>
    </div>
  );
};

export default Slide;
