import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import constructorImg from "../../assets/img/constructor.png";
import astronautImg from "./img/astronaut.png";
import rocketImg from "./img/rocket.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={rocketImg} alt="Ракета" className={cn.image} />
        <RobyMessage>
          А как делают эти огромные ступени? Мне кажется, людям это просто не по
          силам.
        </RobyMessage>
        <Message name="Конструктор" image={constructorImg}>
          Понимаю, к чему ты ведешь, Роби. Конечно же, у нас работают роботы.
          Вот этот цех за месяц может произвести около трехсот крупногабаритных
          элементов конструкции ракеты. Смотрите, как раз сейчас робот
          обрабатывает пластину из дюралюминия длиной 7 метров для топливного
          бака. Человеку бы на это потребовалось больше недели, а робот
          управится за смену.
        </Message>
        <Text>
          Вдруг в дальнем конце цеха раздался оглушительный грохот. Варя
          отпрыгнула, и даже Роби пригнулся.
        </Text>
        <VityaMessage>Что это? Что-то взорвалось?</VityaMessage>
        <Message name="Конструктор" image={constructorImg}>
          Да, взорвалось. Но не пугайтесь: там сваривают шпангоуты топливных
          баков. Это такие ребра жесткости, которые держат всю конструкцию. На
          них ложится огромная нагрузка и ответственность, поэтому для сварки мы
          используем управляемый взрыв. Такой шов выдерживает любые космические
          нагрузки. А всего на конструкциях первой ступени почти 300 метров
          сварных швов, и ни один из них не должен иметь даже малейшего дефекта,
          поэтому все швы проверяются рентгеном.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#4E7F60" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          Запуск ракеты может увидеть любой желающий. Для этого достаточно
          добраться до космодрома, купить билет и присоединиться к экскурсии.
        </p>
        <img src={astronautImg} alt="Шлем" className={cn.block_bottom__image} />
      </div>
    </div>
  );
};

export default Slide;
