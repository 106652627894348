import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";
import classNames from "classnames";
import { AppContext } from "context/app-context";
import VaryaGame from "games/Kits/Game/Game/Varya/Varya";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import { chapter_type } from "types";

import light from "../assets/img/light.png";
import cn from "./Game.module.scss";
import bacteriaImg from "./img/bacteria.png";
import directionImg from "./img/direction.png";
import directionMobileImg from "./img/direction_mobile.png";
import dyeImg from "./img/dye.png";
import explanationImg from "./img/explanation.png";
import lemonImg from "./img/lemon.png";
import milkImg from "./img/milk.png";
import potFilledImg from "./img/pot-filled.png";
import potImg from "./img/pot.png";
import rowanImg from "./img/rowan.png";
import strawberryImg from "./img/strawberry.png";

const items = [
  {
    background: "linear-gradient(180deg, #52A49A 0%, #8EFCC7 100%)",
    img: bacteriaImg,
    tooltip: "Бактерии",
    canDrop: true,
    isError: false,
    isDropped: false,
  },
  {
    background: "linear-gradient(180deg, #8D67FF 0%, #5530BE 100%)",
    img: rowanImg,
    tooltip: "Черноплодная рябина",
    canDrop: true,
    isError: false,
    isDropped: false,
  },
  {
    background: "linear-gradient(180deg, #9F10A3 0%, #E017E5 100%)",
    img: dyeImg,
    tooltip: "Пищевой краситель",
    canDrop: false,
    isError: false,
    isDropped: false,
  },
  {
    background: "linear-gradient(180deg, #FBDB71 0%, #EEC143 100%)",
    img: lemonImg,
    tooltip: "Лимонный сок",
    canDrop: true,
    isError: false,
    isDropped: false,
  },
  {
    background: "linear-gradient(180deg, #FE1E11 0%, #FF7B56 100%)",
    img: strawberryImg,
    tooltip: "Клубничное пюре",
    canDrop: true,
    isError: false,
    isDropped: false,
  },
  {
    background: "linear-gradient(180deg, #5DA1E2 0%, #2465A0 100%)",
    img: milkImg,
    tooltip: "Молоко",
    canDrop: true,
    isError: false,
    isDropped: false,
  },
];

type ownProps_type = {
  nextChapter: chapter_type;
  onGameFinished: () => void;
  returnBack: () => void;
};

const Game: FC<ownProps_type> = ({
  nextChapter,
  onGameFinished,
  returnBack,
}) => {
  const { isMobile } = useContext(AppContext);

  const [list, setList] = useState(items);
  const [gameState, setGameState] = useState<
    "start" | "static" | "failure" | "finish"
  >("start");
  const [isDragging, setIsDragging] = useState(false);
  const [isDragOverTarget, setIsDragOverTarget] = useState(false);
  const [varyaStateData] = useState({
    start:
      "Мы пришли на производство детского пюре «Агуша» и решили разобраться, из чего его делают. Внимательно посмотри на картинки и перетащи правильные ингредиенты в баночку.",
    static: "Перетащи правильные ингредиенты в баночку.",
    failure: "Ты почти угадал! Попробуй еще раз! ",
    finish:
      "Правильно! Чужак в этой компании – пищевой краситель. Розовый цвет йогурту придают клубничное пюре и сок черноплодной рябины.",
  });

  const gameFinished = useMemo(() => {
    return !list.filter((item) => !item.isDropped && item.canDrop).length;
  }, [list]);

  useEffect(() => {
    const index = list.findIndex((item) => item.isError);

    if (index !== -1) {
      setTimeout(() => {
        setList((list) => {
          const items = [...list],
            item = { ...items[index] };

          item.isError = false;
          items[index] = item;

          return items;
        });
      }, 1000);

      setTimeout(
        () =>
          setGameState((gameState) =>
            gameState === "finish" ? gameState : "static"
          ),
        4000
      );
    }
  }, [list]);

  const handleDrop = (e: any) => {
    if (e.dragData.canDrop) {
      e.containerElem.style.visibility = "hidden";

      setList((list) => {
        const items = [...list],
          item = { ...items[e.dragData.index] };

        item.isDropped = true;
        items[e.dragData.index] = item;

        return items;
      });

      return false;
    }

    setGameState((gameState) =>
      gameState === "finish" ? gameState : "failure"
    );

    setList((list) => {
      const items = [...list],
        item = { ...items[e.dragData.index] };

      item.isError = true;
      items[e.dragData.index] = item;

      return items;
    });
  };

  useEffect(() => {
    if (gameFinished) {
      setGameState("finish");
      onGameFinished();
    }
  }, [gameFinished, onGameFinished]);

  return (
    <div className={cn.game}>
      <div className={cn.background}>
        <div className={cn.background__color}></div>
        <img className={cn.background__light_top} src={light} alt="Свет" />
        <img className={cn.background__light_bottom} src={light} alt="Свет" />
      </div>
      <div
        className={classNames(cn.cards, {
          [cn.cards_hidden]: gameState === "finish",
        })}
      >
        {list.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(cn.cards__item, {
                [cn.cards__item_error]: item.isError,
              })}
            >
              <DragDropContainer
                targetKey="drop"
                onDragStart={() => {
                  setIsDragging(true);
                  setGameState((gameState) =>
                    gameState === "finish" ? gameState : "static"
                  );
                }}
                onDragEnd={() => {
                  setIsDragging(false);
                  setIsDragOverTarget(false);
                }}
                dragData={{ ...item, index }}
              >
                <div className={cn.cards__item__tooltip}>
                  <InfoIcon />
                  <span>{item.tooltip}</span>
                </div>
                <div
                  className={cn.cards__item__image}
                  style={{
                    background: item.background,
                  }}
                >
                  <img src={item.img} alt={item.tooltip} draggable={false} />
                </div>
              </DragDropContainer>
            </div>
          );
        })}
      </div>
      <img
        src={isMobile ? directionMobileImg : directionImg}
        alt="Стрелки"
        className={classNames(cn.direction, {
          [cn.direction_hidden]: gameState === "finish",
        })}
      />
      <div
        className={classNames(cn.game__explanation, {
          [cn.cards_hidden]: gameState === "finish",
        })}
      >
        <img src={explanationImg} alt="Пояснение" />
        <p>Перемещай карточки перетягиванием</p>
      </div>
      <DropTarget
        targetKey="drop"
        onDragEnter={() => setIsDragOverTarget(true)}
        onDragLeave={() => setIsDragOverTarget(false)}
        onHit={handleDrop}
      >
        <img
          src={potImg}
          alt="Банка"
          className={classNames(cn.pot, {
            [cn.pot_shadow]: isDragging,
            [cn.pot_hovered]: isDragOverTarget,
            [cn.pot_hidden]: gameState === "finish",
          })}
        />
      </DropTarget>
      <div
        className={classNames(cn.finish, {
          [cn.finish_visible]: gameState === "finish",
        })}
      >
        <img src={potFilledImg} alt="Банка" />
      </div>

      <VaryaGame
        gameState={gameState}
        stateData={varyaStateData}
        nextGameLink={nextChapter.link}
        returnBack={returnBack}
      />
    </div>
  );
};

export default Game;
