import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import image from "./img/image.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={image} alt="Наураша" />
        <Text>Олег подсоединил датчик в виде божьей коровки к USB-порту.</Text>
        <VaryaMessage>Какая хорошенькая! А почему божья коровка?</VaryaMessage>
        <Message name="Директор" image={directorImg}>
          Дело в том, что сейчас обучение начинается еще в детском саду. Дети
          четырех-пяти лет уже вполне могут играть с Наурашей, поэтому мы и
          хотели сделать для них самый веселый в мире датчик.
        </Message>
        <VityaMessage>
          Да о какой науке можно говорить с детьми в четыре года?
        </VityaMessage>
        <Message name="Директор" image={directorImg}>
          Не обижайте наших малышей! В этом возрасте они вполне могут померить,
          при какой температуре замерзает вода, узнать, чем различаются звуки
          ксилофона и губной гармошки, или, например, покрутить ручку
          генератора, увидеть загоревшуюся лампочку и понять, что механическую
          энергию легко превратить в электрическую. А потом они приходят в
          первый класс – и там их снова встречает уже знакомый Наураша со своими
          божьими коровками.
        </Message>
        <RobyMessage>А где вы изготавливаете датчики?</RobyMessage>
        <Message name="Директор" image={directorImg}>
          Электронную начинку нам поставляют партнеры, а корпуса мы печатаем
          здесь на 3D-принтерах. Пойдемте покажу.
        </Message>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
