import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import image1Src from "./img/image1.jpg";
import image2Src from "./img/image2.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VaryaMessage>
          Роби, смотри, какая смешная марка! Я бы тоже везде и всюду требовала
          сосиски. И еще мне всегда было интересно, как это они получаются
          такими одинаковыми? А правда, что молочные сосиски такие вкусные,
          потому что в них много молока?
        </VaryaMessage>
        <VityaMessage>
          Варя, не части. Давай лучше попросим Роби устроить нам экскурсию на
          завод колбас и деликатесов «Дымов» и все узнаем.
        </VityaMessage>
        <Image src={image1Src} alt="Друзья" />
        <VaryaMessage>
          Едем! Заодно пообедаем. У них ведь найдутся сосиски и для нас?
        </VaryaMessage>
        <RobyMessage>
          Московская производственная площадка группы компаний «Дымов» выпускает
          350 километров сосисок в месяц.
        </RobyMessage>
        <VityaMessage>
          350 километров… Это же почти как от Москвы до Нижнего Новгорода… Там
          по прямой 402 километра. Представляешь, Варя, едешь, а вдоль дороги –
          лента из сосисок…
        </VityaMessage>
        <Text>И друзья отправились в Крылатское.</Text>
        <Image src={image2Src} alt="Варя" />
      </WhiteBlock>
    </div>
  );
};

export default Slide;
