import classNames from "classnames";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import image from "./img/image.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.whiteBlock}>
        <Text className={cn.text}>
          Но как тут не трогать, когда вокруг куча приборов со стрелками,
          микроскопов, колб с разноцветными жидкостями. Варя с опаской обошла
          стол с грозной табличкой «Осторожно, идет монтаж установки!» и открыла
          дверцы шкафа.
        </Text>
        <VityaMessage>
          Варя, осторожно! Туда лет сто никто не лазил!
        </VityaMessage>
        <Text className={cn.text}>
          Из шкафа, почти сбив Варю с ног, вывалились книги, пыльные
          лабораторные журналы и тяжелый бархатный альбом.
        </Text>
        <VityaMessage>
          Ничего себе, а я и не знал, что прадедушка собирал марки! Не ушиблась,
          сестренка?
        </VityaMessage>
        <VaryaMessage>
          Конечно, ушиблась! И теперь по справедливости этот альбом должен стать
          моим. Но я, так и быть, дам вам посмотреть!
        </VaryaMessage>

        <Image src={image} alt="Альбом" className={cn.image} />
      </WhiteBlock>
    </div>
  );
};

export default Slide;
