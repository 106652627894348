import classNames from "classnames";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { setChapterActive_tc } from "redux/reducers/app_reducer";
import {
  getChapters,
  getChaptersCount,
  getCompletedChaptersCount,
  getNextChapter,
  getNextNonCompletedChapter
} from "redux/selectors/app_selector";
import { AppStateType } from "redux/store";

import Navigator from "../../games/Kits/Navigator/Navigator";
import background from "./assets/background.png";
import light from "./assets/light.png";
import cn from "./Finish.module.scss";
import Slide1 from "./slides/Slide1/Slide";
import Slide2 from "./slides/Slide2/Slide";

type ownProps_type = {
  chapterId: number;
};

const Finish: FC<ownProps_type & reduxProps_type> = ({
  chapterId,
  setChapterActive_tc,
  chaptersCount,
  completedChaptersCount,
  chapters,
  nextNonCompletedChapter,
}) => {
  useEffect(() => {
    setChapterActive_tc(chapterId);
  }, [setChapterActive_tc, chapterId]);

  const [currentSlide, setCurrentSlide] = useState(0);

  const changeSlide = (dir: -1 | 1 = 1, slideNumber: number | null = null) => {
    setCurrentSlide((cs) => {
      if (slideNumber !== null) {
        return slideNumber;
      }

      if (dir === 1) {
        return cs === slides.length - 1 ? cs : ++cs;
      }

      return cs === 0 ? 0 : --cs;
    });
  };

  const setLastSlide = useCallback(() => {
    changeSlide(1, slides.length - 1);
  }, []);

  const setFirstSlide = useCallback(() => {
    changeSlide(-1, 0);
  }, []);

  const slides = [
    <Slide1 className={cn.slide} setLastSlide={setLastSlide} />,
    <Slide2
      className={cn.slide}
      chapters={chapters}
      chaptersCount={chaptersCount}
      completedChaptersCount={completedChaptersCount}
      setFirstSlide={setFirstSlide}
    />,
  ];

  const nextLink = useMemo(() => {
    let linkText = !completedChaptersCount ? "Начать игру" : "Продолжить игру",
      linkTo = nextNonCompletedChapter?.link;

    return linkTo ? (
      <Link to={linkTo} className={cn.progress__button}>
        {linkText}
      </Link>
    ) : (
      <></>
    );
  }, [completedChaptersCount, nextNonCompletedChapter]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSlide]);

  return (
    <div className={classNames("main-wrapper", cn.wrapper)}>
      <div className={cn.wrapper__background}>
        <img src={background} alt="Фон" />
      </div>
      <div className={classNames("game__container", cn["game__container"])}>
        <div className={classNames("game__wrapper", cn["game__wrapper"])}>
          <Navigator
            elementsCount={slides.length}
            activeIdx={currentSlide}
            elementClick={changeSlide}
            dotClass={cn.navigatorDot}
          />

          <div style={{ position: "relative" }}>
            <div className={cn.game__background}>
              <img
                src={light}
                alt="Фигура"
                className={cn.game__background__light_top}
              />
              <img
                src={light}
                alt="Фигура"
                className={cn.game__background__light_bottom}
              />
            </div>
            <div className={cn.progress}>
              <span>
                Пройдено игр{" "}
                <span className={cn.progress__count}>
                  ({completedChaptersCount}/{chaptersCount} игр)
                </span>
              </span>
              <div className={cn.progress__line}>
                <div
                  className={cn.progress__line__back}
                  style={{
                    width:
                      Math.floor(
                        (completedChaptersCount / chaptersCount) * 100
                      ) + "%",
                  }}
                ></div>
              </div>
              {nextLink}
            </div>
            <div className={cn.game__body}>{slides[currentSlide]}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  nextChapter: getNextChapter(state),
  chaptersCount: getChaptersCount(state),
  completedChaptersCount: getCompletedChaptersCount(state),
  nextNonCompletedChapter: getNextNonCompletedChapter(state),
  chapters: getChapters(state),
});

const connector = connect(mapStateToProps, {
  setChapterActive_tc,
});
type reduxProps_type = ConnectedProps<typeof connector>;

export default connector(Finish);
