import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyStatic } from "games/Kits/Characters";
import RobyInvitation from "games/Kits/Game/Invitation/Roby/Roby";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist2.png";
import imageSrc from "./img/image.jpg";
import shoesImg from "./img/shoes.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VaryaMessage>
          Вот это да, я даже не представляла, что такая существуетя. Можно я
          попробую что-нибудь сделать? Вот, например, подошву к этому ботинку
          приклею, это ведь, похоже, совсем нетрудно. Вите же разрешили на
          компьютере поработать!
        </VaryaMessage>
        <Message name="Формовщица" image={specialistImg}>
          Конечно.
        </Message>
        <Text>
          Людмила протянула Варе детский ботинок и подошву, уже покрытую
          разогретым клеем. Но подошва юлила и никак не хотела ложиться ровно.
          Варя чуть не расплакалась. Но тут поверх ее руки легла твердая рука
          Роби, и подошва идеально встала на положенное ей место.
        </Text>
        <VaryaMessage>Спасибо, Роби! Ты настоящий друг!</VaryaMessage>
        <Image src={imageSrc} alt="Роби и Варя" />
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          За 100 лет своего существования «Парижская коммуна» выпустила около
          700 млн пар обуви.
        </p>
        <RobyStatic className={cn.block_bottom__roby} />
        <img src={shoesImg} alt="Обувь" className={cn.block_bottom__shoes} />
      </div>
      <RobyInvitation />
    </div>
  );
};

export default Slide;
