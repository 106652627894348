import classNames from "classnames";
import Background from "games/Kits/Background/Background";
import RobyGame from "games/Kits/Game/Game/Roby/Roby";
import { FC, useEffect, useState } from "react";
import { chapter_type } from "types";

import light from "../assets/img/light.png";
import cn from "./Game.module.scss";
import explanationImg from "./img/explanation.png";
import milkImg from "./img/milk.jpg";
import potImg from "./img/pot.jpg";
import rebusImg from "./img/rebus.jpg";
import sausageImg from "./img/sausage.jpg";
import trousersImg from "./img/trousers.jpg";

const items = [
  {
    img: milkImg,
    isError: false,
    isCorrect: false,
    isSelected: false,
  },
  {
    img: sausageImg,
    isError: false,
    isCorrect: true,
    isSelected: false,
  },
  {
    img: potImg,
    isError: false,
    isCorrect: false,
    isSelected: false,
  },
  {
    img: trousersImg,
    isError: false,
    isCorrect: false,
    isSelected: false,
  },
];

type ownProps_type = {
  nextChapter: chapter_type;
  onGameFinished: () => void;
  returnBack: () => void;
};

const Game: FC<ownProps_type> = ({
  nextChapter,
  onGameFinished,
  returnBack,
}) => {
  const [list, setList] = useState(items);
  const [gameState, setGameState] = useState<"static" | "failure" | "finish">(
    "static"
  );
  const [robyStateData] = useState({
    static:
      "Разгадай ребус и узнай, что производят на московском предприятии «Дымов»",
    failure: "Ты почти угадал! Попробуй еще раз!",
    finish: "Конечно же, на заводе «Дымов» делают колбасу!",
  });

  useEffect(() => {
    const index = list.findIndex((item) => item.isError);

    if (index !== -1) {
      setGameState((gameState) =>
        gameState === "finish" ? gameState : "failure"
      );

      setTimeout(() => {
        setList((list) => {
          const items = [...list],
            item = { ...items[index] };

          item.isError = false;
          item.isSelected = false;
          items[index] = item;

          return items;
        });
      }, 2000);

      setTimeout(
        () =>
          setGameState((gameState) =>
            gameState === "finish" ? gameState : "static"
          ),
        2000
      );
    }
  }, [list]);

  const handleClick = (index: number) => {
    if (list[index].isSelected) {
      return false;
    }

    setGameState((gameState) =>
      gameState === "finish" ? gameState : "static"
    );

    if (list[index].isCorrect) {
      setGameState("finish");
      onGameFinished();
      return false;
    }

    setList((list) => {
      const items = [...list],
        item = { ...items[index] };

      item.isSelected = true;
      item.isError = !item.isCorrect;
      items[index] = item;

      return items;
    });
  };

  return (
    <div className={cn.game}>
      <Background
        colorFrom={"#104E43"}
        colorTo={"#104E43"}
        baseCloudColor="#187363"
      >
        <img className={cn.background__light_top} src={light} alt="Свет" />
        <img className={cn.background__light_bottom} src={light} alt="Свет" />
      </Background>
      <img
        src={rebusImg}
        alt="Ребус"
        className={classNames(cn.rebus, {
          [cn.rebus_hidden]: gameState === "finish",
        })}
      />
      <div
        className={classNames(cn.items, cn.items_list, {
          [cn.items_hidden]: gameState === "finish",
        })}
      >
        {list.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(cn.items__item, cn.items_list__item, {
                [cn.items__item_error]: item.isError,
              })}
            >
              <div
                className={classNames(
                  cn.items__item__image,
                  cn.items_list__item__image
                )}
                onClick={() => {
                  handleClick(index);
                }}
              >
                <img src={item.img} alt="Изображение" />
              </div>
            </div>
          );
        })}
      </div>

      {gameState === "finish" && (
        <div className={classNames(cn.items, cn.items_finish)}>
          {list.map((item, index) => {
            return (
              <div
                key={index}
                className={classNames(cn.items__item, {
                  [cn.items__item_correct]: item.isCorrect,
                })}
              >
                <div className={cn.items__item__image}>
                  <img src={item.img} alt="Изображение" />
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div
        className={classNames(cn.game__explanation, {
          [cn.items_hidden]: gameState === "finish",
        })}
      >
        <img src={explanationImg} alt="Пояснение" />
        <p>Выбери нужную карточку нажатием</p>
      </div>

      <RobyGame
        gameState={gameState}
        stateData={robyStateData}
        nextGameLink={nextChapter.link}
        returnBack={returnBack}
      />
    </div>
  );
};

export default Game;
