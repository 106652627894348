import classNames from "classnames";
import VityaGame from "games/Kits/Game/Game/Vitya/Vitya";
import { FC, useEffect, useState } from "react";
import { chapter_type } from "types";

import light from "../assets/img/light.png";
import cn from "./Game.module.scss";
import explanationImg from "./img/explanation.png";
import fireSensorImg from "./img/fire-sensor.png";
import lightSensorImg from "./img/light-sensor.png";
import rainSensorFinishImg from "./img/rain-sensor-finish.png";
import rainSensorImg from "./img/rain-sensor.png";
import temperatureSensorImg from "./img/temperature-sensor.png";

const items = [
  {
    img: lightSensorImg,
    isError: false,
    isExtra: false,
    isSelected: false,
  },
  {
    img: rainSensorImg,
    isError: false,
    isExtra: true,
    isSelected: false,
  },
  {
    img: temperatureSensorImg,
    isError: false,
    isExtra: false,
    isSelected: false,
  },
  {
    img: fireSensorImg,
    isError: false,
    isExtra: false,
    isSelected: false,
  },
];

type ownProps_type = {
  nextChapter: chapter_type;
  onGameFinished: () => void;
  returnBack: () => void;
};

const Game: FC<ownProps_type> = ({
  nextChapter,
  onGameFinished,
  returnBack,
}) => {
  const [list, setList] = useState(items);
  const [gameState, setGameState] = useState<"static" | "failure" | "finish">(
    "static"
  );
  const [vityaStateData] = useState({
    static: "Я решил сделать модель умного дома. Какой датчик мне не нужен?",
    failure: "Попробуй еще раз!",
    finish:
      "Правильно! На окнах зданий дворники не ставят. Датчик дождя не требуется",
  });

  useEffect(() => {
    const index = list.findIndex((item) => item.isError);

    if (index !== -1) {
      setGameState((gameState) =>
        gameState === "finish" ? gameState : "failure"
      );

      setTimeout(() => {
        setList((list) => {
          const items = [...list],
            item = { ...items[index] };

          item.isError = false;
          item.isSelected = false;
          items[index] = item;

          return items;
        });
      }, 2000);

      setTimeout(
        () =>
          setGameState((gameState) =>
            gameState === "finish" ? gameState : "static"
          ),
        2000
      );
    }
  }, [list]);

  const handleClick = (index: number) => {
    if (list[index].isSelected) {
      return false;
    }

    setGameState((gameState) =>
      gameState === "finish" ? gameState : "static"
    );

    if (list[index].isExtra) {
      setGameState("finish");
      onGameFinished();
      return false;
    }

    setList((list) => {
      const items = [...list],
        item = { ...items[index] };

      item.isSelected = true;
      item.isError = !item.isExtra;
      items[index] = item;

      return items;
    });
  };

  return (
    <div className={cn.game}>
      <div className={cn.background}>
        <div className={cn.background__color}></div>
        <img className={cn.background__light_top} src={light} alt="Свет" />
        <img className={cn.background__light_bottom} src={light} alt="Свет" />
      </div>
      <div
        className={classNames(cn.cards, {
          [cn.cards_hidden]: gameState === "finish",
        })}
      >
        {list.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(cn.cards__item, {
                [cn.cards__item_error]: item.isError,
              })}
            >
              <div
                className={cn.cards__item__image}
                onClick={() => {
                  handleClick(index);
                }}
              >
                <img src={item.img} alt="Изображение" />
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={classNames(cn.game__explanation, {
          [cn.game__explanation_hidden]: gameState === "finish",
        })}
      >
        <img src={explanationImg} alt="Пояснение" />
        <p>Выбери лишнюю карточку нажатием</p>
      </div>

      {gameState === "finish" && (
        <img
          src={rainSensorFinishImg}
          className={cn.finish}
          alt="Датчик дождя"
        />
      )}

      <VityaGame
        gameState={gameState}
        stateData={vityaStateData}
        nextGameLink={nextChapter.link}
        returnBack={returnBack}
      />
    </div>
  );
};

export default Game;
