import classNames from "classnames";
import Carousel from "components/Blocks/Carousel/Carousel";
import Text from "components/Blocks/Text/Text";
import { FC, useEffect } from "react";

import cn from "./Slide.module.scss";

const slides = [
  "DSC_7533.jpg",
  "TIM_8064.jpg",
  "TIM_8105.jpg",
  "TIM_8114.jpg",
  "slide_01.jpg",
  "slide_02.jpg",
  "slide_03.jpg",
];

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = ({
  changeBackColor,
  changeCloudsColor,
  ...props
}) => {
  useEffect(() => {
    changeBackColor("#3D24A1", "#3D24A1");
    changeCloudsColor("#2F1986");

    return () => {
      changeBackColor();
      changeCloudsColor();
    };
  }, [changeBackColor, changeCloudsColor]);

  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <Text className={classNames("title", cn.slide__title)}>
        Прогулки по производству компании «Научные развлечения»
      </Text>
      <Carousel
        slides={slides}
        dir="Naurashi"
        arrowColor="rgba(47, 25, 134, 0.6)"
        arrowColorHover="rgba(47, 25, 134, 0.3)"
      />
    </div>
  );
};

export default Slide;
