import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import backImg from "./img/back.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={backImg} alt="Фон" />
        <VityaMessage>А как вы придумали своего первого робота?</VityaMessage>
        <Message name="Директор" image={directorImg}>
          Своего первенца я начал собирать в 2014 году. У меня тогда родилась
          дочка, я работал дома. Дочку приходилось укачивать, и я
          запрограммировал робота качать кроватку, а в глаза ему вставил
          видеокамеры, чтобы видеть, все ли в порядке. Вот так впервые робот
          избавил меня от рутинной работы.{" "}
        </Message>
        <VityaMessage>
          А почему вы решили делать роботов похожими на людей? Ведь возникает
          много проблем с движением и удержанием равновесия.
        </VityaMessage>
        <Message name="Директор" image={directorImg}>
          А почему ты, Витя, сделал Роби похожим на человека? Потому что нам
          трудно взаимодействовать с ящиком на колесах или со станком с числовым
          управлением. Вы же наверняка были в Центре имени Хруничева, слышали
          про лунную программу? Тогда вы должны понимать, что на Луне сначала
          должны высадиться роботы, чтобы все подготовить. И если они будут
          похожи на людей, то смогут пользоваться всеми нашими инструментами и
          механизмами.
        </Message>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
