import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import wiresImg from "./img/wires.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VaryaMessage>
          А почему на этом вашем заводе так вкусно пахнет?
        </VaryaMessage>
        <Message name="PR-директор" image={specialistImg}>
          Здесь мы делаем обмотки для трансформаторов, а запах – это эмаль-лак.
          Смотрите, он стекает по проводам, точно мед, покрывая их тончайшим
          слоем, всего одна десятая миллиметра. Каждый слой запекается вот в
          этой печи высотой 26 метров, а всего слоев может быть до пятнадцати.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#7B651A" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          ЭТО ИНТЕРЕСНО!
        </p>
        <p>
          Кабели, произведенные заводом «Москабельмет», работают на атомных
          ледоходах «Арктика», «Сибирь» и «Урал», на атомных электростанциях, в
          московском и питерском метро и на Останкинской телебашне.
        </p>
        <img src={wiresImg} className={cn.block_bottom__wires} alt="Провода" />
      </div>
    </div>
  );
};

export default Slide;
