import arrowRight from "assets/images/icons/arrow-next.svg";
import arrowLeft from "assets/images/icons/arrow-prev.svg";
import Button from "components/Kits/Footer/Button";
import { AppContext } from "context/app-context";
import React, {
  FC,
  JSXElementConstructor,
  ReactElement,
  useContext,
  useMemo
} from "react";
import { Link } from "react-router-dom";

import cn from "./GameCharacter.module.scss";

type stateData_type = {
  start?: string;
  static: string;
  failure: string;
  finish: string;
};

type ownProps_type = {
  gameState: keyof stateData_type;
  stateData: stateData_type;
  nextGameLink: string;
  className?: string;
  children: Array<ReactElement<any, string | JSXElementConstructor<any>>>;
  returnBack: () => void;
};

const GameCharacter: FC<ownProps_type> = ({
  gameState,
  stateData,
  nextGameLink,
  className,
  children,
  returnBack,
}) => {
  const { isMobile } = useContext(AppContext);
  const characterStateData = useMemo(() => {
    return stateData[gameState];
  }, [gameState, stateData]);

  return (
    <>
      {gameState !== "finish" && isMobile && (
        <Button className={cn.prevButton} onClick={returnBack}>
          НАЗАД
          <img src={arrowLeft} alt="Назад" />
        </Button>
      )}
      <div className={className}>
        {React.cloneElement(children[0], {
          style: {
            display:
              gameState === "static" || gameState === "start"
                ? "block"
                : "none",
          },
        })}
        {React.cloneElement(children[1], {
          style: {
            display: gameState === "failure" ? "block" : "none",
          },
        })}
        {React.cloneElement(children[2], {
          style: {
            display: gameState === "finish" ? "block" : "none",
          },
        })}
        {characterStateData && (
          <span className="title text-cloud">
            {characterStateData}
            {gameState === "finish" && nextGameLink && (
              <Link to={nextGameLink}>
                <Button variant="red" className={cn.nextButton}>
                  <span className={cn.nextButton__text}>Следующая</span>
                  ГЛАВА
                  <img src={arrowRight} alt="Вперёд" />
                </Button>
              </Link>
            )}
          </span>
        )}
      </div>
    </>
  );
};

export default GameCharacter;
