import classNames from "classnames";
import { AppContext } from "context/app-context";
import VityaGame from "games/Kits/Game/Game/Vitya/Vitya";
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { chapter_type } from "types";

import cn from "./Game.module.scss";
import dotsImg from "./img/dots.png";
import dotsMobileImg from "./img/dots_mobile.png";
import explanationImg from "./img/explanation.png";
import rocketImg from "./img/rocket.png";
import skyImg from "./img/sky.png";

type point_type = {
  x: number;
  y: number;
  isSelected: boolean;
  isHovered: boolean;
  isConnected: boolean;
};

const items = [
  {
    x: 33.8,
    y: 40.3,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: true,
  },
  {
    x: 27.1,
    y: 40.6,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: false,
  },
  {
    x: 17.8,
    y: 42.7,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: true,
  },
  {
    x: 28.2,
    y: 30.5,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: false,
  },
  {
    x: 37,
    y: 26,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: true,
  },
  {
    x: 45,
    y: 27.5,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: true,
  },
  {
    x: 55.8,
    y: 22.2,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: false,
  },
  {
    x: 66.8,
    y: 18.8,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: false,
  },
  {
    x: 81.5,
    y: 16.9,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: false,
  },
  {
    x: 88,
    y: 19.1,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: true,
  },
  {
    x: 88.3,
    y: 24.7,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: false,
  },
  {
    x: 80.8,
    y: 38.6,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: false,
  },
  {
    x: 75.1,
    y: 46.4,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: false,
  },
  {
    x: 63.2,
    y: 59.4,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: true,
  },
  {
    x: 66.7,
    y: 67.2,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: true,
  },
  {
    x: 59,
    y: 76.5,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: false,
  },
  {
    x: 46.7,
    y: 83.2,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: true,
  },
  {
    x: 50,
    y: 73.5,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: false,
  },
  {
    x: 53.9,
    y: 67.6,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: true,
  },
  {
    x: 39.5,
    y: 52.7,
    isSelected: false,
    isHovered: false,
    isConnected: false,
    showOnMobile: true,
  },
];

type ownProps_type = {
  nextChapter: chapter_type;
  onGameFinished: () => void;
  returnBack: () => void;
};

const Game: FC<ownProps_type> = ({
  nextChapter,
  onGameFinished,
  returnBack,
}) => {
  const { isMobile } = useContext(AppContext);

  const [points, setPoints] = useState(items);
  const [pointStyles] = useState({
    diameter: isMobile ? 20 : 15,
    backColor: "#ffffff",
    hoverBackColor: "#FF5E5E",
    finishBackColor: "#000000",
    borderWidth: 4,
    borderColor: "#FF5E5E",
    finishBorderColor: "#AAB900",
  });
  const [lineStyles] = useState({
    width: 1,
    color: "#FF5E5E",
  });
  const [gameState, setGameState] = useState<"static" | "failure" | "finish">(
    "static"
  );
  const [pointToClick, setPointToClick] = useState(0);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [vityaStateData] = useState({
    static: "Соедини точки и узнай самое необычное место, где нужен кабель.",
    failure: "Разгадка уже близко! Попробуй еще раз!",
    finish: "Да, кабель может пригодиться и в космосе!",
  });

  const setCanvasWidth = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (!canvasRef.current) return;

    canvasRef.current.width = e.currentTarget.width;
    canvasRef.current.height = e.currentTarget.height;

    points.forEach((item, index) => {
      drawPoint(item, index);

      if (item.isSelected && points[index + 1]?.isSelected) {
        drawLine(item.x, item.y, points[index + 1].x, points[index + 1].y);
      }
    });
  };

  const clearCanvas = () => {
    if (!canvasRef.current) return;

    const ctx = canvasRef.current.getContext("2d");

    ctx?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  const drawPoint = useCallback(
    (point: point_type, index: number) => {
      if (!canvasRef.current) return;

      const ctx = canvasRef.current.getContext("2d"),
        x = (canvasRef.current.width / 100) * point.x,
        y = (canvasRef.current.height / 100) * point.y;

      if (!ctx) return false;

      ctx.beginPath();
      ctx.arc(x, y, pointStyles.diameter / 2, 0, 2 * Math.PI, false);
      ctx.fillStyle =
        gameState === "finish"
          ? pointStyles.finishBackColor
          : point.isHovered || point.isSelected
          ? pointStyles.hoverBackColor
          : pointStyles.backColor;
      ctx.fill();
      ctx.lineWidth = pointStyles.borderWidth;
      ctx.strokeStyle =
        gameState === "finish"
          ? pointStyles.finishBorderColor
          : pointStyles.borderColor;
      ctx.stroke();

      if (isMobile && gameState !== "finish") {
        ctx.font = "16px Roboto, sans-serif";
        ctx.fillStyle = "#000000";
        ctx.fillText(
          String(index + 1),
          x - pointStyles.diameter / 4 - (index + 1 >= 10 ? 4 : 0),
          y + pointStyles.diameter / 4 + 1
        );
      }
    },
    [gameState, pointStyles, isMobile]
  );

  const drawLine = useCallback(
    (fromX: number, fromY: number, toX: number, toY: number) => {
      if (!canvasRef.current) return;

      const ctx = canvasRef.current.getContext("2d"),
        x1 = (canvasRef.current.width / 100) * fromX,
        y1 = (canvasRef.current.height / 100) * fromY,
        x2 = (canvasRef.current.width / 100) * toX,
        y2 = (canvasRef.current.height / 100) * toY;

      if (!ctx) return false;

      ctx.strokeStyle = lineStyles.color;
      ctx.lineWidth = lineStyles.width;

      ctx.beginPath();
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();
    },
    [lineStyles]
  );

  useEffect(() => {
    if (isMobile) {
      setPoints((points) => {
        return points.filter((point) => point.showOnMobile);
      });
    }
  }, [isMobile]);

  useEffect(() => {
    clearCanvas();

    points.forEach((item, index) => {
      drawPoint(item, index);

      if (item.isSelected && points[index + 1]?.isSelected) {
        gameState !== "finish" &&
          drawLine(item.x, item.y, points[index + 1].x, points[index + 1].y);
      }
    });

    document.body.style.cursor = points.filter((item) => item.isHovered).length
      ? "pointer"
      : "default";

    return () => {
      document.body.style.cursor = "";
    };
  }, [points, gameState, drawLine, drawPoint]);

  useEffect(() => {
    if (pointToClick === points.length) {
      setGameState("finish");
      onGameFinished();
    }
  }, [pointToClick, onGameFinished, points.length]);

  const checkMouseInPoint = (
    e: React.MouseEvent<HTMLCanvasElement>,
    point: point_type
  ) => {
    if (!canvasRef.current) return false;

    const rect = canvasRef.current.getBoundingClientRect(),
      mouseX =
        ((e.clientX - rect.left) / (rect.right - rect.left)) *
        canvasRef.current.width,
      mouseY =
        ((e.clientY - rect.top) / (rect.bottom - rect.top)) *
        canvasRef.current.height,
      currentPointX =
        (canvasRef.current.width / 100) * point.x - pointStyles.diameter / 2,
      currentPointY =
        (canvasRef.current.height / 100) * point.y - pointStyles.diameter / 2,
      currentPointW = currentPointX + pointStyles.diameter,
      currentPointH = currentPointY + pointStyles.diameter;

    return (
      mouseX >= currentPointX &&
      mouseX <= currentPointW &&
      mouseY >= currentPointY &&
      mouseY <= currentPointH
    );
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (gameState === "finish") return;

    setPoints((points) => {
      const items = [...points],
        item = { ...items[pointToClick] };

      item.isHovered = checkMouseInPoint(e, item);
      items[pointToClick] = item;

      return item.isHovered === points[pointToClick].isHovered ? points : items;
    });
  };

  const handleClick = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (gameState === "finish") {
      return false;
    }

    if (!checkMouseInPoint(e, points[pointToClick])) {
      setGameState("failure");
      return false;
    }

    setPoints((points) => {
      const items = [...points],
        item = { ...items[pointToClick] };

      item.isSelected = true;
      item.isHovered = false;
      items[pointToClick] = item;

      return items;
    });

    setGameState("static");
    setPointToClick((index) => index + 1);
  };

  useEffect(() => {
    if (gameState !== "failure") {
      return;
    }

    setTimeout(
      () =>
        setGameState((gameState) =>
          gameState === "finish" ? gameState : "static"
        ),
      4000
    );
  }, [gameState]);

  return (
    <div className={cn.game}>
      {gameState === "finish" && (
        <div className={classNames(cn.items, cn.items_finish)}></div>
      )}
      <img src={skyImg} alt="Небо" className={cn.background} />
      <img
        src={isMobile ? dotsMobileImg : dotsImg}
        alt="Точки"
        onLoad={setCanvasWidth}
        className={cn.dots}
      />
      <img
        src={rocketImg}
        alt="Ракета"
        className={cn.rocket}
        style={{ display: gameState === "finish" ? "block" : "none" }}
      />
      <canvas
        className={cn.canvas}
        ref={canvasRef}
        onMouseMove={handleMouseMove}
        onClick={handleClick}
      ></canvas>

      <div
        className={classNames(cn.game__explanation, {
          [cn.items_hidden]: gameState === "finish",
        })}
      >
        <img src={explanationImg} alt="Пояснение" />
        <p>Выбери 2 точки кликом для соединения</p>
      </div>

      <VityaGame
        gameState={gameState}
        stateData={vityaStateData}
        nextGameLink={nextChapter.link}
        returnBack={returnBack}
      />
    </div>
  );
};

export default Game;
