import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import VityaInvitation from "games/Kits/Game/Invitation/Vitya/Vitya";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import cubeImg from "./img/cube.png";
import imageSrc from "./img/image.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={imageSrc} alt="Книга" />
        <RobyMessage>
          Она тоже робот? Витя, ну почему ты не сделал меня больше похожим на
          человека?
        </RobyMessage>
        <VaryaMessage>
          Мы тебя и таким любим! И хоть завтра готовы полететь с тобой на Луну!
        </VaryaMessage>
        <Message name="Директор" image={directorImg}>
          Кстати, наш принтер взяли с собой участники эксперимента Sirius 2021 –
          они восемь месяцев находились в изоляции, моделируя высадку на Луну. У
          них во время транспортировки сломалась какая-то важная деталь, и,
          представляете, они смогли быстро распечатать ее на нашем принтере!
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#6C6447" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          Научиться 3D-моделированию можно самому – в Сети есть много бесплатных
          программ с подробными инструкциями. Придумывать и печатать свои
          собственные модели гораздо интереснее, чем использовать готовые.
        </p>
        <img src={cubeImg} alt="Куб" className={cn.block_bottom__image} />
      </div>
      <VityaInvitation />
    </div>
  );
};

export default Slide;
