import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import constructorImg from "../../assets/img/constructor.png";
import astronautImg from "./img/astronaut.png";
import rocketImg from "./img/rocket.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={rocketImg} alt="Ракета" className={cn.image} />
        <Text>
          На основной производственной площадке центра ребят встретил
          генеральный конструктор Сергей Кузнецов.
        </Text>
        <Message name="Конструктор" image={constructorImg}>
          А вы знали, что сначала наше предприятие собирало автомобили?
        </Message>
        <VaryaMessage>Как это?</VaryaMessage>
        <Message name="Конструктор" image={constructorImg}>
          В 1916 году был основан завод по производству автомобилей
          «Руссо-Балт». В 1927 году предприятие было переведено на производство
          самолетов. А с 1960-х годов мы перешли к космосу – разрабатываем и
          производим ракеты-носители, разгонные блоки и космические аппараты.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#4C5E7F" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          У Центра имени Хруничева есть и земная специальность. Он изготавливает
          барокамеры, которые помогают восстанавливаться людям после сердечных
          приступов, травм и ожогов.
        </p>
        <img
          src={astronautImg}
          alt="Роби космонавт"
          className={cn.block_bottom__image}
        />
      </div>
    </div>
  );
};

export default Slide;
