import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import imageSrc from "./img/image.jpg";
import image from "./img/image.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          В соседней комнате несколько принтеров трудились над деталями будущих
          датчиков. Рядом молодой рабочий вставлял спинки божьих коровок в
          небольшой станок, опускал пресс – и на красном пластике появлялись
          симпатичные черные точки.
        </Text>
        <Message name="Директор" image={directorImg}>
          Кстати, Варя, а вот этот набор может показаться тебе особенно
          интересным. Называется «Азбука парфюмерии». Здесь у нас двенадцать
          эфирных масел, восемь синтетических ароматических веществ и все
          необходимое для приготовления растворов. Можно составлять разные
          ароматы, а заодно выяснить, как восприятие запаха зависит от
          температуры, какие запахи не нравятся комарам, а какие помогают снять
          стресс.
        </Message>
        <VaryaMessage>
          То есть я теперь могу не ждать, пока Полина приготовит мне духи, а
          сделать их сама? Как здорово!
        </VaryaMessage>
        <Image src={imageSrc} alt="Роби нюхает духи" />
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#476B59" />
      <div className={classNames("block", cn.block_bottom)}>
        <p
          style={{ fontSize: 96, lineHeight: "121px", marginBottom: 10 }}
          className={classNames("title", cn.title)}
        >
          Это интересно!
        </p>
        <p className={cn.text}>
          Окончили Академию Наураши? Поступайте в Школу профессора Дроздова! Там
          вы слетаете на Луну, вскипятите воду на Эвересте, измерите пульс
          спящего медведя и нырнете в океан, а также узнаете, что самым сильным
          животным на Земле является панцирный клещ, который может поднимать
          грузы в 1180 раз больше собственного веса.
        </p>
        <img src={image} alt="Роби" className={cn.block_bottom__image} />
      </div>
    </div>
  );
};

export default Slide;
