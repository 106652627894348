import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import RobyInvitation from "games/Kits/Game/Invitation/Roby/Roby";
import { FC } from "react";

import { VaryaDialogue } from "../../../Kits/Characters/index";
import iceCream from "../../assets/img/ice-cream.png";
import tasterImg from "../../assets/img/taster.png";
import imageSrc from "./img/image.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <img src={iceCream} alt="Мороженое" className={cn.white__iceCream} />
        <VaryaMessage>Сколько мороженого вы съедаете в день?</VaryaMessage>
        <Message name="Дегустатор" image={tasterImg}>
          Мы пробуем каждую партию – оцениваем вкус и внешний вид, проверяем,
          хрустит ли вафля – она обязана хрустеть! По-моему, как-то я съела за
          день целый килограмм.
        </Message>
        <RobyMessage>И вы здесь придумываете новые сорта?</RobyMessage>
        <Message name="Дегустатор" image={tasterImg}>
          Конечно. Вообще-то мы можем сделать самое разное мороженое – недавно
          выпустили сорт с березовым соком. Но москвичи больше всего любят
          клубнику, черную смородину и фисташки. Ну и, конечно, ванильное и
          шоколадное. Вот, попробуйте.
        </Message>
        <Image src={imageSrc} alt="На заводе" className={cn.image} />
        <Text>
          И ребята попробовали. И клубничное, и фисташковое, и из черной
          смородины. И, конечно, ванильное и шоколадное.
        </Text>
      </WhiteBlock>
      <Clips topColor="#D2D2D2" bottomColor="#18446C" doubled />
      <div className={classNames("block", cn.block_interesting)}>
        <VaryaDialogue className={cn.block_interesting__image} />
        <p
          style={{ fontSize: 48, lineHeight: "61px", marginBottom: 30 }}
          className="title"
        >
          Это Интересно
        </p>
        <p>
          Спорим, вы не пробовали! В Южной Америке популярно мороженое с перцем
          чили, в Австралии — с сельдереем и солью, в Японии в мороженое
          добавляют рыбу, креветок и моллюсков, а в Мексике обваливают в
          панировке и жарят.
        </p>
      </div>
      <RobyInvitation />
    </div>
  );
};

export default Slide;
