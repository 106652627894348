import classNames from "classnames";
import Carousel from "components/Blocks/Carousel/Carousel";
import Text from "components/Blocks/Text/Text";
import { FC, useEffect } from "react";

import cn from "./Slide.module.scss";

const slides = [
  "SV_2022_06_01_0211.jpg",
  "SV_2022_06_02_0341.jpg",
  "Комплекс-переработки-нефти-ЕВРО+3.jpg",
  "slide_01.jpg",
  "slide_02.jpg",
  "slide_03.jpg",
];

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = ({
  changeBackColor,
  changeCloudsColor,
  ...props
}) => {
  useEffect(() => {
    changeBackColor("#30482C", "#30482C");
    changeCloudsColor("#4A6545");

    return () => {
      changeBackColor();
      changeCloudsColor();
    };
  }, [changeBackColor, changeCloudsColor]);

  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <Text className={classNames("title", cn.slide__title)}>
        Прогулка по Московскому нефтеперерабатывающему заводу
      </Text>
      <Carousel
        slides={slides}
        dir="MNPZ"
        arrowColor="rgba(74, 101, 69, 0.6)"
        arrowColorHover="rgba(74, 101, 69, 0.3)"
      />
    </div>
  );
};

export default Slide;
