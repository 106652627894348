import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import image from "./img/image.png";
import smartHomeImg from "./img/smart-home.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={image} alt="Опыты" />
        <Message name="Директор" image={directorImg}>
          А вот здесь настоящая роботизированная теплица.
        </Message>
        <VityaMessage>
          Это к Роби, он у нас салат на подоконнике выращивает.
        </VityaMessage>
        <Message name="Директор" image={directorImg}>
          Тогда ему она определенно пригодится. Мы высаживаем растение в
          климатическую камеру, отделенную пленкой. За его состоянием следят
          минимум три датчика, данные с которых отправляются на компьютер. Один
          меряет температуру и подает сигнал на проветривание камеры, второй
          определяет влажность почвы и включает капельный полив, а третий
          измеряет освещенность и управляет блоком светодиодов, которые могут
          менять не только яркость, но и цвет.
        </Message>
        <VityaMessage>
          Послушайте, да это же теплицы агрохолдинга «Московский» в миниатюре!
        </VityaMessage>
        <VaryaMessage>А вы еще обещали танцующий маятник!</VaryaMessage>
        <Message name="Директор" image={directorImg}>
          Обещал – значит покажу. Вот он. Если вставить в грузик магнит и
          окружить его другими магнитами, то маятник начинает танцевать. Я могу
          смотреть на это бесконечно. Кстати, в Академии Наураши есть
          лаборатория магнетизма. Там за каждое правильно выполненное задание
          студент получает награду – деталь воздушного шара. А полностью собрав
          шар, можно отправиться на нем в путешествие по Наурандии, причем
          управлять дирижаблем придется с помощью магнита. Вот такое применение
          знаний на практике!
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#476B59" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          У «Научных развлечений» есть не только «Умная теплица», но и «Умный
          дом», в котором можно управлять температурой, включать и выключать
          свет. Или оснастить его солнечной батареей и ветрогенератором и
          превратить в настоящий дом будущего.
        </p>
        <img
          src={smartHomeImg}
          alt="Умный дом"
          className={cn.block_bottom__image}
        />
      </div>
    </div>
  );
};

export default Slide;
