import classNames from "classnames";
import Carousel from "components/Blocks/Carousel/Carousel";
import Text from "components/Blocks/Text/Text";
import { FC, useEffect } from "react";

import cn from "./Slide.module.scss";

const slides = [
  "1M1A6961.jpg",
  "1M1A7016.jpg",
  "1M1A7033.jpg",
  "1M1A7091.jpg",
  "TIM_4430.jpg",
  "TIM_4457.jpg",
  "slide_01.jpg",
  "slide_02.jpg",
  "slide_03.jpg",
];

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = ({
  changeBackColor,
  changeCloudsColor,
  ...props
}) => {
  useEffect(() => {
    changeBackColor("#006281", "#006281");
    changeCloudsColor("#00789E");

    return () => {
      changeBackColor();
      changeCloudsColor();
    };
  }, [changeBackColor, changeCloudsColor]);

  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <Text className={classNames("title", cn.slide__title)}>
        Прогулка по фабрике VDOHNI
      </Text>
      <Carousel
        slides={slides}
        dir="Vdohni"
        arrowColor="rgba(0, 120, 158, 0.6)"
        arrowColorHover="rgba(0, 120, 158, 0.3)"
      />
    </div>
  );
};

export default Slide;
