import "reset-css";
import "./App.scss";

import classNames from "classnames";
import Header from "components/Kits/Header/Header";
import Loader from "components/Kits/Loader/Loader";
import { AppContext } from "context/app-context";
import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { HashRouter, Route, Routes } from "react-router-dom";
import { initializeApp_tc } from "redux/reducers/app_reducer";
import {
  getActiveChapter,
  getIsInitialized
} from "redux/selectors/app_selector";
import { AppStateType } from "redux/store";
import { router } from "router";

const App: FC<reduxProps_type> = ({
  isInitialized,
  initializeApp_tc,
  activeChapter,
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(false),
    [isMenuVisible, setIsMenuVisible] = useState(false);

  useEffect(() => {
    if (!activeChapter) {
      return;
    }

    const meta = document.getElementsByTagName("meta");

    document.title = activeChapter.meta.title;

    for (var i = 0; i < meta.length; i++) {
      if (meta[i].name.toLowerCase() === "description") {
        meta[i].content = activeChapter.meta.description;
      }
    }
  }, [activeChapter]);

  const handleWindowSizeChange = () => {
    let flag = false;
    if (window.innerWidth <= 1170) flag = true;
    setIsMobile(flag);
  };

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  });

  useEffect(() => {
    if (!isInitialized) {
      initializeApp_tc(router);
    }
  }, [isInitialized, initializeApp_tc]);

  if (!isInitialized) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    <AppContext.Provider value={{ isMobile }}>
      <HashRouter>
        <div
          className={classNames("content", {
            content_menu: isMenuVisible,
          })}
        >
          <Header
            isMenuVisible={isMenuVisible}
            setIsMenuVisible={setIsMenuVisible}
          />
          <React.Suspense fallback={<Loader />}>
            <Routes>
              {router.map((chapter, idx) => (
                <Route
                  key={idx}
                  path={chapter.link}
                  element={<chapter.component chapterId={idx} />}
                />
              ))}
            </Routes>
          </React.Suspense>
        </div>
      </HashRouter>
    </AppContext.Provider>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  isInitialized: getIsInitialized(state),
  activeChapter: getActiveChapter(state),
});

const connector = connect(mapStateToProps, {
  initializeApp_tc,
});
type reduxProps_type = ConnectedProps<typeof connector>;

export default connector(App);
