import classNames from "classnames";
import Carousel from "components/Blocks/Carousel/Carousel";
import Text from "components/Blocks/Text/Text";
import { FC, useEffect } from "react";

import cn from "./Slide.module.scss";

const slides = [
  "DSC_3407.jpg",
  "DSC_3425.jpg",
  "DSC_3435.jpg",
  "DSC_3438.jpg",
  "DSC_3446.jpg",
  "wSytNU8OXaI.jpg",
  "x.jpg",
  "slide_02.jpg",
  "slide_03.jpg",
];

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = ({
  changeBackColor,
  changeCloudsColor,
  ...props
}) => {
  useEffect(() => {
    changeBackColor("#5E7235", "#5E7235");
    changeCloudsColor("#46581F");

    return () => {
      changeBackColor();
      changeCloudsColor();
    };
  }, [changeBackColor, changeCloudsColor]);

  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <Text className={classNames("title", cn.slide__title)}>
        Прогулка по предприятию Picasso 3D
      </Text>
      <Carousel
        slides={slides}
        dir="Picasso_3D"
        arrowColor="rgba(70, 88, 31, 0.6)"
        arrowColorHover="rgba(70, 88, 31, 0.3)"
      />
    </div>
  );
};

export default Slide;
