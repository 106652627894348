import classNames from "classnames";
import Footer from "components/Kits/Footer/Footer";
import Loader from "components/Kits/Loader/Loader";
import Navigator from "games/Kits/Navigator/Navigator";
import useImagePreloader from "hooks/useImagePreloader";
import useVideoPreloader from "hooks/useVideoPreloader";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  setChapterActive_tc,
  setChapterCompleted_tc
} from "redux/reducers/app_reducer";
import { getNextChapter } from "redux/selectors/app_selector";
import { AppStateType } from "redux/store";
import { color } from "types";

import Background from "../Kits/Background/Background";
import cn from "./AirCraft.module.scss";
import backgroundSlide from "./assets/img/background.png";
import bottom from "./assets/img/bottom.png";
import top from "./assets/img/head.png";
import light from "./assets/img/light.png";
import background from "./assets/video/background.mp4";
import Game from "./Game/Game";
import Slide1 from "./slides/Slide1/Slide";
import Slide10 from "./slides/Slide10/Slide";
import Slide11 from "./slides/Slide11/Slide";
import Slide2 from "./slides/Slide2/Slide";
import Slide3 from "./slides/Slide3/Slide";
import Slide4 from "./slides/Slide4/Slide";
import Slide5 from "./slides/Slide5/Slide";
import Slide6 from "./slides/Slide6/Slide";
import Slide7 from "./slides/Slide7/Slide";
import Slide8 from "./slides/Slide8/Slide";

const preloadSrcList: Array<string> = [bottom, top];

type ownProps_type = {
  chapterId: number;
  [x: string]: any;
};

const AirCraft: FC<reduxProps_type & ownProps_type> = ({
  chapterId,
  nextChapter,
  setChapterCompleted_tc,
  setChapterActive_tc,
}) => {
  useEffect(() => {
    setChapterActive_tc(chapterId);
  }, [setChapterActive_tc, chapterId]);

  const { imagesPreloaded } = useImagePreloader(preloadSrcList);
  const { videoPreloadedSrc } = useVideoPreloader(background);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [baseBackgroundColorFrom] = useState<color>("#139189");
  const [baseBackgroundColorTo] = useState<color>("#139189");
  const [baseCloudColor] = useState<color>("#2FA7A0");
  const [backgroundColorFrom, setBackgroundColorFrom] = useState(
    baseBackgroundColorFrom
  );
  const [backgroundColorTo, setBackgroundColorTo] = useState(
    baseBackgroundColorTo
  );
  const [cloudColor, setCloudColor] = useState("");

  const changeBackgroundColor = useCallback(
    (
      backgroundColorF = baseBackgroundColorFrom,
      backgroundColorT = baseBackgroundColorTo
    ) => {
      setBackgroundColorFrom(backgroundColorF);
      setBackgroundColorTo(backgroundColorT);
    },
    [baseBackgroundColorFrom, baseBackgroundColorTo]
  );

  const changeCloudsColor = useCallback((color = "") => {
    setCloudColor(color);
  }, []);

  const gameFinished = () => setChapterCompleted_tc(chapterId);

  const slides = [
    <Slide1 />,
    <Slide2 className={cn.slide} />,
    <Slide3 />,
    <Slide4 className={cn.slide} />,
    <Slide5 className={cn.slide} />,
    <Slide6
      changeBackColor={changeBackgroundColor}
      changeCloudsColor={changeCloudsColor}
    />,
    <Slide7 className={cn.slide} />,
    <Slide8 className={cn.slide} />,
    <Slide10 className={cn.slide} />,
    <Slide11 className={cn.slide} />,
    <Game
      nextChapter={nextChapter}
      onGameFinished={gameFinished}
      returnBack={() => {
        changeSlide(-1);
      }}
    />,
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSlide]);

  const isInGame = useMemo<boolean>(() => {
    return currentSlide === slides.length - 1;
  }, [currentSlide, slides.length]);

  const isBeforeGame = useMemo(() => {
    return currentSlide === slides.length - 2;
  }, [currentSlide, slides.length]);

  if (!imagesPreloaded || !videoPreloadedSrc) {
    return <Loader />;
  }

  const changeSlide = (dir: -1 | 1 = 1, slideNumber: number | null = null) => {
    setCurrentSlide((cs) => {
      if (slideNumber !== null) {
        return slideNumber;
      }

      if (dir === 1) {
        return cs === slides.length - 1 ? cs : ++cs;
      }

      return cs === 0 ? 0 : --cs;
    });
  };

  return (
    <div className={classNames("main-wrapper", cn.wrapper)}>
      <video className={cn.wrapper__background} autoPlay loop muted>
        <source src={background} type="video/mp4" />
      </video>
      <div className={classNames("game__container", cn["game__container"])}>
        <div className={classNames("game__wrapper", cn["game__wrapper"])}>
          <Navigator
            elementsCount={slides.length}
            activeIdx={currentSlide}
            elementClick={changeSlide}
          />

          <div style={{ position: "relative" }}>
            {!isInGame && (
              <Background
                colorFrom={backgroundColorFrom}
                colorTo={backgroundColorTo}
                baseCloudColor={baseCloudColor}
                cloudColor={cloudColor}
              >
                <img
                  className={cn.background__image}
                  src={backgroundSlide}
                  alt="Узоры"
                />
                <img
                  className={cn.background__light_top}
                  src={light}
                  alt="Свет"
                />
                <img
                  className={cn.background__light_bottom}
                  src={light}
                  alt="Свет"
                />
              </Background>
            )}
            <div className={cn.game__body}>{slides[currentSlide]}</div>
          </div>
        </div>
      </div>
      <Footer
        prevDisabled={currentSlide === 0}
        className={classNames(cn.footer, {
          footer_beforeGame: isBeforeGame,
        })}
        prevHidden={isInGame}
        nextHidden={currentSlide === slides.length - 2 || isInGame}
        playHidden={isInGame}
        onNextClick={() => changeSlide(1)}
        onPrevClick={() => changeSlide(-1)}
        onPlayClick={() => changeSlide(1, slides.length - 1)}
      />
    </div>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  nextChapter: getNextChapter(state),
});

const connector = connect(mapStateToProps, {
  setChapterCompleted_tc,
  setChapterActive_tc,
});
type reduxProps_type = ConnectedProps<typeof connector>;

export default connector(AirCraft);
