import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";
import classNames from "classnames";
import RobyGame from "games/Kits/Game/Game/Roby/Roby";
import { FC, useEffect, useMemo, useState } from "react";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import { chapter_type } from "types";

import cn from "./Game.module.scss";
import explanationImg from "./img/explanation.png";
import image1Src from "./img/image_1.png";
import image2Src from "./img/image_2.png";
import image3Src from "./img/image_3.png";
import image4Src from "./img/image_4.png";
import image5Src from "./img/image_5.png";
import image6Src from "./img/image_6.png";
import image7Src from "./img/image_7.png";

const items = [
  {
    tooltip: "Греем и смешиваем",
    img: image5Src,
    correctIndex: 1,
    isError: false,
    isHovered: false,
  },
  {
    tooltip: "Устраняем вредные бактерии",
    img: image3Src,
    correctIndex: 2,
    isError: false,
    isHovered: false,
  },
  {
    tooltip: "Добавляем ингредиенты",
    img: image1Src,
    correctIndex: 0,
    isError: false,
    isHovered: false,
  },
  {
    tooltip: "Взбиваем и замораживаем",
    img: image2Src,
    correctIndex: 4,
    isError: false,
    isHovered: false,
  },
  {
    tooltip: "Заполняем стаканчики и режем эскимо",
    img: image6Src,
    correctIndex: 5,
    isError: false,
    isHovered: false,
  },
  {
    tooltip: "Охлаждаем",
    img: image4Src,
    correctIndex: 3,
    isError: false,
    isHovered: false,
  },
];

type ownProps_type = {
  nextChapter: chapter_type;
  onGameFinished: () => void;
  returnBack: () => void;
};

const Game: FC<ownProps_type> = ({
  nextChapter,
  onGameFinished,
  returnBack,
}) => {
  const [list, setList] = useState(items);
  const [gameState, setGameState] = useState<"static" | "failure" | "finish">(
    "static"
  );
  const [robyStateData] = useState({
    static:
      "Помоги нам приготовить мороженое. Расставь карточки в правильном порядке",
    failure: "Попробуй еще! Ты почти угадал!",
    finish: "Молодец! Ты настоящий эксперт! Приходи работать на фабрику!",
  });

  const gameFinished = useMemo(() => {
    return !list.filter((item, idx) => item.correctIndex !== idx).length;
  }, [list]);

  useEffect(() => {
    if (gameFinished) {
      setGameState("finish");
      onGameFinished();
    }
  }, [gameFinished, onGameFinished]);

  const getListWithSwitchedError = (
    list: any,
    index: number,
    isError: boolean
  ) => {
    const items = [...list],
      item = { ...items[index] };

    item.isError = isError;
    items[index] = item;

    return items;
  };

  const handleDrop = (e: any, dragToIndex: number) => {
    const correctIndex = e.dragData.correctIndex,
      dragFromIndex = e.dragData.index;

    if (dragFromIndex === dragToIndex) {
      return false;
    }

    if (correctIndex !== dragToIndex) {
      setList((list) => getListWithSwitchedError(list, dragFromIndex, true));

      setGameState((gameState) =>
        gameState === "finish" ? gameState : "failure"
      );

      setTimeout(() => {
        setList((list) => getListWithSwitchedError(list, dragFromIndex, false));
      }, 1000);

      setTimeout(() => {
        setGameState((gameState) =>
          gameState === "finish" ? gameState : "static"
        );
      }, 4000);

      return false;
    }

    setList((list) => {
      const items = [...list];

      [items[dragFromIndex], items[dragToIndex]] = [
        items[dragToIndex],
        items[dragFromIndex],
      ];
      return items;
    });
  };

  const setIsHovered = (index: number) => {
    setList((list) => {
      const items = [...list],
        item = { ...items[index] };

      item.isHovered = !item.isHovered;
      items[index] = item;

      return items;
    });
  };

  const setAllUnhoverd = () => {
    setList((list) => {
      list.map((item) => (item.isHovered = false));

      return list;
    });
  };

  return (
    <div className={cn.game}>
      <div className={cn.background}>
        <div className={cn.background__color}></div>
      </div>
      <div className={cn.cards}>
        {list.map((item, index) => {
          return (
            <DropTarget
              targetKey="drop"
              onHit={(e: any) => handleDrop(e, index)}
              onDragEnter={() => setIsHovered(index)}
              onDragLeave={() => setIsHovered(index)}
              data-index={index}
              key={index}
            >
              <div
                key={index}
                className={classNames(cn.cards__item, {
                  [cn.cards__item_correct]: index === item.correctIndex,
                  [cn.cards__item_candrop]: item.isHovered,
                  [cn.cards__item_error]:
                    item.isError && index !== item.correctIndex,
                })}
              >
                <DragDropContainer
                  targetKey="drop"
                  noDragging={index === item.correctIndex}
                  onDragStart={() => {
                    setGameState((gameState) =>
                      gameState === "finish" ? gameState : "static"
                    );
                  }}
                  onDragEnd={setAllUnhoverd}
                  dragData={{ ...item, index }}
                >
                  <div className={cn.cards__item__tooltip}>
                    <InfoIcon />
                    <span>{item.tooltip}</span>
                  </div>
                  <div
                    className={cn.cards__item__image}
                    draggable={index !== item.correctIndex}
                  >
                    <img src={item.img} alt={item.tooltip} draggable={false} />
                  </div>
                </DragDropContainer>
              </div>
            </DropTarget>
          );
        })}
        <div
          className={classNames(cn.cards__item, cn.cards__item_last, {
            [cn.cards__item_correct]: gameState === "finish",
          })}
        >
          <div className={cn.cards__item__image}>
            <img src={image7Src} alt="Мороженое" draggable={false} />
          </div>
        </div>
      </div>
      <div className={cn.cards__last}>
        <img src={explanationImg} alt="Пояснение" />
        <p>Перемещай карточки перетягиванием</p>
      </div>

      <RobyGame
        gameState={gameState}
        stateData={robyStateData}
        nextGameLink={nextChapter?.link}
        returnBack={returnBack}
      />
    </div>
  );
};

export default Game;
