import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import wiresImg from "./img/wires.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VaryaMessage>А что дальше с катанкой происходит?</VaryaMessage>
        <Message name="PR-директор" image={specialistImg}>
          Дальше она попадает в волочильно-проволочный цех, куда мы сейчас и
          направляемся. Осторожно: не заступайте за желтые линии на полу!
        </Message>
        <RobyMessage>
          Волочильно-проволочный. То есть катанку здесь будут волочить?..
        </RobyMessage>
        <Message name="PR-директор" image={specialistImg}>
          Именно. Катанка проходит через волоки – цилиндры, внутренний диаметр
          которых постепенно уменьшается. Так мы можем получить проволоку
          практически любой толщины, даже 0,05 миллиметров. Здесь у нас
          большинство сотрудников – женщины, они лучше справляются с такой
          ювелирной работой.
        </Message>
        <VityaMessage>Да и мужчинам дел хватает.</VityaMessage>
        <Text>
          Витя посмотрел на рабочего, который подкатывает к станку катушку с
          медной проволокой.
        </Text>
        <Message name="PR-директор" image={specialistImg}>
          Эти катушки могут весить до 240 килограммов. Женщинам такое просто не
          под силу.
        </Message>
        <Image className={cn.image} src={wiresImg} alt="Фабрика" />
        <Text>
          Катушку загрузили в агрегат, и механизм начал вращаться, сплетая
          несколько проволочек в токопроводящую жилу – сердце кабеля.
        </Text>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
