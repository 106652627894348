import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import Clips from "components/Blocks/Clips/Clips";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import beeImg from "./img/bee.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Message image={specialistImg} name="Агроном">
          А вот в таких небольших домиках живут наши главные сотрудники. Без них
          не было бы огурцов, помидоров и баклажанов.
        </Message>
        <Text>
          Ольга сняла с домика крышку, и послышалось сердитое жужжание.
        </Text>
        <Message image={specialistImg} name="Агроном">
          Это шмелиная семья – 60–80 рабочих насекомых-опылителей.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#126624" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          ЭТО ИНТЕРЕСНО!
        </p>
        <p>
          В теплицах агрохолдинга «Московский» работают 90 тысяч шмелей. Они
          опыляют огурцы, помидоры, перцы и баклажаны.
        </p>
        <img src={beeImg} alt="Теплица" className={cn.block_bottom__bee} />
      </div>
    </div>
  );
};

export default Slide;
