import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import engineerImg from "../../assets/img/engineer.png";
import carImg from "./img/car.png";
import factoryImg from "./img/factory.png";
import factory2Img from "./img/factory2.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VaryaMessage>
          А вы всю нефть перерабатываете или что-то выбрасываете?
        </VaryaMessage>
        <Message name="Директор" image={engineerImg}>
          В среднем по России выход полезного продукта составляет 74%, то есть
          из тонны нефти получается 740 килограммов бензина, керосина и прочих
          нужных вещей. Мы с нашей установкой делаем 85%, но скоро достроим
          комплекс глубокой переработки нефти и выйдем почти на 100%.
        </Message>
        <Image src={factoryImg} alt="НПЗ" />
        <VaryaMessage>Что за чудо-комплекс?</VaryaMessage>
        <Message name="Директор" image={engineerImg}>
          Мы как раз подъезжаем к его стройплощадке. Вот эти колонны позволят
          перерабатывать нефть практически без остатка.
        </Message>
        <VaryaMessage>Это же настоящий металлический лес!</VaryaMessage>
        <Message name="Директор" image={engineerImg}>
          Да, его проектировал компьютер, человек просто не смог бы уместить на
          такой площади 26 000 тонн металлоконструкций. Видели бы вы, как его
          монтировали! Сначала вот эта колонна высотой почти 60 метров и весом
          1200 тонн две недели ехала к нам из Петербурга. Конечно же, по воде,
          на огромной барже – по Неве, Ладоге, Онежскому озеру, Волге и,
          наконец, Москве-реке. На причале в Капотне ее ждали восемь грузовых
          платформ, а на площадке – кран, который может поднимать до 1600 тонн.
        </Message>
        <Image src={factory2Img} alt="НПЗ" />
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#404E5C" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          Оборудование общим весом 1200 тонн доставили из Петербурга в Москву на
          барже. Эта операция была признана перевозкой самого тяжелого груза
          речным транспортом по Москве-реке и вошла в Книгу рекордов России.
        </p>
        <img src={carImg} alt="Автомобиль" className={cn.block_bottom__car} />
      </div>
    </div>
  );
};

export default Slide;
