import loader from "assets/images/loader.gif";
import { FC } from "react";

import c from "./Loader.module.css";

const Loader: FC = () => {
  return (
    <div className={c.layout}>
      <img src={loader} alt="loader" className={c.loader} />
    </div>
  );
};

export default Loader;
