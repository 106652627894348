import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";
import classNames from "classnames";
import { AppContext } from "context/app-context";
import VityaGame from "games/Kits/Game/Game/Vitya/Vitya";
import { FC, useContext, useEffect, useState } from "react";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import { chapter_type } from "types";

import light from "../assets/img/light.png";
import cn from "./Game.module.scss";
import computerState1Img from "./img/computer-state_1.png";
import computerState2Img from "./img/computer-state_2.png";
import computerState3Img from "./img/computer-state_3.png";
import computerImg1 from "./img/computer_1.png";
import computerImg2 from "./img/computer_2.png";
import computerImg3 from "./img/computer_3.png";
import computerImg4 from "./img/computer_4.png";
import computerImg5 from "./img/computer_5.png";
import arrowImg from "./img/direction.png";
import arrowMobileImg from "./img/direction_mobile.png";
import explanationImg from "./img/explanation.png";
import questionImg from "./img/question.png";

const items = [
  {
    img: computerImg1,
    tooltip: "Выбросить в мусорный бак у дома",
    isError: false,
    isDropped: false,
  },
  {
    img: computerImg2,
    tooltip: "Отвезти в лес, где никто не найдет",
    isError: false,
    isDropped: false,
  },
  {
    img: computerImg3,
    tooltip: "Сдать на переработку",
    isCorrect: true,
    isError: false,
    isDropped: false,
  },
  {
    img: computerImg4,
    tooltip: "Продать",
    isError: false,
    isDropped: false,
  },
  {
    img: computerImg5,
    tooltip: "Подарить",
    isError: false,
    isDropped: false,
  },
];

type ownProps_type = {
  nextChapter: chapter_type;
  onGameFinished: () => void;
  returnBack: () => void;
};

const Game: FC<ownProps_type> = ({
  nextChapter,
  onGameFinished,
  returnBack,
}) => {
  const { isMobile } = useContext(AppContext);

  const [list, setList] = useState(items);
  const [target, setTarget] = useState<null | number>(null);
  const [gameState, setGameState] = useState<"static" | "failure" | "finish">(
    "static"
  );
  const [vityaStateData] = useState({
    static:
      "Как лучше поступить со сломанной техникой? Поставь карточки вместо знака вопроса.",
    failure: "Почти получилось! Попробуй еще раз!",
    finish:
      "Молодец, совершенно верно! Выброшенный на свалку компьютер – это потерянное ценное сырье и прямой вред природе. Всю технику нужно сдавать на переработку.",
  });

  useEffect(() => {
    const index = list.findIndex((item) => item.isError);

    if (index !== -1) {
      setTimeout(() => {
        setList((list) => {
          const items = [...list],
            item = { ...items[index] };

          item.isError = false;
          items[index] = item;

          return items;
        });
      }, 1000);

      setTimeout(
        () =>
          setGameState((gameState) =>
            gameState === "finish" ? gameState : "static"
          ),
        4000
      );
    }
  }, [list]);

  useEffect(() => {
    if (target !== null) {
      setGameState("finish");
      onGameFinished();
    }
  }, [target, onGameFinished]);

  const handleDrop = (e: any) => {
    if (e.dragData.isCorrect) {
      e.containerElem.style.visibility = "hidden";

      setTarget(e.dragData.index);

      return false;
    }

    setGameState((gameState) =>
      gameState === "finish" ? gameState : "failure"
    );

    setList((list) => {
      const items = [...list],
        item = { ...items[e.dragData.index] };

      item.isError = true;
      items[e.dragData.index] = item;

      return items;
    });
  };

  return (
    <div className={cn.game}>
      <div className={cn.background}>
        <div className={cn.background__color}></div>
        <img className={cn.background__light_top} src={light} alt="Свет" />
        <img className={cn.background__light_bottom} src={light} alt="Свет" />
      </div>
      <div
        className={classNames(cn.items, cn.items_targets, {
          [cn.items_hidden]: gameState === "finish",
        })}
      >
        <div className={classNames(cn.items__item)}>
          <div className={classNames(cn.items__item__image)}>
            <img src={computerState1Img} alt="Изображение" />
          </div>
        </div>
        <div className={classNames(cn.items__item)}>
          <div className={classNames(cn.items__item__image)}>
            <img src={computerState2Img} alt="Изображение" />
          </div>
        </div>
        <div className={classNames(cn.items__item)}>
          <div className={classNames(cn.items__item__image)}>
            <img src={computerState3Img} alt="Изображение" />
          </div>
        </div>
        <div className={classNames(cn.items__item)}>
          <DropTarget targetKey="drop" onHit={handleDrop}>
            <div
              className={classNames(cn.items__item__image, {
                [cn.items__item__image_empty]: target === null,
              })}
            >
              {target === null ? (
                <img src={questionImg} alt="Изображение" />
              ) : (
                <img src={list[target].img} alt="Изображение" />
              )}
            </div>
          </DropTarget>
        </div>
      </div>
      <img
        src={isMobile ? arrowMobileImg : arrowImg}
        alt="Стрелки"
        className={classNames(cn.direction, {
          [cn.direction_hidden]: gameState === "finish",
        })}
      />
      <div
        className={classNames(cn.items, cn.items_list, {
          [cn.items_hidden]: gameState === "finish",
        })}
      >
        {list.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(cn.items__item, {
                [cn.items__item_error]: item.isError,
              })}
            >
              <DragDropContainer
                targetKey="drop"
                onDragStart={() => {
                  setGameState((gameState) =>
                    gameState === "finish" ? gameState : "static"
                  );
                }}
                dragData={{ ...item, index }}
              >
                <div className={cn.items__item__image}>
                  <div className={cn.items__item__tooltip}>
                    <InfoIcon />
                    <span>{item.tooltip}</span>
                  </div>
                  <img src={item.img} alt="Изображение" />
                </div>
              </DragDropContainer>
            </div>
          );
        })}
      </div>

      {gameState === "finish" && (
        <div
          className={classNames(
            cn.items,
            cn.items_targets,
            cn.items_targets_finish
          )}
        >
          <div className={classNames(cn.items__item)}>
            <div className={classNames(cn.items__item__image)}>
              <img src={computerState1Img} alt="Изображение" />
            </div>
          </div>
          <div className={classNames(cn.items__item)}>
            <div className={classNames(cn.items__item__image)}>
              <img src={computerState2Img} alt="Изображение" />
            </div>
          </div>
          <div className={classNames(cn.items__item)}>
            <div className={classNames(cn.items__item__image)}>
              <img src={computerState3Img} alt="Изображение" />
            </div>
          </div>
          <div className={classNames(cn.items__item)}>
            <div className={classNames(cn.items__item__image)}>
              <img
                src={list.filter((item) => item.isCorrect)[0].img}
                alt="Изображение"
              />
            </div>
          </div>
        </div>
      )}

      <div
        className={classNames(cn.game__explanation, {
          [cn.items_hidden]: gameState === "finish",
        })}
      >
        <img src={explanationImg} alt="Пояснение" />
        <p>Перемещай карточки перетягиванием</p>
      </div>

      <VityaGame
        gameState={gameState}
        stateData={vityaStateData}
        nextGameLink={nextChapter.link}
        returnBack={returnBack}
      />
    </div>
  );
};

export default Game;
