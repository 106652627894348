import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import imageSrc from "./img/image.jpg";
import lightImg from "./img/light.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <RobyMessage>
          А вы как решаете, чем будут пахнуть ваши следующие духи?
        </RobyMessage>
        <Image src={imageSrc} alt="Роби нюхает духи" />
        <Message name="Парфюмер" image={specialistImg}>
          Создание аромата – это долгая история. Часто идея приходит мне в
          голову неожиданно, например в самолете. А иногда я вижу аромат во сне.
        </Message>
        <VityaMessage>Как это?</VityaMessage>
        <Message name="Парфюмер" image={specialistImg}>
          Например, аромат «Босиком по радуге» мне действительно приснился.
          Будто бы я иду босиком по мокрой после дождя траве и вижу радугу.
          Кажется, что еще немного – и я смогу до нее дотронуться. И потом
          восемь месяцев я пыталась описать приснившийся мне аромат, подбирала
          слова: свежесть, свежескошенная трава, воздух после грозы, зелень
          инжира, цитрусовые.
        </Message>
        <VaryaMessage>Так и рождаются ароматы?</VaryaMessage>
        <Message name="Парфюмер" image={specialistImg}>
          Этот – именно так, другие – иначе.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#73102C" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          За один раз мы способны распробовать не больше трех ароматов, дальше
          рецепторы в носу практически выключаются.
        </p>
        <img src={lightImg} className={cn.block_bottom__light} alt="Свет" />
      </div>
    </div>
  );
};

export default Slide;
