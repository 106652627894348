import Intro from "games/Kits/Intro/Intro";
import { FC } from "react";

import printerImg from "./img/printer.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div className={cn.slide}>
      <Intro
        factoryName="Компания PICASO 3D"
        title="Нарисуем - будем жить"
        intro="Глава, в которой Витя, Варя и Роби узнают, что умеют 3D-принтеры"
        titleClassname={cn.title}
      >
        <img src={printerImg} className={cn.game__printer} alt="Принтер" />
      </Intro>
    </div>
  );
};

export default Slide;
