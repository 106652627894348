import "swiper/css";
import "./carousel.scss";

import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow.svg";
import classNames from "classnames";
import Modal from "components/Kits/Modals/Modal/Modal";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { color } from "types";

import cn from "./Carousel.module.scss";

type ownProps_type = {
  slides: Array<string>;
  dir: string;
  arrowColor: color;
  arrowColorHover: color;
  [x: string]: any;
};

const Carousel: FC<ownProps_type> = ({
  slides,
  dir,
  arrowColor,
  arrowColorHover,
  ...props
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const sliderRef = useRef(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(1);
  const [slidesThumbnailsHref] = useState(
    `https://children.prommoscow.info/img/${dir}/450/`
  );
  const [isImagesLoaded, setIsImagesLoaded] = useState(false);
  const [slidesHref] = useState(
    `https://children.prommoscow.info/img/${dir}/2000/`
  );
  const style = {
    "--back-color": arrowColor,
    "--back-color-hover": arrowColorHover,
  } as React.CSSProperties;

  useEffect(() => {
    if (!slides.length) {
      return;
    }

    if (isModalVisible && !isImagesLoaded) {
      slides.forEach((imageSrc) => {
        new Image().src = slidesHref + imageSrc;
      });

      setIsImagesLoaded(true);
    }
  }, [slidesHref, slides, isModalVisible, isImagesLoaded, setIsImagesLoaded]);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    //@ts-ignore
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    //@ts-ignore
    sliderRef.current.swiper.slideNext();
  }, []);

  const SwiperSlides = slides.map((name, idx) => (
    <SwiperSlide key={idx}>
      <img src={slidesThumbnailsHref + name} alt="Слайд" />
    </SwiperSlide>
  ));

  useEffect(() => {
    //@ts-ignore
    sliderRef.current.swiper.slideToLoop(activeSlideIndex);
  }, [activeSlideIndex]);

  // Needed for Swiper adaptive behaviour
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  });

  const isMobile = width <= 768;

  return (
    <>
      <div className={classNames(props.className, cn.block_slider)}>
        <Swiper
          //@ts-ignore
          ref={sliderRef}
          slidesPerView={isMobile ? 1 : 3}
          loop={true}
          allowTouchMove={false}
          speed={0}
          onClick={(e) => {
            if (e.clickedIndex === e.activeIndex) {
              setActiveSlideIndex(e.realIndex);
              setIsModalVisible(true);
            }
          }}
          centeredSlides={true}
        >
          <div
            className={classNames(cn.arrow, cn.arrow_prev)}
            onClick={handlePrev}
            style={style}
          >
            <ArrowIcon />
          </div>
          {SwiperSlides}
          <div
            className={classNames(cn.arrow, cn.arrow_next)}
            onClick={handleNext}
            style={style}
          >
            <ArrowIcon />
          </div>
        </Swiper>
      </div>
      <Modal
        visible={isModalVisible}
        closeModal={() => setIsModalVisible(false)}
        closeIconColor="#ffffff"
      >
        <div className={cn.slider_modal}>
          <div
            className={classNames(
              cn.slider_modal__arrow,
              cn.slider_modal__arrow_prev
            )}
            onClick={() => {
              setActiveSlideIndex((idx) =>
                idx - 1 < 0 ? slides.length - 1 : idx - 1
              );
            }}
          >
            <ArrowIcon />
          </div>
          <img src={slidesHref + slides[activeSlideIndex]} alt="" />
          <div
            className={classNames(
              cn.slider_modal__arrow,
              cn.slider_modal__arrow_next
            )}
            onClick={() => {
              setActiveSlideIndex((idx) =>
                idx + 1 === slides.length ? 0 : idx + 1
              );
            }}
          >
            <ArrowIcon />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Carousel;
