import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaDialogue } from "games/Kits/Characters";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          Уже через час ребята и Роби входили в здание фабрики на Кожевнической.
          И почти сразу вместе с Еленой, начальником раскройного цеха, прошли на
          ее любимый участок.
        </Text>
        <VaryaMessage>Какие миленькие!</VaryaMessage>
        <Text>
          Варя крутила в руках розовые детские сапожки с птичками. Ну просто
          ми-ми-ми!
        </Text>
        <Message name="Начальник раскройного цеха" image={specialistImg}>
          Это наши модельеры-конструкторы стараются. От них приходит раскладка,
          а мы переносим ее на раскройный комплекс.
        </Message>
        <Text>
          Елена показала на доску с изображением частей какого-то еще не совсем
          понятного друзьям сапога и проследовала к большому синему станку с
          числовым программным управлением, который быстро и точно вырезал из
          кожи обувные заготовки.
        </Text>
        <Message name="Начальник раскройного цеха" image={specialistImg}>
          Вот этот нож занимается нарезкой деталей. А рядом наши сотрудницы
          кроят с помощью прессов.
        </Message>
        <VaryaMessage>
          Очень похоже на формочки, которыми моя мама фигурки из теста вырезает.
        </VaryaMessage>
        <Message name="Начальник раскройного цеха" image={specialistImg}>
          Да, пожалуй. Только наши формы более острые, да и прорезать кожу или
          резину труднее, чем тесто, поэтому приходится использовать пресс. А
          так, задачи похожие: нужно, чтобы как можно меньше теста, то есть
          ценной кожи, уходило в отходы.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          За 100 лет своего существования «Парижская коммуна» выпустила около
          700 миллионов пар обуви. Сложная модель сапог или кроссовок может
          состоять из 30 деталей.
        </p>
        <VaryaDialogue className={cn.block_bottom__varya} />
      </div>
    </div>
  );
};

export default Slide;
