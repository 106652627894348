import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";
import classNames from "classnames";
import RobyGame from "games/Kits/Game/Game/Roby/Roby";
import { FC, useEffect, useMemo, useState } from "react";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import { chapter_type } from "types";

import light from "../assets/img/light.png";
import cn from "./Game.module.scss";
import carImg from "./img/car.png";
import carriageImg from "./img/carriage.png";
import explanationImg from "./img/explanation.png";
import factoryImg from "./img/factory.png";
import productionImg from "./img/production.png";
import refuelingImg from "./img/refueling.png";

const items = [
  {
    img: factoryImg,
    title: "Перерабатываем нефть",
    correctIndex: 2,
    isError: false,
    isHovered: false,
  },
  {
    img: carImg,
    title: "Везем бензин на заправку",
    correctIndex: 3,
    isError: false,
    isHovered: false,
  },
  {
    img: productionImg,
    title: "Добываем нефть",
    correctIndex: 0,
    isError: false,
    isHovered: false,
  },
  {
    img: refuelingImg,
    title: "Заправляем автомобиль",
    correctIndex: 4,
    isError: false,
    isHovered: false,
  },
  {
    img: carriageImg,
    title: "Отправляем на завод",
    correctIndex: 1,
    isError: false,
    isHovered: false,
  },
];

type ownProps_type = {
  nextChapter: chapter_type;
  onGameFinished: () => void;
  returnBack: () => void;
};

const Game: FC<ownProps_type> = ({
  nextChapter,
  onGameFinished,
  returnBack,
}) => {
  const [list, setList] = useState(items);
  const [gameState, setGameState] = useState<"static" | "failure" | "finish">(
    "static"
  );
  const [robyStateData] = useState({
    static:
      "Какой путь проходит бензин, чтобы попасть в бак автомобиля? Расставь картинки в правильной последовательности.",
    failure: "Попробуй еще раз!",
    finish:
      "Молодец! Ты знаешь толк в нефтепереработке! Нефть, добытую из скважины, по трубопроводу или железнодорожным транспортом отправляют на нефтеперерабатывающий завод. Там из нее сделают бензин. А оттуда он поедет на автозаправочные станции.",
  });

  const gameFinished = useMemo(() => {
    return !list.filter((item, idx) => item.correctIndex !== idx).length;
  }, [list]);

  useEffect(() => {
    if (gameFinished) {
      setGameState("finish");
      onGameFinished();
    }
  }, [gameFinished, onGameFinished]);

  const getListWithSwitchedError = (
    list: any,
    index: number,
    isError: boolean
  ) => {
    const items = [...list],
      item = { ...items[index] };

    item.isError = isError;
    items[index] = item;

    return items;
  };

  const handleDrop = (e: any, dragToIndex: number) => {
    const correctIndex = e.dragData.correctIndex,
      dragFromIndex = e.dragData.index;

    if (dragFromIndex === dragToIndex) {
      return false;
    }

    if (correctIndex !== dragToIndex) {
      setList((list) => getListWithSwitchedError(list, dragFromIndex, true));

      setGameState((gameState) =>
        gameState === "finish" ? gameState : "failure"
      );

      setTimeout(() => {
        setList((list) => getListWithSwitchedError(list, dragFromIndex, false));
      }, 1000);

      setTimeout(() => {
        setGameState((gameState) =>
          gameState === "finish" ? gameState : "static"
        );
      }, 4000);

      return false;
    }

    setList((list) => {
      const items = [...list];

      [items[dragFromIndex], items[dragToIndex]] = [
        items[dragToIndex],
        items[dragFromIndex],
      ];
      return items;
    });
  };

  const setIsHovered = (index: number) => {
    setList((list) => {
      const items = [...list],
        item = { ...items[index] };

      item.isHovered = !item.isHovered;
      items[index] = item;

      return items;
    });
  };

  const setAllUnhoverd = () => {
    setList((list) => {
      list.map((item) => (item.isHovered = false));

      return list;
    });
  };

  return (
    <div className={cn.game}>
      <div className={cn.background}>
        <div className={cn.background__color}></div>
        <img className={cn.background__light_top} src={light} alt="Свет" />
        <img className={cn.background__light_bottom} src={light} alt="Свет" />
      </div>
      <div
        className={classNames(cn.cards, {
          [cn.cards_hidden]: gameState === "finish",
        })}
      >
        {list.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(cn.cards__item, {
                [cn.cards__item_correct]: index === item.correctIndex,
                [cn.cards__item_candrop]: item.isHovered,
                [cn.cards__item_error]:
                  item.isError && index !== item.correctIndex,
              })}
            >
              <DropTarget
                targetKey="drop"
                onHit={(e: any) => handleDrop(e, index)}
                onDragEnter={() => setIsHovered(index)}
                onDragLeave={() => setIsHovered(index)}
                data-index={index}
                key={index}
              >
                <DragDropContainer
                  targetKey="drop"
                  noDragging={index === item.correctIndex}
                  onDragStart={() => {
                    setGameState((gameState) =>
                      gameState === "finish" ? gameState : "static"
                    );
                  }}
                  onDragEnd={setAllUnhoverd}
                  dragData={{ ...item, index }}
                >
                  <div
                    className={cn.cards__item__image}
                    draggable={index !== item.correctIndex}
                  >
                    <div className={cn.cards__item__tooltip}>
                      <InfoIcon />
                      <span>{item.title}</span>
                    </div>
                    <p className="title">{item.title}</p>
                    <img src={item.img} alt={item.title} draggable={false} />
                  </div>
                </DragDropContainer>
              </DropTarget>
            </div>
          );
        })}
      </div>
      <div
        className={classNames(cn.game__explanation, {
          [cn.game__explanation_hidden]: gameState === "finish",
        })}
      >
        <img src={explanationImg} alt="Пояснение" />
        <p>Перемещай карточки перетягиванием</p>
      </div>
      {gameState === "finish" && (
        <div className={classNames(cn.cards, cn.cards_finish)}>
          {list
            .sort((a, b) => a.correctIndex - b.correctIndex)
            .map((item, index) => {
              return (
                <div
                  key={index}
                  className={classNames(cn.cards__item, cn.cards__item_correct)}
                >
                  <div className={cn.cards__item__image}>
                    <p className="title">{item.title}</p>
                    <img src={item.img} alt={item.title} draggable={false} />
                  </div>
                </div>
              );
            })}
        </div>
      )}

      <RobyGame
        gameState={gameState}
        stateData={robyStateData}
        nextGameLink={nextChapter.link}
        returnBack={returnBack}
      />
    </div>
  );
};

export default Game;
