import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import image from "./img/image.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  setLastSlide: () => void;
  [x: string]: any;
};

const Slide: FC<ownProps_type> = ({ setLastSlide, ...props }) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <p className={classNames("title", cn.title)}>
        Глава, в которой Витя, Варя и Роби подводят итоги своих путешествий
      </p>
      <WhiteBlock className={cn.whiteBlock}>
        <VityaMessage>
          Что же, братцы, благодаря прадедушкиному альбому мы совершили
          четырнадцать путешествий на предприятия и в лаборатории Москвы. Что
          запомнилось больше других? Начнем с тебя, Роби!
        </VityaMessage>
        <RobyMessage>
          Откровенно говоря, я в растерянности. Было бы естественным выбрать
          что-то свое, например «Фора Роботикс», но благодаря этим экскурсиям я
          стал более человечным, что ли…{" "}
        </RobyMessage>
        <RobyMessage>
          Право, не знаю, как объяснить. Я горжусь, что ты, Витя, создал меня в
          таком замечательном городе, как Москва. И я рад за своих собратьев,
          которые помогают людям и заменяют их на участках, где требуются
          расчет, точность и выдержка.
        </RobyMessage>
        <VityaMessage>
          Роби, какая прекрасная речь! Нет, мы вполне серьезно. Правда, Варвара?
        </VityaMessage>
        <VaryaMessage>
          Чистая правда. Роби действительно становится все больше похожим на
          человека.
        </VaryaMessage>
        <Image src={image} alt="Друзья" className={cn.image} />
        <VityaMessage>И это прекрасно. А ты, Варя, что отметишь?</VityaMessage>
        <VaryaMessage>
          Конечно, мороженое. И «Агушу». И сапожки с птичками на фабрике
          «Парижская коммуна». И еще…
        </VaryaMessage>
        <RobyMessage>Что?</RobyMessage>
        <VaryaMessage>
          Мне очень хочется поскорее получить духи, которые для меня создаст
          Полина.
        </VaryaMessage>
        <VityaMessage>
          Понятно. Ну а я вот что скажу, друзья. Все было здорово, но в
          прадедушкином альбоме еще много страниц. А это значит, что нас ждут
          новые открытия!
        </VityaMessage>
      </WhiteBlock>
      <div className={classNames("title", cn.button)} onClick={setLastSlide}>
        Твой результат
      </div>
    </div>
  );
};

export default Slide;
