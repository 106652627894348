import Intro from "games/Kits/Intro/Intro";
import { FC } from "react";

import sausageImg from "./img/sausage.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div className={cn.slide}>
      <Intro
        factoryName="Завод «Дымов»"
        title="Расколбас"
        intro="Глава, в которой Витя, Варя и Роби узнали секрет приготовления молочных сосисок на заводе «Дымов»"
      >
        <img src={sausageImg} className={cn.game__sausage} alt="Колбаса" />
      </Intro>
    </div>
  );
};

export default Slide;
