import classNames from "classnames";
import Carousel from "components/Blocks/Carousel/Carousel";
import { FC, useEffect } from "react";

import cn from "./Slide.module.scss";

const slides = [
  "DSC_0709.jpg",
  "DSC_0888.jpg",
  "DSC_0916.jpg",
  "DSC_0957.jpg",
  "DSC_8781.jpg",
  "slide_01.jpg",
  "slide_02.jpg",
  "slide_03.jpg",
];

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = ({
  changeBackColor,
  changeCloudsColor,
  ...props
}) => {
  useEffect(() => {
    changeBackColor("#5F5DDE", "#5F5DDE");
    changeCloudsColor("#8CABFB ");

    return () => {
      changeBackColor();
      changeCloudsColor();
    };
  }, [changeBackColor, changeCloudsColor]);

  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <p
        className={classNames("title", cn.slide__title)}
        style={{ fontSize: 36, lineHeight: "45px", textAlign: "center" }}
      >
        Прогулка по Лианозовскому молочному комбинату
      </p>
      <Carousel
        slides={slides}
        dir="Lianozovskiy"
        arrowColor="rgba(58, 75, 225, 0.6)"
        arrowColorHover="rgba(58, 75, 225, 0.3)"
      />
    </div>
  );
};

export default Slide;
