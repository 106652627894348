import { VityaDialogue } from "games/Kits/Characters";

import cn from "./Vitya.module.scss";

const VityaInvitation = () => {
  return (
    <div className={cn.vitya}>
      <VityaDialogue />
      <span className="title text-cloud">Поиграем?</span>
    </div>
  );
};

export default VityaInvitation;
