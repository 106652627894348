import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import imageSrc from "./img/image.jpg";
import wiresImg from "./img/wires.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <RobyMessage>
          Не поверите, но экскурсовод будет нас ждать у Эйфелевой башни!
        </RobyMessage>
        <Text>
          И действительно, сразу за коваными воротами располагалась уменьшенная
          копия главной парижской достопримечательности, а рядом с ней ребят
          ждала директор PR-службы завода Чулпан.
        </Text>
        <Image src={imageSrc} alt="Друзья" />
        <VityaMessage>
          А почему вы решили здесь поставить башню Эйфеля?
        </VityaMessage>
        <Message name="PR-директор" image={specialistImg}>
          Здесь у нас артезианская скважина. Был объявлен конкурс на лучшую идею
          ее оформления, и победил проект Эйфелевой башни. Здесь мы обычно
          начинаем путешествие по пяти нашим заводам. И первым будет «Элкат»,
          где медь плавят и превращают в толстую проволоку – катанку.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#7B651A" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          ЭТО ИНТЕРЕСНО!
        </p>
        <p>
          На «Москабельмет» делают подводные кабели. Таких заводов всего четыре
          в мире!
        </p>
        <img src={wiresImg} className={cn.block_bottom__wires} alt="Провода" />
      </div>
    </div>
  );
};

export default Slide;
