import classNames from "classnames";
import Carousel from "components/Blocks/Carousel/Carousel";
import Text from "components/Blocks/Text/Text";
import { FC, useEffect } from "react";

import cn from "./Slide.module.scss";

const slides = [
  "TIM_1073.jpg",
  "TIM_1178.jpg",
  "TIM_1247.jpg",
  "TIM_1253.jpg",
  "TIM_1311.jpg",
  "TIM_1326.jpg",
  "TIM_1408.jpg",
];

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = ({
  changeBackColor,
  changeCloudsColor,
  ...props
}) => {
  useEffect(() => {
    changeBackColor("#795032", "#795032");
    changeCloudsColor("#97633C");

    return () => {
      changeBackColor();
      changeCloudsColor();
    };
  }, [changeBackColor, changeCloudsColor]);

  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <Text className={classNames("title", cn.slide__title)}>
        Прогулка по обувной фабрике «Парижская коммуна»
      </Text>
      <Carousel
        slides={slides}
        dir="Paris_kommuna"
        arrowColor="rgba(121, 80, 50, 0.6)"
        arrowColorHover="rgba(121, 80, 50, 0.3)"
      />
    </div>
  );
};

export default Slide;
