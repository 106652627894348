import { VaryaDialogue } from "games/Kits/Characters";

import cn from "./Varya.module.scss";

const VaryaInvitation = () => {
  return (
    <div className={cn.varya}>
      <VaryaDialogue />
      <span className="title text-cloud">Поиграем?</span>
    </div>
  );
};

export default VaryaInvitation;
