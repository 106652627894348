import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaDialogue } from "games/Kits/Characters";
import RobyInvitation from "games/Kits/Game/Invitation/Roby/Roby";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <RobyMessage>А можно ли создать цифровую модель… человека?</RobyMessage>
        <Message name="Директор" image={directorImg}>
          Скопировать его тело и лицо довольно легко, мы сами недавно сделали
          цифрового двойника одного нашего сотрудника. А вот создание настоящей
          копии – дело будущего. Представляете, что можно было бы с ней делать?
        </Message>
        <VaryaMessage>
          Больше не придется ходить в поликлинику и сдавать анализы? Врач и так
          все про тебя будет знать?
        </VaryaMessage>
        <VityaMessage>
          Перед операцией хирург сможет потренироваться на двойнике и учесть все
          возможные осложнения?
        </VityaMessage>
        <RobyMessage>
          Испытания новых лекарств будут занимать не го­ды, а дни? – проявил
          заботу о человечестве Роби.
        </RobyMessage>
        <Message name="Директор" image={directorImg}>
          Это и многое другое, что мы еще и представить себе не можем. Так что,
          Варя, расти быстрее, тебя ждет большая работа!
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#500A73" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          Цифровыми копиями обзаводятся многие города. Самый подробный двойник у
          Сингапура: на модели есть даже скамейки в парках и фонарные столбы.
          Копия помогает предсказать, как строительство нового завода повлияет
          на окружающую среду или изменит ситуацию на дорогах.
        </p>
        <VaryaDialogue className={cn.block_bottom__varya} />
      </div>
      <RobyInvitation />
    </div>
  );
};

export default Slide;
