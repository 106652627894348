import classNames from "classnames";
import { FC } from "react";

import cn from "./WhiteBlock.module.scss";

type ownProps_type = {
  children: React.ReactNode;
  [x: string]: any;
};

const WhiteBlock: FC<ownProps_type> = ({ children, ...restProps }) => {
  return (
    <div
      {...restProps}
      className={classNames("block", cn.block_white, restProps.className)}
    >
      {children}
    </div>
  );
};

export default WhiteBlock;
