import classNames from "classnames";
import Footer from "components/Kits/Footer/Footer";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setChapterActive_tc } from "redux/reducers/app_reducer";
import {
  getChaptersCount,
  getCompletedChaptersCount,
  getFirstGameChapter,
  getNextNonCompletedChapter
} from "redux/selectors/app_selector";
import { AppStateType } from "redux/store";

import Navigator from "../../games/Kits/Navigator/Navigator";
import background from "./assets/background.png";
import light from "./assets/light.png";
import Slide1 from "./slides/Slide1/Slide";
import Slide2 from "./slides/Slide2/Slide";
import Slide3 from "./slides/Slide3/Slide";
import Slide4 from "./slides/Slide4/Slide";
import Slide5 from "./slides/Slide5/Slide";
import cn from "./Start.module.scss";

type ownProps_type = {
  chapterId: number;
};

const Start: FC<ownProps_type & reduxProps_type> = ({
  chapterId,
  setChapterActive_tc,
  chaptersCount,
  completedChaptersCount,
  nextNonCompletedChapter,
  firstGameChapter,
}) => {
  useEffect(() => {
    setChapterActive_tc(chapterId);
  }, [setChapterActive_tc, chapterId]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  const slides = [
    <Slide1 className={cn.slide} />,
    <Slide2 className={cn.slide} />,
    <Slide3 className={cn.slide} />,
    <Slide4 className={cn.slide} />,
    <Slide5 className={cn.slide} />,
  ];

  const changeSlide = (dir: -1 | 1 = 1, slideNumber: number | null = null) => {
    setCurrentSlide((cs) => {
      if (slideNumber !== null) {
        return slideNumber;
      }

      if (dir === 1) {
        return cs === slides.length - 1 ? cs : ++cs;
      }

      return cs === 0 ? 0 : --cs;
    });
  };

  const nextLink = useMemo(() => {
    let linkText = !completedChaptersCount ? "Начать игру" : "Продолжить игру",
      linkTo = nextNonCompletedChapter
        ? nextNonCompletedChapter.link
        : firstGameChapter.link;

    return {
      link: linkTo,
      text: linkText,
    };
  }, [completedChaptersCount, nextNonCompletedChapter, firstGameChapter]);

  const handleGameClick = useCallback(() => {
    if (!nextLink.link) {
      return;
    }

    navigate(nextLink.link, { replace: true });
  }, [navigate, nextLink]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSlide]);

  return (
    <div className={classNames("main-wrapper", cn.wrapper)}>
      <div className={cn.wrapper__background}>
        <img src={background} alt="Фон" />
      </div>
      <div className={classNames("game__container", cn["game__container"])}>
        <div className={classNames("game__wrapper", cn["game__wrapper"])}>
          <Navigator
            elementsCount={slides.length}
            activeIdx={currentSlide}
            elementClick={changeSlide}
            dotClass={cn.navigatorDot}
          />

          <div style={{ position: "relative" }}>
            <div className={cn.game__background}>
              <img
                src={light}
                alt="Фигура"
                className={cn.game__background__light_top}
              />
              <img
                src={light}
                alt="Фигура"
                className={cn.game__background__light_bottom}
              />
            </div>
            <div className={cn.progress}>
              <span>
                Пройдено игр{" "}
                <span className={cn.progress__count}>
                  ({completedChaptersCount}/{chaptersCount} игр)
                </span>
              </span>
              <div className={cn.progress__line}>
                <div
                  className={cn.progress__line__back}
                  style={{
                    width:
                      Math.floor(
                        (completedChaptersCount / chaptersCount) * 100
                      ) + "%",
                  }}
                ></div>
              </div>
              {nextLink.link && (
                <Link to={nextLink.link} className={cn.progress__button}>
                  {nextLink.text}
                </Link>
              )}
            </div>
            <div className={cn.game__body}>{slides[currentSlide]}</div>
          </div>
        </div>
      </div>
      <Footer
        prevDisabled={currentSlide === 0}
        className={classNames(cn.footer)}
        nextHidden={currentSlide === slides.length - 1}
        onNextClick={() => changeSlide(1)}
        onPrevClick={() => changeSlide(-1)}
        onPlayClick={handleGameClick}
        playHidden={nextLink.link ? false : true}
      />
    </div>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  chaptersCount: getChaptersCount(state),
  completedChaptersCount: getCompletedChaptersCount(state),
  nextNonCompletedChapter: getNextNonCompletedChapter(state),
  firstGameChapter: getFirstGameChapter(state),
});

const connector = connect(mapStateToProps, {
  setChapterActive_tc,
});
type reduxProps_type = ConnectedProps<typeof connector>;

export default connector(Start);
