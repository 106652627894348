import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import doctorImg from "../../assets/img/doctor.png";
import imageSrc from "./img/image.jpg";
import korotyshkinImg from "./img/Korotyshkin.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={imageSrc} alt="Варя" />
        <VaryaMessage>А вы можете сделать очки в форме цветочка?</VaryaMessage>
        <Message name="Главный инженер" image={doctorImg}>
          Такого мы еще не делали, но можем предложить тебе восьмиугольные
          линзы. Или шестиугольные, как пчелиные соты. Согласна? А Вите я бы
          посоветовал нашу спортивную модель «Марафон» – разрабатывая их, мы
          думали о молодых людях, которые передвигаются по Москве на самокатах.
          Ты ведь катаешься на самокате? У этих очков мягкая, плотно прилегающая
          оправа, а линзы янтарного цвета увеличивают яркость и контрастность и
          помогают при недостаточном освещении.
        </Message>
        <VityaMessage>
          Здорово! Мне как раз до моей лаборатории 15 минут на самокате.
        </VityaMessage>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#09726D" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          ЭТО ИНТЕРЕСНО!
        </p>
        <p>
          Спортивные очки «Айкрафт» тестировал пловец Евгений Коротышкин,
          чемпион мира 2010 года и серебряный призер Олимпиады 2012 года.
        </p>
        <img
          src={korotyshkinImg}
          alt="Коротышкин"
          className={cn.block_bottom__person}
        />
      </div>
    </div>
  );
};

export default Slide;
