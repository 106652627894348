import Intro from "games/Kits/Intro/Intro";
import { FC } from "react";

import vegetablesImg from "./img/vegetables.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div className={cn.slide}>
      <Intro
        factoryName="Агрохолдинг «Московский»"
        title="Зеленая энергия"
        intro="Глава, в которой Роби угощает Витю и Варю капустными чипсами"
        withCircle={false}
      >
        <img src={vegetablesImg} className={cn.game__vegetables} alt="Овощи" />
      </Intro>
    </div>
  );
};

export default Slide;
