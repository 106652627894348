import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyInfo } from "games/Kits/Characters";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import cabbageImg from "./img/cabbage.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          Андрей привел ребят в следующую теплицу. Капуста оказалась целым
          деревом с большими курчавыми листьями.
        </Text>
        <VaryaMessage>
          Ничего себе! Это же не капуста, а целая пальма!
        </VaryaMessage>
        <Message image={directorImg} name="Директор">
          Попробуй!
        </Message>
        <VaryaMessage>М-м-м. Вкусно! А чипсы такие же вкусные?</VaryaMessage>
        <Message image={directorImg} name="Директор">
          Чипсы ждут вас по адресу: семнадцатая секция, четвертая стойка,
          семнадцатый куст.
        </Message>
        <Text>
          Ребята прошли квест и под семнадцатым кустом действительно обнаружили
          несколько пакетов чипсов со вкусом чили.
        </Text>
        <VityaMessage>
          Фантастика! В следующий раз в кино возьму вместо попкорна.
        </VityaMessage>
        <VaryaMessage>А я не люблю острое.</VaryaMessage>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#796419" />
      <div className={classNames("block", cn.block_reference)}>
        <p className={classNames("title", cn.block_reference__title)}>
          СПРАВКА
        </p>
        <p>
          Сегодня кейл выращивают на полутора гектарах, в день предприятие
          отправляет в магазины до 400 килограммов этой суперполезной капусты.
        </p>
        <img
          src={cabbageImg}
          alt="Капуста"
          className={cn.block_reference__cabbage}
        />
      </div>
      <Clips topColor="#796419" bottomColor="#18446C" doubled />
      <div className={classNames("block", cn.block_bottom)}>
        <p>
          Капуста кейл очень полезная! В ней содержится много минералов и
          витаминов.
          <br />
          <br />
          Если кушать кейл регулярно, то зрение будет острым, кости – крепкими,
          а иммунитет – сильным!
        </p>
        <RobyInfo className={cn.block_bottom__roby} />
      </div>
    </div>
  );
};

export default Slide;
