import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyInfo } from "games/Kits/Characters";
import { FC } from "react";

import engineerImg from "../../assets/img/engineer.png";
import greatWallmg from "./img/great-wall.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VityaMessage>
          А куда деваются старые телевизоры? Что-то я ни одного пока не видел.
        </VityaMessage>
        <Message name="Главный инженер" image={engineerImg}>
          К ним у нас тоже отдельный подход.
        </Message>
        <Text>
          Ребята подошли к прозрачной камере, в которой был закреплен пыльный
          кинескоп. К нему одновременно потянулись изящные клювы четырех
          манипуляторов. Дотронулись, отошли – и верх кинескопа легко снялся.
        </Text>
        <VaryaMessage>Как они это делают?</VaryaMessage>
        <Message name="Главный инженер" image={engineerImg}>
          Кинескоп состоит из двух видов стекла, соединенных швом. Аппарат
          сначала нагревает место соединения, а потом охлаждает, и шов
          расходится.
        </Message>
        <Image src={greatWallmg} alt="Великая стена" className={cn.image}>
          <p className="title">
            В 2021 году люди всего мира выбросили почти 60 миллионов тонн
            электронных приборов. Столько же весит самое большое сооружение в
            мире – Великая Китайская стена!
          </p>
        </Image>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8 " />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          Каждый россиянин ежегодно выбрасывает 11 кг электрических приборов.
        </p>
        <RobyInfo className={cn.block_bottom__roby} />
      </div>
    </div>
  );
};

export default Slide;
