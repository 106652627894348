import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaDialogue } from "games/Kits/Characters/index";
import { FC } from "react";

import constructorImg from "../../assets/img/constructor.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VityaMessage>А как же «Протоны»? Их больше не будет?</VityaMessage>
        <Message name="Конструктор" image={constructorImg}>
          Нет, мы продолжим их запускать с Байконура до 2025 года. А потом их
          заменит «Ангара», которая летает на экологичном топливе – керосине и
          кислороде, почти как самолеты.
        </Message>
        <VaryaMessage>
          И вы прямо здесь ее собираете, а потом везете на космодром?
        </VaryaMessage>
        <Message name="Конструктор" image={constructorImg}>
          Не совсем. Ракету собирают и тщательно проверяют все системы, проводят
          испытания. Потом еще две недели мы тестируем электронику. Затем
          разбираем, грузим в вагоны и вот по этим рельсам отправляем в путь.
        </Message>
        <VaryaMessage>Куда? На Байконур?</VaryaMessage>
        <Message name="Конструктор" image={constructorImg}>
          Нет, «Ангара» пока взлетает только с космодрома Плесецк в
          Архангельской области, но скоро для нее будет построен стартовый
          комплекс и на Восточном.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          На космодроме Восточный строится стартовый комплекс для «Ангары»
          площадью 109 гектаров, рассчитанный на 10 пусков в год.
        </p>
        <VaryaDialogue className={cn.block_bottom__varya} />
      </div>
    </div>
  );
};

export default Slide;
