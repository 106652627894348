import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import VityaInvitation from "../../../Kits/Game/Invitation/Vitya/Vitya";
import directorImg from "../../assets/img/director.png";
import naurashaImg from "../../assets/img/naurasha.png";
import image from "./img/image.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VaryaMessage>
          Вот еще, нужны мне твои яблоки. Можно мне лучше набор с божьей
          коровкой?
        </VaryaMessage>
        <Message name="Директор" image={directorImg}>
          Только не отпускай ее на небо.
        </Message>
        <VaryaMessage>Спасибо. И тебе, Наураша, спасибо.</VaryaMessage>
        <Message name="Наураша" image={naurashaImg}>
          Good bye!
        </Message>
        <VaryaMessage>Он еще и по-английски говорит?</VaryaMessage>
        <Message name="Директор" image={directorImg}>
          И не только. Еще на французском, немецком и японском.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#46646A" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          С наборами «Научные развлечения» можно «увидеть» ультразвук и понять,
          как разговаривают дельфины.
        </p>
        <img src={image} alt="Роби" className={cn.block_bottom__image} />
      </div>
      <VityaInvitation />
    </div>
  );
};

export default Slide;
