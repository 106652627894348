import classNames from "classnames";
import { FC } from "react";

import robyImg from "./img/Roby.png";
import varyaImg from "./img/Varya.png";
import vityaImg from "./img/Vitya.png";
import cn from "./Message.module.scss";

type ownProps_type = {
  children: React.ReactNode;
  [x: string]: any;
};

const AllMessage: FC<ownProps_type> = ({ children, ...restProps }) => {
  return (
    <div
      {...restProps}
      className={classNames(
        cn.message,
        cn.message_vertical,
        restProps.className
      )}
    >
      <div className={cn.message__top}>
        <div className={cn.message__author}>
          <img
            src={vityaImg}
            className={cn.message__author__image}
            alt="Витя"
          />
          <span className={cn.message__author__name}>Витя</span>
        </div>
        <div className={cn.message__author}>
          <img src={robyImg} className={cn.message__author__image} alt="Роби" />
          <span className={cn.message__author__name}>Роби</span>
        </div>
        <div className={cn.message__author}>
          <img
            src={varyaImg}
            className={cn.message__author__image}
            alt="Варя"
          />
          <span className={cn.message__author__name}>Варя</span>
        </div>
      </div>
      <div className={cn.message__body}>{children}</div>
    </div>
  );
};

export default AllMessage;
