import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VityaMessage>Интересные люди к вам обращаются.</VityaMessage>
        <Message name="Директор" image={directorImg}>
          Еще какие! Недавно на нашем оборудовании сделали модель надувного
          спасательного модуля. Представляете, его можно сбросить из вертолета с
          высоты 50 метров, конструкция автоматически надувается сжатым воздухом
          из баллона и превращается в плавучий дом, в котором могут укрыться 12
          человек. Модуль оборудован системой сбора и фильтрации дождевой воды,
          а также якорями, так что в нем вполне можно пережить наводнение.
        </Message>
        <RobyMessage>
          Я хотел бы вернуться к моей проблеме. Можно ли на вашем оборудовании
          напечатать мне новую ходовую часть? Я решил, что для передвижения по
          лунному грунту мое колесо не слишком подходит.
        </RobyMessage>
        <Message name="Директор" image={directorImg}>
          Конечно, тем более что на наших принтерах уже давно печатают прототипы
          электросамокатов. Да и с роботами мы часто работаем. Кстати, первый
          принтер мы собрали еще в институте, когда нам понадобилось изготовить
          детали для робота-паука – нашего курсового проекта. А сейчас наши
          принтеры помогают создавать вот таких красавиц – познакомьтесь с
          Алисой. У нее напечатаны глаза, череп с подвижной нижней челюстью и
          руки.
        </Message>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
