import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import guideImg from "../../assets/img/guide.png";
import bacteriaImg from "./img/bacteria.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VaryaMessage>А если вдруг что-то не так?</VaryaMessage>
        <Message name="Экскурсовод" image={guideImg}>
          Тогда мы отправляем всю партию молока обратно. Это же пищевое
          производство, мы не имеем права рисковать. В некоторые цеха
          посторонним вход вообще запрещен.
        </Message>
        <RobyMessage>Промышленный шпионаж?</RobyMessage>
        <Message name="Экскурсовод" image={guideImg}>
          В первую очередь стерильность. На многих наших линиях мы полностью
          исключили контакт человека с продукцией. Но отчасти есть правда и в
          твоем, Роби, предположении. Некоторые производственные процессы мы
          предпочитаем держать в секрете. Ну а теперь пойдемте в цех.
        </Message>
      </WhiteBlock>
      <Clips topColor="##D0D0D0" bottomColor="#1D99C0" />
      <div className={classNames("block", cn.block_interesting)}>
        <p className={classNames("title", cn.block_interesting__title)}>
          ЭТО ИНТЕРЕСНО!
        </p>
        <p>
          10 миллионов полезных бактерий содержится в 1 грамме питьевого
          йогурта.
        </p>
        <img
          src={bacteriaImg}
          alt="Бактерия"
          className={cn.block_interesting__bacteria}
        />
      </div>
    </div>
  );
};

export default Slide;
