import { AppStateType } from "redux/store";
import { createSelector } from "reselect";

export const getIsInitialized = (state: AppStateType) =>
  state.app.isInitialized;
export const getChapters = (state: AppStateType) => state.app.chapters;
export const getGameChapters = createSelector(getChapters, (chapters) => {
  return chapters.filter((c) => c.type === "game");
});
export const getActiveChapter = createSelector(getChapters, (chapters) => {
  return chapters.filter((c) => c.isActive)[0];
});
export const getActiveChapterIndex = createSelector(getChapters, (chapters) => {
  return chapters.filter((c) => c.isActive)[0].id;
});
export const getNextChapter = createSelector(getChapters, (chapters) => {
  return chapters[chapters.findIndex((c) => c.isActive) + 1];
});
export const getFirstGameChapter = createSelector(getChapters, (chapters) => {
  return chapters.filter((chapter) => chapter.type === "game")[0];
});
export const getNextNonCompletedChapter = createSelector(
  getChapters,
  (chapters) => {
    return chapters.filter(
      (chapter) => !chapter.isCompleted && chapter.type === "game"
    )[0];
  }
);

export const getChaptersCount = createSelector(getChapters, (chapters) => {
  return chapters.filter((c) => c.type === "game").length;
});
export const getCompletedChaptersCount = createSelector(
  getChapters,
  (chapters) => {
    return chapters.filter((c) => c.isCompleted).length;
  }
);
