import Intro from "games/Kits/Intro/Intro";
import { FC } from "react";

import glassesImg from "./img/glasses.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div className={cn.slide}>
      <Intro
        factoryName="Завод «Айкрафт»"
        title="Лето на носу"
        intro="Глава, в которой Витя и Варя выбирают Роби модные очки на заводе «Айкрафт»"
      >
        <img src={glassesImg} className={cn.game__glasses} alt="Очки" />
      </Intro>
    </div>
  );
};

export default Slide;
