import arrowRight from "assets/images/icons/arrow-next.svg";
import arrowLeft from "assets/images/icons/arrow-prev.svg";
import classNames from "classnames";
import { FC } from "react";

import Button from "./Button";

type ownProps_type = {
  onPrevClick: () => void;
  onNextClick: () => void;
  onPlayClick: () => void;
  [x: string]: any;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
  prevHidden?: boolean;
  nextHidden?: boolean;
  playHidden?: boolean;
};

const Footer: FC<ownProps_type> = ({
  onPrevClick,
  onNextClick,
  onPlayClick,
  prevDisabled = false,
  nextDisabled = false,
  prevHidden = false,
  nextHidden = false,
  playHidden = false,
  ...restProps
}) => {
  return (
    <div {...restProps} className={classNames("footer", restProps.className)}>
      {!prevHidden && (
        <Button onClick={onPrevClick} disabled={prevDisabled}>
          Назад <img src={arrowLeft} alt="назад" />
        </Button>
      )}
      {!nextHidden && (
        <Button onClick={onNextClick} disabled={nextDisabled}>
          Вперёд <img src={arrowRight} alt="Вперёд" />
        </Button>
      )}
      {!playHidden && (
        <Button onClick={onPlayClick} variant="red">
          Играть
        </Button>
      )}
    </div>
  );
};

export default Footer;
