import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import carImg from "./img/car.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Message name="Директор" image={directorImg}>
          Вот это наш шоурум.
        </Message>
        <VaryaMessage>Ух ты!</VaryaMessage>
        <Text>
          Варя кинулась рассматривать зеленого Халка с горой мышц, Капитана
          Америку, Железного Человека, Бэтмена и Супермена.
        </Text>
        <Message name="Директор" image={directorImg}>
          Это все на наших принтерах напечатано. Мы их здесь выставили, потому
          что все любят супергероев. Но наши установки умеют делать и серьезные
          вещи.
        </Message>
        <VaryaMessage>А как вообще можно напечатать Халка?</VaryaMessage>
        <Message name="Директор" image={directorImg}>
          Сначала вам нужно нарисовать его трехмерную модель и загрузить в
          принтер, можно даже на флешке. Затем вот сюда вы помещаете катушку
          пластика и протягиваете кончик нити к печатной головке и соплу. Когда
          мы начнем печать, пластик разогреется до 200 градусов, станет жидким и
          будет выдавливаться через сопло. Печатная головка двигается и
          постепенно, слой за слоем, формирует фигуру нашего Халка.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#6F2525 " />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          На принтерах PICASO 3D печатались детали настоящих гоночных
          автомобилей. Часто такие детали просто невозможно изготовить
          традиционными способами.
        </p>
        <img src={carImg} alt="Автомобиль" className={cn.block_bottom__image} />
      </div>
    </div>
  );
};

export default Slide;
