import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaDialogue } from "games/Kits/Characters";
import { FC } from "react";

import iceCream from "../../assets/img/ice-cream.png";
import technologistImg from "../../assets/img/technologist.png";
import iceCreamScoops from "./img/scoops.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <img src={iceCream} alt="Мороженое" className={cn.white__iceCream} />
        <VityaMessage>Из чего вообще состоит мороженое?</VityaMessage>
        <Message name="Технолог" image={technologistImg}>
          В подготовительной смеси всего четыре компонента: сливки, молоко,
          сахар и вода.
        </Message>
        <VaryaMessage>Какой запах! Я, пожалуй, тут останусь.</VaryaMessage>
        <Message name="Технолог" image={technologistImg}>
          Ты правильно выбрала место, Варенька! В этой емкости почти тонна
          жидкого шоколада, скоро его добавят в смесь. Все делает автоматика,
          оператор только выбирает рецепт.
        </Message>
        <VityaMessage>А почему эти трубы горячие?</VityaMessage>
        <Message name="Технолог" image={technologistImg}>
          Здесь происходит очень важный процесс – смесь за 26 секунд должна
          успеть нагреться до 85–86 градусов – при такой температуре
          останавливается развитие бактерий – и снова остыть.
        </Message>
        <VaryaMessage>А можно ее попробовать?</VaryaMessage>
        <Message name="Технолог" image={technologistImg}>
          Это, к сожалению, не получится. Все происходит в закрытой системе
          стерильных труб. А вот сюда попадает готовая смесь.
        </Message>
        <Text>
          Светлана провела экскурсантов в помещение, в стенах которого сияли
          сталью огромные люки.
        </Text>
        <Message name="Технолог" image={technologistImg}>
          В этих резервуарах, на 25 тонн каждый, смесь 4–6 часов созревает. А
          теперь посмотрим, как из нее делают мороженое.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D2D2D2" bottomColor="#6E168D" doubled />
      <div className={classNames("block", cn.interesting)}>
        <VaryaDialogue className={cn.interesting__image} />
        <p
          style={{ fontSize: 48, lineHeight: "61px", marginBottom: 30 }}
          className="title"
        >
          Это интересно
        </p>
        <p>
          Мороженому можно придать самые разные цвета, причем используя только
          натуральные красители. Розовый — это свекольный сок, а оттенки синего
          и зеленого получают, добавляя экстракт морских водорослей.
        </p>
        <img
          src={iceCreamScoops}
          alt="Мороженое"
          className={cn.interesting__scoops}
        />
      </div>
      <Clips topColor="#6E168D" bottomColor="#18446C" />
      <div className={classNames("block", cn.block_bottom)}>
        <p
          style={{ fontSize: 96, lineHeight: "121px", marginBottom: 30 }}
          className={classNames("title", cn.title)}
        >
          7300 эскимо и стаканчиков
        </p>
        <p>
          мороженого производится за один час на фабрике «Московское мороженое»
        </p>
        <img
          src={iceCream}
          alt="Мороженое"
          className={cn.block_bottom__image1}
        />
        <img
          src={iceCream}
          alt="Мороженое"
          className={cn.block_bottom__image2}
        />
      </div>
    </div>
  );
};

export default Slide;
