import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaDialogue } from "games/Kits/Characters";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import perfumeImg from "./img/image.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VityaMessage>А что потом?</VityaMessage>
        <Message name="Парфюмер" image={specialistImg}>
          Когда идея сформулирована, можно браться за написание формулы. В этом
          мне помогают мои записи.
        </Message>
        <Text>Полина показала внушительные исписанные тетради.</Text>
        <Message name="Парфюмер" image={specialistImg}>
          Я начала их вести еще во время учебы и продолжаю пополнять, описывая
          новые ингредиенты и формулы. Например, пишу, с каким цветом
          ассоциируется ингредиент: белым, как свежевыстиранное белье, сохнущее
          на морозе, нежно-розовым, а может, с карминовым. С чем можно его
          сравнить: с морозным узором на стекле, теплым прикосновением,
          послеполуденным зноем или северным ветром.
        </Message>
        <Text>
          Увлекшись рассказом, Полина подошла к шкафу, открыла дверцы и стала
          передвигать на полках флаконы – крохотные, прозрачные, и большие,
          коричневого стекла.
        </Text>
        <Image src={perfumeImg} alt="Изображение">
          <VaryaDialogue className={cn.varya} />
        </Image>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
