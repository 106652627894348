import "video-react/dist/video-react.css";

import { ReactComponent as PlayIcon } from "assets/images/icons/play.svg";
import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import Modal from "components/Kits/Modals/Modal/Modal";
import { AppContext } from "context/app-context";
import { RobyDialogue, VaryaDialogue } from "games/Kits/Characters";
import { FC, useCallback, useContext, useRef, useState } from "react";
import { BigPlayButton, Player } from "video-react";

import iceCream from "../../assets/img/ice-cream.png";
import technologistImg from "../../assets/img/technologist.png";
import factoryImg from "./img/factory.png";
import tvImg from "./img/tv.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false),
    videoRef = useRef<HTMLVideoElement | null>(null);

  const closeModal = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pause();
    }

    setIsModalVisible(false);
  }, [videoRef, setIsModalVisible]);

  const { isMobile } = useContext(AppContext);

  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white} style={{ zIndex: 1 }}>
        <img src={iceCream} alt="Мороженое" className={cn.white__iceCream} />
        <VaryaMessage>
          Скажите, а разве это трудно — делать мороженое? Надо просто взять
          молоко, добавить туда сахара и все это заморозить. Ну а потом посыпать
          шоколадом и орешками.
        </VaryaMessage>
        <Message name="Технолог" image={technologistImg}>
          А ты попробуй сделать это дома, в морозилке. Но, боюсь, результат тебя
          разочарует. Вместо воздушной сливочной массы ты получишь хрустящие на
          зубах кристаллики молочного льда.
        </Message>
        <VaryaMessage>А как же тогда?</VaryaMessage>
        <Message name="Технолог" image={technologistImg}>
          Китайцы решили эту задачу еще в XIII веке. Емкость с молоком помещали
          в соленую воду, выносили на мороз (соленая вода остается жидкой даже
          при температуре –4 градуса) и долго трясли. Немного терпения – и
          получалось нежное мороженое.
        </Message>
        <VaryaMessage>И вы до сих пор вот так… трясете?</VaryaMessage>
        <Message name="Технолог" image={technologistImg}>
          А вот сейчас мы пойдем в цех и все узнаем.
        </Message>
      </WhiteBlock>
      <div className={cn.block_factory}>
        <div className={cn.block_factory__image}>
          <img src={factoryImg} alt="Фабрика" />
        </div>
        <div className={cn.block_factory__roby}>
          <RobyDialogue />
          <div
            className={classNames("text-cloud", cn.block_factory__roby__text)}
          >
            А ты знал, что самое популярное мороженое в мире – ванильное?
          </div>
        </div>

        <VaryaDialogue className={cn.block_factory__varya} />
      </div>
      <div className={cn.block_tv}>
        <img src={tvImg} alt="Телевизор" />
        <div className={cn.block_tv__inner}>
          {!isMobile && <span className="title">Смотреть видео</span>}
          <div className={cn.block_tv__play}>
            <PlayIcon onClick={() => setIsModalVisible(true)} />
          </div>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        closeModal={closeModal}
        closeIconColor="#ffffff"
      >
        <div className={cn.block_video}>
          <Player ref={videoRef}>
            <BigPlayButton position="center" />
            <source src="https://children.prommoscow.info/video/2.mp4" />
          </Player>
        </div>
      </Modal>
    </div>
  );
};

export default Slide;
