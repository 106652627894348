import { ReactComponent as PlayIcon } from "assets/images/icons/play.svg";
import classNames from "classnames";
import Text from "components/Blocks/Text/Text";
import Modal from "components/Kits/Modals/Modal/Modal";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { BigPlayButton, Player } from "video-react";

import tvImg from "./img/tv.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = ({
  changeBackColor,
  changeCloudsColor,
  ...props
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false),
    videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    changeBackColor("#427DAF", "#427DAF");
    changeCloudsColor("#2B6698");

    return () => {
      changeBackColor();
      changeCloudsColor();
    };
  }, [changeBackColor, changeCloudsColor]);

  const closeModal = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pause();
    }

    setIsModalVisible(false);
  }, [videoRef, setIsModalVisible]);

  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <Text style={{ color: "#ffffff" }}>
        Промышленные приключения Вити и Роби
      </Text>
      <div className={cn.block_tv}>
        <img src={tvImg} className={cn.block_tv__img} alt="Телевизор" />
        <div className={cn.block_tv__inner}>
          <span className="title">Смотреть видео</span>
          <div className={cn.block_tv__play}>
            <PlayIcon onClick={() => setIsModalVisible(true)} />
          </div>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        closeModal={closeModal}
        closeIconColor="#ffffff"
      >
        <div className={cn.block_video}>
          <Player ref={videoRef}>
            <BigPlayButton position="center" />
            <source src="https://children.prommoscow.info/video/8.mp4" />
          </Player>
        </div>
      </Modal>
    </div>
  );
};

export default Slide;
