import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import orangesImg from "./img/oranges.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VityaMessage>Откуда вам привозят ингредиенты?</VityaMessage>
        <Message name="Парфюмер" image={specialistImg}>
          Из Франции. У нас в Крыму только начали возрождать сбор парфюмерных
          ингредиентов: лаванды, дамасской розы, шалфея. Но на это нужно время.
        </Message>
        <VaryaMessage>А как добыть из розы ее аромат?</VaryaMessage>
        <Message name="Парфюмер" image={specialistImg}>
          Нам нужно эфирное масло. Для килограмма масла нужно собрать пять тонн
          розовых лепестков. Причем собрать не за месяц или два, а за сутки. И в
          течение этих же суток доставить на производство. Лепестки все время
          бережно переворачивают, чтобы они слегка подсохли, но не потемнели.
          Затем лепестки отправляют в огромный дистиллятор и с помощью горячего
          пара получают эфирное масло.
        </Message>
        <VaryaMessage>
          Если все ингредиенты из Франции, то что делают у нас?
        </VaryaMessage>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#F55102" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          Восприятие запахов индивидуально, но есть одно вещество, которое
          пахнет для всех абсолютно одинаково. Это цитраль — искусственно
          синтезированное вещество с цитрусовым ароматом.
        </p>

        <img
          src={orangesImg}
          className={cn.block_bottom__oranges}
          alt="Апельсины"
        />
      </div>
    </div>
  );
};

export default Slide;
