import Intro from "games/Kits/Intro/Intro";
import { FC } from "react";

import faceImg from "./img/face.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div className={cn.slide}>
      <Intro
        factoryName="«Фора Роботикс»"
        title="Шоу двойников"
        intro="Глава, в которой Роби знакомится 
      со своими собратьями "
      >
        <img src={faceImg} className={cn.game__face} alt="Лицо робота" />
      </Intro>
    </div>
  );
};

export default Slide;
