import classNames from "classnames";
import { FC } from "react";

import cn from "./Navigator.module.scss";

type ownProps_type = {
  elementClick: (dir: 1 | -1, idx: number) => void;
  elementsCount: number;
  activeIdx: number;
  dotClass?: string;
};

const Navigator: FC<ownProps_type> = ({
  elementClick,
  elementsCount,
  activeIdx,
  dotClass,
}) => {
  const Dots = [];

  for (let i = 0; i < elementsCount; i++) {
    Dots.push(
      <span
        key={i}
        onClick={() => elementClick(1, i)}
        className={classNames(dotClass, cn.navigator__item, {
          [cn.navigator__item_active]: activeIdx === i,
        })}
      ></span>
    );
  }

  return <div className={cn.navigator}>{Dots}</div>;
};

export default Navigator;
