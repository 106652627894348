import Book from "components/Blocks/Book/Book";
import { FC } from "react";

import arrow from "./img/arrow.svg";
import bookImg from "./img/book.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div className={cn.slide}>
      <Book {...props} arrowImg={arrow} bookImg={bookImg} />
    </div>
  );
};

export default Slide;
