import classNames from "classnames";
import RobyGame from "games/Kits/Game/Game/Roby/Roby";
import { FC, useEffect, useState } from "react";
import { chapter_type } from "types";

import light from "../assets/img/light.png";
import cn from "./Game.module.scss";
import robyImg from "./img/roby.png";

const items = [
  {
    text: "Роботы могут работать на ледниках, в пустыне и под водой.",
    isError: false,
    isSelected: false,
    isExtra: false,
  },
  {
    text: "Роботы могут нанимать на работу.",
    isError: false,
    isSelected: false,
    isExtra: true,
  },
  {
    text: "Роботы могут работать без подключения к розетке.",
    isError: false,
    isSelected: false,
    isExtra: false,
  },
  {
    text: "Роботы могут копировать поведение насекомых, например, муравьев.",
    isError: false,
    isSelected: false,
    isExtra: false,
  },
  {
    text: "Мягкие роботы –это машины из мягких материалов, похожих на кожу человека.",
    isError: false,
    isSelected: false,
    isExtra: false,
  },
];
type ownProps_type = {
  nextChapter: chapter_type;
  onGameFinished: () => void;
  returnBack: () => void;
};

const Game: FC<ownProps_type> = ({
  nextChapter,
  onGameFinished,
  returnBack,
}) => {
  const [list, setList] = useState(items);
  const [gameState, setGameState] = useState<"static" | "failure" | "finish">(
    "static"
  );
  const [robyStateData] = useState({
    static:
      "Что ты знаешь о современных роботах? Найди одно ложное утверждение.",
    failure: "Подумай еще немного!",
    finish: "Отлично! Ты точно сможешь собрать своего робота!",
  });

  useEffect(() => {
    const index = list.findIndex((item) => item.isError);

    if (index !== -1) {
      setGameState((gameState) =>
        gameState === "finish" ? gameState : "failure"
      );

      setTimeout(() => {
        setList((list) => {
          const items = [...list],
            item = { ...items[index] };

          item.isError = false;
          item.isSelected = false;
          items[index] = item;

          return items;
        });
      }, 2000);

      setTimeout(
        () =>
          setGameState((gameState) =>
            gameState === "finish" ? gameState : "static"
          ),
        2000
      );
    }
  }, [list]);

  const handleClick = (index: number) => {
    if (list[index].isSelected) {
      return false;
    }

    setGameState((gameState) =>
      gameState === "finish" ? gameState : "static"
    );

    if (list[index].isExtra) {
      setGameState("finish");
      onGameFinished();
      return false;
    }

    setList((list) => {
      const items = [...list],
        item = { ...items[index] };

      item.isSelected = true;
      item.isError = !item.isExtra;
      items[index] = item;

      return items;
    });
  };

  return (
    <div className={cn.game}>
      <div className={cn.background}>
        <div className={cn.background__color}></div>
        <img className={cn.background__light_top} src={light} alt="Свет" />
        <img className={cn.background__light_bottom} src={light} alt="Свет" />
      </div>
      <div
        className={classNames(cn.items, {
          [cn.items_hidden]: gameState === "finish",
        })}
      >
        {list.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(cn.items__item, {
                [cn.items__item_error]: item.isError,
              })}
              onClick={() => {
                handleClick(index);
              }}
            >
              <div className={cn.items__item__check}></div>
              <div className={classNames(cn.items__item__text, "title")}>
                {item.text}
              </div>
            </div>
          );
        })}
      </div>
      <img
        src={robyImg}
        alt="Роби"
        className={classNames(cn.roby, {
          [cn.roby_hidden]: gameState === "finish",
        })}
      />

      {gameState === "finish" && (
        <div className={cn.finish}>
          <img src={robyImg} alt="Роби" />
          <p className="title">
            Робот может помочь в проведении собеседования, но решение
            обязательно принимает человек.
          </p>
        </div>
      )}

      <RobyGame
        gameState={gameState}
        stateData={robyStateData}
        nextGameLink={nextChapter.link}
        returnBack={returnBack}
      />
    </div>
  );
};

export default Game;
