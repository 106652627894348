import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyInfo, VityaWow } from "games/Kits/Characters";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import factoryImg from "./img/factory.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Message image={directorImg} name="Директор">
          Мы смешиваем говядину и свинину со специями, свежим молоком и яйцами.
          Для «Молочных» очень важен мускатный орех, он придает им характерную
          нотку, а также колотый лед – мы добавляем его, чтобы фарш не
          перегревался.
        </Message>
        <Text>
          Ребят впечатлили размеры космического вида аппарата для смешивания
          фарша. А Роби пришел в восторг от пятой скорости вращения – не хуже,
          чем у гоночного болида.
        </Text>
        <VityaMessage>А дальше?</VityaMessage>
        <Image src={factoryImg} alt="Фабрика" className={cn.image}>
          <RobyInfo className={cn.image__roby} />
        </Image>
        <Text>
          Сосиски «Молочные» от «Дымова» каждый год награждаются медалью
          «Гарантия качества», но мы и без этого знаем, что они очень вкусные.
        </Text>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8 " />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          Полный цикл изготовления сосисок – от подготовки сырья до упаковки –
          занимает 15 часов.
        </p>

        <VityaWow className={cn.block_bottom__vitya} />
      </div>
    </div>
  );
};

export default Slide;
