import { RobyStatic } from "games/Kits/Characters";

import cn from "./Roby.module.scss";

const RobyInvitation = () => {
  return (
    <div className={cn.roby}>
      <RobyStatic />
      <span className="title text-cloud">Поиграем?</span>
    </div>
  );
};

export default RobyInvitation;
