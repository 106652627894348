import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaDialogue } from "games/Kits/Characters";
import { FC } from "react";

import engineerImg from "../../assets/img/engineer.png";
import freezersImg from "./img/freezers.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>Ребята шли вдоль стены, составленной из холодильников.</Text>
        <Image src={freezersImg} alt="Холодильники" />
        <VityaMessage>А вот эти холодильники – их тоже в шредер?</VityaMessage>
        <Message name="Главный инженер" image={engineerImg}>
          Тоже. Но сначала надо откачать фреон и срезать компрессоры – вот эти
          ножницы режут металл, как бумагу.
        </Message>
        <RobyMessage>
          Фреон является одной из причин разрушения озонового слоя Земли. А озон
          защищает нашу планету от космических лучей.
        </RobyMessage>
        <Message name="Главный инженер" image={engineerImg}>
          Умница! Хотите посмотреть, как шредер справляется с холодильником? У
          нас там видеокамера.
        </Message>
        <VaryaMessage>Как быстро он его пережевывает!</VaryaMessage>
        <Message name="Главный инженер" image={engineerImg}>
          Да, его производительность – один холодильник в минуту.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8 " />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          Завод «Экотехпром» может перерабатывать 75 000 тонн электрохлама в год
          – это, например 1 миллион холодильников.
        </p>
        <VaryaDialogue className={cn.block_bottom__varya} />
      </div>
    </div>
  );
};

export default Slide;
