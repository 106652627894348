import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import greenhouseImg from "./img/greenhouse.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          Большая красная машина смешивала торфяную смесь, поливала ее,
          аккуратно набивала ею горшочки, делала в центре углубление и высевала
          салат – ровно по одному семечку в стаканчик. Затем стаканы уходили на
          прорастание в специальную темную теплицу.
        </Text>
        <Message image={directorImg} name="Директор">
          За рабочую смену мы здесь высеваем семена в 1200 горшков.
        </Message>
        <VaryaMessage>Так много?!</VaryaMessage>
        <Message image={directorImg} name="Директор">
          Это не так уж трудно: все автоматизировано, операторы лишь
          контролируют процесс и засыпают семена в сеялку.
        </Message>
        <RobyMessage>А где же кейл?</RobyMessage>
        <Message image={directorImg} name="Директор">
          Скоро мы до нее дойдем.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#126624" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          ЭТО ИНТЕРЕСНО!
        </p>
        <p>
          15 гектаров теплиц в «Московском» полностью автоматизированы. В цеху
          работают всего 2 человека, которые контролируют качество товара и
          занимаются упаковкой.
        </p>
        <img
          src={greenhouseImg}
          alt="Теплица"
          className={cn.block_bottom__greenhouse}
        />
      </div>
    </div>
  );
};

export default Slide;
