import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VityaWow } from "games/Kits/Characters";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Message name="PR-директор" image={specialistImg}>
          Теперь мы пойдем на третье предприятие – завод «Москабель». Здесь нашу
          жилу покроют изоляцией. Посмотрите, станок оплетает ее бумажной
          лентой, точно паук паутину плетет. А затем вот эта большая машина
          скрутит несколько жил в единую конструкцию.
        </Message>
        <VaryaMessage>А почему эти жилы разноцветные?</VaryaMessage>
        <Message name="PR-директор" image={specialistImg}>
          Чтобы монтажнику было легче определить, что куда подключать. Но это
          еще не все. Теперь кабель покроют еще одним слоем бумаги и искупают в
          ванне со специальным раствором.
        </Message>
        <VityaMessage>
          Но ведь бумага – вещь непрочная. Что, если кабель попробует на зуб
          какой-нибудь грызун?
        </VityaMessage>
        <Message name="PR-директор" image={specialistImg}>
          Наш кабель им не по зубам. – Поверх бумаги мы оденем его в оболочку из
          свинца или алюминия, а потом добавим наружные покровы. Некоторые
          кабели мы заковываем в стальную броню, ведь на атомных электростанциях
          и в метро кабель должен служить без замены до полувека. Еще используем
          негорючие полимеры – при пожаре наши кабели могут продолжать работу до
          трех часов. За это время машинист точно успеет привести поезд метро на
          станцию и эвакуировать людей.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          Ежегодно «Москабельмет» производит до 40 000 километров кабелей –
          этого количества хватит, чтобы обернуть Землю по экватору.
        </p>
        <VityaWow className={cn.block_bottom__vitya} />
      </div>
    </div>
  );
};

export default Slide;
