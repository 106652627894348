import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import RobyInvitation from "games/Kits/Game/Invitation/Roby/Roby";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import greenhouseImg from "./img/greenhouse.png";
import imageSrc from "./img/image.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          Андрей провел ребят в следующую теплицу. Компания оказалась на
          огромном цветочном поле, эта картина поразила даже Роби.
        </Text>
        <Image src={greenhouseImg} alt="Теплица" />

        <RobyMessage>
          Могу ли я взять один горшок с собой? Очень хочется порадовать мою
          подругу Viki-Viki.
        </RobyMessage>
        <Message image={directorImg} name="Директор">
          Конечно, Роби. Но сначала для тебя есть задача. Справишься с посадкой
          бегонии?
        </Message>
        <RobyMessage>Попробую.</RobyMessage>
        <Text>
          Получилось у Роби замечательно: он брал с ленты конвейера горшок,
          делал в грунте отверстие и по мещал в него крохотный саженец, а в этот
          момент к нему уже подъезжал очередной горшок.
        </Text>
        <Message image={directorImg} name="Директор">
          Роби, ты можешь к нам приезжать по выходным и помогать.
        </Message>
        <Image src={imageSrc} alt="Друзья" />
      </WhiteBlock>
      <Text className={cn.text}>
        На прощание Андрей угостил Варю и Витю цветами. Варе особенно
        понравились кисленькая кислица и анютины глазки, а василек она воткнула
        себе в волосы.
      </Text>
      <RobyInvitation />
    </div>
  );
};

export default Slide;
