import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VityaWow } from "games/Kits/Characters";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import factoryImg from "./img/factory.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          Пока Полина передвигала стеклянные флаконы в шкафу, ребята обратили
          внимание на ее рабочий кабинет – просторный и светлый, с лабораторными
          весами, множеством мензурок и холодильником у стены.
        </Text>
        <Message name="Парфюмер" image={specialistImg}>
          Запахи непостоянны. Например, нота арбуза в сочетании с некоторыми
          другими запахами даст аромат моря; один из моих любимых ингредиентов
          пахнет персиком, но если с ним переборщить, то смесь начнет благоухать
          солеными огурцами; есть вещества, которые создают аромат свежести,
          белого льна. Часто я выбираю из нескольких сотен ингредиентов один
          единственный – тот, который создаст волшебство, которое парфюмеры
          называют балансом
        </Message>
        <Text>Полина закрыла шкафчик и вернулась к ребятам.</Text>
        <Message name="Парфюмер" image={specialistImg}>
          Затем, когда мы определились с формулой, начинается работа со
          спиртовыми растворами. Их концентрация невысока – от 1 до 10%. Потому
          что с чистыми веществами работать невозможно. На этом этапе в аромате
          можно что-то подправить, сделать какие-то акценты. И потом уже можно
          заказывать ингредиенты.
        </Message>
        <Image src={factoryImg} alt="Производство" className={cn.image}></Image>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>Профессиональный парфюмер может распознать более 300 ароматов.</p>

        <VityaWow className={cn.block_bottom__vitya} />
      </div>
    </div>
  );
};

export default Slide;
