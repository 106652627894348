import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import imageSrc from "./img/image.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VityaMessage>
          Роби, что ты там поливаешь? Салат? Не подозревал, что ты занимаешься
          садоводством.
        </VityaMessage>
        <RobyMessage>
          А ты разве не знал, что лучше всех с выращиванием зелени справляются
          именно роботы? Это уже давно доказали в агрохолдинге «Московский».
          Кстати, там делают чипсы из самой полезной капусты – кейла. Кто-нибудь
          пробовал?
        </RobyMessage>
        <VaryaMessage>
          Какой же ты фантазер! Посмотри лучше, какие красивые марки с овощами я
          нашла в альбоме.
        </VaryaMessage>
        <VityaMessage>
          Что толку на овощи смотреть? Их надо есть. Роби, сможешь устроить нам
          экскурсию в агрохолдинг «Московский»? Заодно твои капустные чипсы
          попробуем, а то Варя не верит в их существование.
        </VityaMessage>
        <RobyMessage>Едем! Сами увидите.</RobyMessage>
        <Image src={imageSrc} alt="Друзья" />
        <Text>И любознательные друзья отправились в Новую Москву.</Text>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
