import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import VaryaInvitation from "games/Kits/Game/Invitation/Varya/Varya";
import { FC } from "react";

import constructorImg from "../../assets/img/constructor.png";
import planetImg from "./img/planet.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VaryaMessage>Это же, наверное, ужасно долго.</VaryaMessage>
        <Message name="Конструктор" image={constructorImg}>
          Как посмотреть. Мы можем построить ракету за 18 месяцев – по-моему, не
          так уж и долго. Обычные жилые дома часто строятся дольше, а ведь им не
          надо на столбе огня подниматься на орбиту.
        </Message>
        <VaryaMessage>А до Луны на вашей «Ангаре» можно долететь?</VaryaMessage>
        <Message name="Конструктор" image={constructorImg}>
          Думаю, скоро будет можно. Мы ведем работу над модификацией, которая за
          два старта сможет долететь до Луны.
        </Message>
        <VaryaMessage>Как это «за два старта»?</VaryaMessage>
        <Message name="Конструктор" image={constructorImg}>
          Сначала на МКС, а потом на Луну. Мы понимаем, что без освоения Луны
          никакие исследования дальнего космоса невозможны. А ведь мы туда
          хотим, правда, ребята?
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#4E7F60" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          Сейчас по соседству с цехами Центра имени Хруничева строится
          Национальный космический центр. В 47-этажной башне, напоминающей
          ракету на стартовой площадке, разместится центр управления полетами
          Роскосмоса.
        </p>
        <img src={planetImg} alt="Планета" className={cn.block_bottom__image} />
      </div>
      <VaryaInvitation />
    </div>
  );
};

export default Slide;
