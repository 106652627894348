import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import fabricImg from "./img/fabric.png";
import pizzaImg from "./img/pizza.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          На производстве компанию первым делом одели в уже привычные защитные
          халаты, шапочки и бахилы, а затем провели подробный инструктаж по
          технике безопасности. Сопровождал ребят высокий молодой человек –
          исполнительный директор производства. Экскурсию начали с самого
          важного, а самым важным в колбасе является вкусное мясо. В холодильной
          камере ребята увидели длинный ряд свиных полутуш. Вот бы привести сюда
          тех, кто уверяет, что колбаса – это сплошная химия.
        </Text>
        <Image src={fabricImg} alt="Фабрика" className={cn.image} />
        <Message image={directorImg} name="Директор">
          Мы ежегодно строго проверяем всех наших поставщиков. Кроме того, мы
          построили собственные фермы во Владимирской и Ивановской областях, в
          Красноярске и на Кубани. На колбасу, сосиски и сардельки идут только
          лучшие части туши: шейка, карбонат, лопатка, окорок и грудинка. Все
          мясо мы проверяем в микробиологической лаборатории – у нас их целых
          две. Если что-то не так, возвращаем поставщикам.
        </Message>
        <Text>
          В 2021 году комбинат «Дымов» выпустил столько бекона, что ломтиками
          можно было застелить площадь всей нашей страны.
        </Text>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#A84147" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          ЭТО ИНТЕРЕСНО!
        </p>
        <p>
          Каждая 3-я пицца в Москве приготовлена с колбасой пепперони от фабрики
          «Дымов».
        </p>
        <img src={pizzaImg} alt="Пицца" className={cn.block_bottom__pizza} />
      </div>
    </div>
  );
};

export default Slide;
