import classNames from "classnames";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { AppContext } from "context/app-context";
import { FC, useContext } from "react";

import image from "./img/image.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  const { isMobile } = useContext(AppContext);

  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.whiteBlock}>
        <Text>Глава, в которой все начинается</Text>
        {!isMobile && <Image src={image} alt="Друзья" className={cn.image} />}
        <VaryaMessage>Витя, подожди! Ты же обещал!</VaryaMessage>
        <VityaMessage>Что обещал?</VityaMessage>
        <Text className={cn.text}>
          Витя, который уже стоял в дверях, обернулся. Рядом задумчиво
          перекатывался на своем колесе робот Роби.
        </Text>
        <VaryaMessage>
          Что возьмешь меня с собой на работу! Вы все время исчезаете на целый
          день, у вас там, в лаборатории, наверняка интересно. Ты даже Роби с
          собой берешь!
        </VaryaMessage>
        <VityaMessage>
          Было бы странно мне его туда не брать, он ведь там родился.
        </VityaMessage>
        <VaryaMessage>
          Но ведь это, можно сказать, наша семейная лаборатория. Там еще
          прадедушка работал, когда придумывал первые реактивные двигатели. Ты
          же сам говорил, что там уже можно табличку с нашей фамилией на дверь
          повесить. Так вот, он не только твой, но и мой прадедушка. Сегодня я с
          вами!
        </VaryaMessage>
        <VityaMessage>
          Ладно-ладно, сдаюсь! Но уговор: ничего не трогать!
        </VityaMessage>
        {isMobile && <Image src={image} alt="Друзья" className={cn.image} />}
      </WhiteBlock>
    </div>
  );
};

export default Slide;
