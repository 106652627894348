import classNames from "classnames";
import VaryaGame from "games/Kits/Game/Game/Varya/Varya";
import { FC, useEffect, useMemo, useState } from "react";
import { chapter_type } from "types";

import light from "../assets/img/light.png";
import cn from "./Game.module.scss";
import booksImg from "./img/books.png";
import cakeImg from "./img/cake.png";
import explanationImg from "./img/explanation.png";
import flowersImg from "./img/flowers.png";
import garlicImg from "./img/garlic.png";
import photoImg from "./img/photo.png";
import toyImg from "./img/toy.png";
import tubeImg from "./img/tube.png";

const items = [
  {
    img: booksImg,
    title: "Книги",
    isError: false,
    isSelected: false,
    isCorrect: false,
  },
  {
    img: garlicImg,
    title: "Чеснок",
    isError: false,
    isSelected: false,
    isCorrect: true,
  },
  {
    img: toyImg,
    title: "Плюшевый мишка",
    isError: false,
    isSelected: false,
    isCorrect: false,
  },
  {
    img: cakeImg,
    title: "Пирожное с кремом",
    isError: false,
    isSelected: false,
    isCorrect: true,
  },
  {
    img: flowersImg,
    title: "Букет цветов",
    isError: false,
    isSelected: false,
    isCorrect: true,
  },
  {
    img: photoImg,
    title: "Рамка с фото",
    isError: false,
    isSelected: false,
    isCorrect: false,
  },
  {
    img: tubeImg,
    title: "Космическое питание",
    isError: false,
    isSelected: false,
    isCorrect: false,
  },
];

type ownProps_type = {
  nextChapter: chapter_type;
  onGameFinished: () => void;
  returnBack: () => void;
};

const Game: FC<ownProps_type> = ({
  nextChapter,
  onGameFinished,
  returnBack,
}) => {
  const [list, setList] = useState(items);
  const [gameState, setGameState] = useState<"static" | "failure" | "finish">(
    "static"
  );
  const [varyaStateData] = useState({
    static:
      "Помоги нам собрать посылку на орбиту. Выбери предметы, которые нельзя отправить в космос.",
    failure: "Чуть-чуть не получилось! Попробуй еще раз!",
    finish:
      "Букет цветов, пирожное и чеснок явно не вынесут перегрузок при старте. А вот космическое питание, книги, фотографии и даже игрушки вполне могут попасть на орбиту. Молодец, берем тебя в космонавты!",
  });

  const gameFinished = useMemo(() => {
    return !list.filter((item) => !item.isSelected && item.isCorrect).length;
  }, [list]);

  useEffect(() => {
    if (gameFinished) {
      setGameState("finish");
      onGameFinished();
    }
  }, [gameFinished, onGameFinished]);

  useEffect(() => {
    if (!list.filter((item) => item.isCorrect && !item.isSelected).length) {
      setGameState("finish");
    }
  }, [list]);

  useEffect(() => {
    const index = list.findIndex((item) => item.isError);

    if (index !== -1) {
      setTimeout(() => {
        setList((list) => {
          const items = [...list],
            item = { ...items[index] };

          item.isError = false;
          item.isSelected = false;
          items[index] = item;

          return items;
        });
      }, 1000);
    }
  }, [list]);

  const handleClick = (index: number) => {
    if (list[index].isSelected) {
      return false;
    }

    setGameState((gameState) =>
      gameState === "finish" ? gameState : "static"
    );

    setList((list) => {
      const items = [...list],
        item = { ...items[index] };

      item.isSelected = true;
      item.isError = !item.isCorrect;
      items[index] = item;

      if (item.isError) {
        setGameState((gameState) =>
          gameState === "finish" ? gameState : "failure"
        );

        setTimeout(
          () =>
            setGameState((gameState) =>
              gameState === "finish" ? gameState : "static"
            ),
          2000
        );
      }

      return items;
    });
  };

  return (
    <div className={cn.game}>
      <div className={cn.background}>
        <div className={cn.background__color}></div>
        <img className={cn.background__light_top} src={light} alt="Свет" />
        <img className={cn.background__light_bottom} src={light} alt="Свет" />
      </div>
      <div
        className={classNames(cn.cards, {
          [cn.cards_hidden]: gameState === "finish",
        })}
      >
        {list.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(cn.cards__item, {
                [cn.cards__item_correct]: item.isCorrect && item.isSelected,
                [cn.cards__item_error]: item.isError && item.isSelected,
              })}
            >
              <div
                className={cn.cards__item__image}
                onClick={() => {
                  handleClick(index);
                }}
              >
                <p className={classNames("title", cn.cards__item__title)}>
                  {item.title}
                </p>
                <img src={item.img} alt={item.title} draggable={false} />
              </div>
            </div>
          );
        })}
        <div className={cn.cards__last}>
          <img src={explanationImg} alt="Пояснение" />
          <p>Выбери лишние карточки нажатием</p>
        </div>
      </div>

      {gameState === "finish" && (
        <div className={classNames(cn.cards, cn.cards_finish)}>
          {list
            .filter((item) => item.isCorrect)
            .map((item, index) => {
              return (
                <div
                  key={index}
                  className={classNames(cn.cards__item, cn.cards__item_correct)}
                >
                  <div className={cn.cards__item__image}>
                    <p className="title">{item.title}</p>
                    <img src={item.img} alt={item.title} draggable={false} />
                  </div>
                </div>
              );
            })}
        </div>
      )}

      <VaryaGame
        gameState={gameState}
        stateData={varyaStateData}
        nextGameLink={nextChapter.link}
        returnBack={returnBack}
      />
    </div>
  );
};

export default Game;
