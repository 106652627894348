import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import imageSrc from "./img/image.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>В лабораторию вбежала запыхавшаяся Варя.</Text>
        <VaryaMessage>
          Витя, помоги! Я вела поезд – ну в игре, понимаешь – и вдруг все
          зависло. А поезд, между прочим, люди ждут.
        </VaryaMessage>
        <RobyMessage>
          Во всем здании отключился Wi-Fi. Идут работы на линии
          волоконно-оптического кабеля.
        </RobyMessage>
        <VaryaMessage>
          Витя, тебе надо над Роби еще поработать! Ведь Wi-Fi значит
          «беспроводной». Зачем ему кабель? Да и что вообще может случиться с
          кабелем? Это ведь просто провод.
        </VaryaMessage>
        <Image src={imageSrc} alt="Варя" />
        <VityaMessage>
          Ты ошибаешься. Вот смотри… Видишь марку с Останкинской башней?
        </VityaMessage>
        <VaryaMessage>А она-то здесь при чем?</VaryaMessage>
        <VityaMessage>
          Без кабеля не было бы ни телевидения, ни интернета, ни метро. Кабель –
          это вовсе не трубка с проводником внутри.
        </VityaMessage>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
