import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import technologistImg from "../../assets/img/technologist.png";
import factoryImg from "./img/factory.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <Text className={cn.text}>
        В цеху ребят окружили огромные блестящие резервуары, без устали бежали
        конвейерные ленты, неся упаковки «Агуши». А еще повсюду сверкали, словно
        тульские самовары, надраенные на выставку, трубы, трубы, трубы…
      </Text>
      <WhiteBlock className={cn.white}>
        <Message name="Технолог" image={technologistImg}>
          Между прочим, творожок «Агуша» – один из самых популярных детских
          молочных продуктов. Творог для него мы сначала пропускаем через очень
          тонкие фильтры, а затем добавляем злаки, ягоды и фрукты. А вот эта
          машина запечатывает стаканчик с творожком и укладывает в поддоны для
          отправки на склад.
        </Message>
        <Text>
          Технолог показал на автоматическую линию и подхватил с конвейера
          «Агушу» со вкусом яблока и банана.
        </Text>
        <VityaMessage>Варя, попробуешь?</VityaMessage>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#1D99C0" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Детские творожки выпускаю на трех производственных линиях. Две делают
          по 20 000 упаковок в час, а третья – 50 000 упаковок.
        </p>
        <Image
          src={factoryImg}
          alt="Фабрика"
          className={cn.block_bottom__image}
        />
      </div>
    </div>
  );
};

export default Slide;
