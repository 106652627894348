import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyInfo } from "games/Kits/Characters";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import factoryImg from "./img/factory.png";
import wiresImg from "./img/wires.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>Ребята спустились в цех.</Text>
        <VaryaMessage>Жарко!</VaryaMessage>

        <Message name="PR-директор" image={specialistImg}>
          Поэтому здесь у нас везде расставлены автоматы с газировкой. Вот в эту
          печь загружают медные пластины, каждая по 50 килограммов.
        </Message>

        <VaryaMessage>
          Как красиво! Похоже на северное сияние. А почему пламя зеленое?
        </VaryaMessage>
        <RobyMessage>
          Медь окрашивает пламя в зеленый, а при самых высоких температурах – в
          синий цвет.
        </RobyMessage>
        <Message name="PR-директор" image={specialistImg}>
          К печам можно подходить только в огнеупорных шлемах и халатах. Сейчас
          как раз расплавленную медь из миксера переливают в желоб прокатного
          стана, который превратит ее в раскаленную полосу. Затем полоса будет
          постепенно сужаться, остывать и превратится в круглую катанку
          диаметром от 8 до 24 миллиметров.
        </Message>
        <Image className={cn.image} src={factoryImg} alt="Фабрика">
          <RobyInfo className={cn.image__roby} />
        </Image>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          За время своего существования завод «Элкат» выпустил 2 500 000 тонн
          медной катанки, то есть почти по 20 тонн в сутки.
        </p>
        <img src={wiresImg} className={cn.block_bottom__wires} alt="Провода" />
      </div>
    </div>
  );
};

export default Slide;
