import { ThunkAction } from "redux-thunk";
import { AppStateType, InferActionsTypes } from "redux/store";
import { LocalKey, LocalStorage } from "ts-localstorage";
import { chapter_type, router_type } from "types";

const chaptersKey = new LocalKey<Array<{ id: number; isCompleted: boolean }>>(
  "chapters",
  []
);

const initialState = {
  isInitialized: false,
  chapters: [] as Array<chapter_type>,
};

type initialState_type = typeof initialState;

const appReducer = (
  state = initialState,
  action: AppActions_types
): initialState_type => {
  switch (action.type) {
    case "INITIALIZED_SUCCESS":
      return {
        ...state,
        isInitialized: true,
      };

    case "SET_CHAPTERS":
      return {
        ...state,
        chapters: action.chapters,
      };

    case "SET_CHAPTER_COMPLETED": {
      const newChapters = state.chapters.slice(),
        oldChapterIndex = newChapters.findIndex(
          (chapter) => chapter.id === action.id
        ),
        oldChapter = newChapters[oldChapterIndex],
        newChapter = Object.assign({}, oldChapter);

      newChapter.isCompleted = true;
      newChapters[oldChapterIndex] = newChapter;

      return {
        ...state,
        chapters: newChapters,
      };
    }

    case "SET_CHAPTER_ACTIVE": {
      const newChapters = state.chapters.slice(),
        oldChapterIndex = newChapters.findIndex(
          (chapter) => chapter.id === action.id
        ),
        oldChapter = newChapters[oldChapterIndex],
        oldActiveChapterIndex = newChapters.findIndex(
          (chapter) => chapter.isActive
        ),
        newChapter = Object.assign({}, oldChapter);

      if (oldActiveChapterIndex !== -1) {
        const oldActiveChapter = newChapters[oldActiveChapterIndex],
          oldNonActiveChapter = Object.assign({}, oldActiveChapter);

        oldNonActiveChapter.isActive = false;
        newChapters[oldActiveChapterIndex] = oldNonActiveChapter;
      }

      newChapter.isActive = true;
      newChapters[oldChapterIndex] = newChapter;

      return {
        ...state,
        chapters: newChapters,
      };
    }

    case "SET_STORAGE_CHAPTERS": {
      const newChapters = state.chapters.slice();

      action.chapters.forEach((chapter) => {
        const newChapterId = newChapters.findIndex(
          (newChapter) => newChapter.id === chapter.id
        );

        newChapters[newChapterId] = {
          ...newChapters[newChapterId],
          ...chapter,
        };
      });

      return {
        ...state,
        chapters: newChapters,
      };
    }

    default:
      return state;
  }
};

export type AppActions_types = InferActionsTypes<typeof appActions>;

export const appActions = {
  initializedSuccess_ac: () =>
    ({
      type: "INITIALIZED_SUCCESS",
    } as const),
  setChapters_ac: (chapters: Array<chapter_type>) =>
    ({
      type: "SET_CHAPTERS",
      chapters,
    } as const),
  setChapterActive_ac: (id: number) =>
    ({
      type: "SET_CHAPTER_ACTIVE",
      id,
    } as const),
  setChapterCompleted_ac: (id: number) =>
    ({
      type: "SET_CHAPTER_COMPLETED",
      id,
    } as const),
  setStorageChapters_ac: (
    chapters: Array<{ id: number; isCompleted: boolean }>
  ) =>
    ({
      type: "SET_STORAGE_CHAPTERS",
      chapters,
    } as const),
};

type ThunkType = ThunkAction<void, AppStateType, unknown, AppActions_types>;

export const initializeApp_tc =
  (router: Array<router_type>): ThunkType =>
  async (dispatch, getState) => {
    dispatch(
      appActions.setChapters_ac(
        router.map((chapter) => {
          const { component, ...newChapter } = chapter;

          return { ...newChapter, ...{ isActive: false, isCompleted: false } };
        })
      )
    );

    const localStorageChapters = LocalStorage.getItem(chaptersKey);

    if (!localStorageChapters) {
      LocalStorage.setItem(
        chaptersKey,
        getState().app.chapters.map((chapter) => ({
          id: chapter.id,
          isCompleted: chapter.isCompleted,
        }))
      );
    } else {
      dispatch(appActions.setStorageChapters_ac(localStorageChapters));
    }

    dispatch(appActions.initializedSuccess_ac());
  };

export const setChapterActive_tc =
  (id: number): ThunkType =>
  async (dispatch) => {
    dispatch(appActions.setChapterActive_ac(id));
  };

export const setChapterCompleted_tc =
  (id: number): ThunkType =>
  async (dispatch) => {
    const localStorageChapters = LocalStorage.getItem(chaptersKey);

    if (localStorageChapters) {
      const completedChapterIndex = localStorageChapters.findIndex(
          (chapter) => chapter.id === id
        ),
        newChapters = localStorageChapters.slice();

      newChapters[completedChapterIndex].isCompleted = true;

      LocalStorage.setItem(chaptersKey, newChapters);
    }

    dispatch(appActions.setChapterCompleted_ac(id));
  };

export default appReducer;
