import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";
import classNames from "classnames";
import VaryaGame from "games/Kits/Game/Game/Varya/Varya";
import { FC, useEffect, useMemo, useState } from "react";
import { chapter_type } from "types";

import light from "../assets/img/light.png";
import cn from "./Game.module.scss";
import catImg from "./img/cat.png";
import explanationImg from "./img/explanation.png";
import image_1 from "./img/image_01.jpg";
import image_2 from "./img/image_02.jpg";
import image_3 from "./img/image_03.jpg";
import image_4 from "./img/image_04.jpg";
import image_5 from "./img/image_05.jpg";

const items = [
  {
    img: image_1,
    tooltip: "Состав сока",
    isError: false,
    isCorrect: false,
    isSelected: false,
  },
  {
    img: image_2,
    tooltip: "Название кафе через дорогу",
    isError: false,
    isCorrect: true,
    isSelected: false,
  },
  {
    img: image_3,
    tooltip: "Номер подъезжающего автобуса",
    isError: false,
    isCorrect: true,
    isSelected: false,
  },
  {
    img: image_4,
    tooltip: "Что написано на доске",
    isError: false,
    isCorrect: true,
    isSelected: false,
  },
  {
    img: image_5,
    tooltip: "Кто звонит",
    isError: false,
    isCorrect: false,
    isSelected: false,
  },
];

type ownProps_type = {
  nextChapter: chapter_type;
  onGameFinished: () => void;
  returnBack: () => void;
};

const Game: FC<ownProps_type> = ({
  nextChapter,
  onGameFinished,
  returnBack,
}) => {
  const [list, setList] = useState(items);
  const [gameState, setGameState] = useState<
    "start" | "static" | "failure" | "finish"
  >("start");
  const [varyaStateData] = useState({
    start:
      "Наш кот Барсик много читал с фонариком под одеялом, и теперь у него плохое зрение: -7. Пока Барсик ходит без очков, он многое не видит. Угадай, что именно не видит Барсик? Вариантов может быть несколько.",
    static:
      "Угадай, что именно не видит Барсик? Вариантов может быть несколько.",
    failure: "Ты почти угадал! Попробуй еще раз! ",
    finish:
      "Совершенно верно! «Минусовое» зрение означает близорукость. То есть Барсик плохо видит предметы, которые находятся вдали. ",
  });

  const gameFinished = useMemo(() => {
    return !list.filter((item) => !item.isSelected && item.isCorrect).length;
  }, [list]);

  useEffect(() => {
    const index = list.findIndex((item) => item.isError);

    if (index !== -1) {
      setGameState((gameState) =>
        gameState === "finish" ? gameState : "failure"
      );

      setTimeout(() => {
        setList((list) => {
          const items = [...list],
            item = { ...items[index] };

          item.isError = false;
          item.isSelected = false;
          items[index] = item;

          return items;
        });
      }, 1000);

      setTimeout(
        () =>
          setGameState((gameState) =>
            gameState === "finish" ? gameState : "static"
          ),
        2000
      );
    }
  }, [list]);

  useEffect(() => {
    if (gameFinished) {
      setGameState("finish");
      onGameFinished();
    }
  }, [gameFinished, onGameFinished]);

  const handleClick = (index: number) => {
    if (list[index].isSelected) {
      return false;
    }

    setGameState((gameState) =>
      gameState === "finish" ? gameState : "static"
    );

    setList((list) => {
      const items = [...list],
        item = { ...items[index] };

      item.isSelected = true;
      item.isError = !item.isCorrect;
      items[index] = item;

      return items;
    });
  };

  return (
    <div className={cn.game}>
      <div className={cn.background}>
        <div className={cn.background__color}></div>
        <img className={cn.background__light_top} src={light} alt="Свет" />
        <img className={cn.background__light_bottom} src={light} alt="Свет" />
      </div>
      <div
        className={classNames(cn.cards, {
          [cn.cards_hidden]: gameState === "finish",
        })}
      >
        {list.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(cn.cards__item, {
                [cn.cards__item_error]: item.isError,
                [cn.cards__item_correct]: item.isCorrect && item.isSelected,
              })}
            >
              <div className={cn.cards__item__tooltip}>
                <InfoIcon />
                <span>{item.tooltip}</span>
              </div>
              <div
                className={cn.cards__item__image}
                onClick={() => {
                  handleClick(index);
                }}
              >
                <img src={item.img} alt="Изображение" />
              </div>
            </div>
          );
        })}
      </div>
      <img
        src={catImg}
        className={classNames(cn.cat, {
          [cn.cat_hidden]: gameState === "finish",
        })}
        alt="Кот"
      />
      <div
        className={classNames(cn.game__explanation, {
          [cn.cards_hidden]: gameState === "finish",
        })}
      >
        <img src={explanationImg} alt="Пояснение" />
        <p>Выбирай нужные карточки нажатием</p>
      </div>
      {gameState === "finish" && (
        <div
          className={classNames(cn.finish, {
            [cn.finish_visible]: gameState === "finish",
          })}
        >
          <div className={cn.finish__cat}>
            <img src={catImg} alt="Кот" />
          </div>
          <div className={classNames(cn.finish__cards)}>
            {list
              .filter((item) => item.isCorrect)
              .map((item, index) => {
                return (
                  <div
                    key={index}
                    className={classNames(
                      cn.cards__item,
                      cn.finish__cards__item
                    )}
                  >
                    <div
                      className={classNames(
                        cn.cards__item__image,
                        cn.finish__cards__item__image
                      )}
                    >
                      <img src={item.img} alt="Изображение" />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}

      <VaryaGame
        gameState={gameState}
        stateData={varyaStateData}
        nextGameLink={nextChapter.link}
        returnBack={returnBack}
      />
    </div>
  );
};

export default Game;
