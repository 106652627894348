import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import { RobyStatic } from "../../../Kits/Characters/index";
import iceCream from "../../assets/img/ice-cream.png";
import iceCreams from "./img/ice-creams.png";
import imageSrc from "./img/image.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(cn.slide, props.className)}>
      <WhiteBlock className={cn.white}>
        <img src={iceCream} alt="Мороженое" className={cn.white__iceCream} />
        <Image src={imageSrc} alt="Парк аттракционов" className={cn.image} />
        <VaryaMessage>
          Интересно, почему в прадедушкином альбоме рядом расположены три не
          связанные между собой марки… Вот, смотри: балерина, цирк и мороженое.
        </VaryaMessage>
        <RobyMessage>
          Ничего странного. Ведь он жил во времена СССР, а тогда иностранцы
          приезжали к нам, чтобы увидеть русский балет, сходить в цирк на клоуна
          Юрия Никулина и попробовать мороженое. А знаете, сколько мороженого
          выпускает в год Московская фабрика мороженого? 16 тысяч тонн! Так вот,
          я подсчитал: на каждого москвича приходится больше килограмма.
        </RobyMessage>
        <VaryaMessage>
          Подумаешь, килограмм! Я столько за день могу съесть. Кстати, Роби, а
          почему мы до сих пор туда не съездили? Одними «Научными развлечениями»
          сыт не будешь!
        </VaryaMessage>
        <VityaMessage>
          У нас осталась еще одна экскурсия — и книжка закончится. Предлагаю
          посвятить ее мороженому, будет отличный десерт.
        </VityaMessage>
        <Text>
          И ребята отправились в Останкино, где их встретила технолог Светлана.
        </Text>
      </WhiteBlock>
      <Clips topColor="#D2D2D2" bottomColor="#18446C" doubled />
      <div className={classNames("block", cn.interesting)}>
        <RobyStatic className={cn.interesting__image} />
        <p
          style={{ fontSize: 48, lineHeight: "61px", marginBottom: 30 }}
          className="title"
        >
          Это Интересно
        </p>
        <p className={cn.interesting__text}>
          В одной смене на фабрике работают всего 25 человек — этого достаточно,
          чтобы управлять оборудованием и производить за час 4300 эскимо и 3000
          вафельных стаканчиков.
        </p>
        <img
          src={iceCreams}
          alt="Мороженое"
          className={cn.interesting__iceCreams}
        />
      </div>
    </div>
  );
};

export default Slide;
