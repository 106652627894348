import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyInfo } from "games/Kits/Characters";
import { FC } from "react";

import doctorImg from "../../assets/img/doctor.png";
import factoryImg from "./img/factory.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Message name="Главный инженер" image={doctorImg}>
          Кстати, видите эти штуки, похожие на пылесосы? Они аккуратно собирают
          всю пыль и стружку, которая образуется при обточке. Все это вместе с
          другими пластиковыми отходами отправляется на переработку и потом
          может превратиться в скамейки или покрытие для детских площадок.
        </Message>
        <VaryaMessage>Как? Разве линзы в очках не стеклянные?</VaryaMessage>
        <Message name="Главный инженер" image={doctorImg}>
          В мире уже почти не производят очков со стеклянными линзами –
          пластиковые прочнее и легче. И даже переработать их проще, чем стекло.
          Но и пластик бывает разный. Мы часто предлагаем гостям провести
          небольшой эксперимент. Вот, Варя, держи.
        </Message>
        <Text>
          Инженер достал невесть откуда молоток и протянул девочке. Варя
          испуганно попятилась.
        </Text>
        <VaryaMessage>Нет, пусть лучше Витя.</VaryaMessage>
        <Message name="Главный инженер" image={doctorImg}>
          Хорошо, здесь и правда требуется сила. Вот две линзы – из обычного
          пластика и та, которую мы используем. Попробуйте их разбить.
        </Message>
        <Image src={factoryImg} alt="Фабрика">
          <RobyInfo className={cn.factory_roby} />
        </Image>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
