import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaDialogue } from "games/Kits/Characters/index";
import { FC } from "react";

import engineerImg from "../../assets/img/engineer.png";
import carsImg from "./img/cars.png";
import factoryImg from "./img/factory.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          Завод в Капотне оказался огромным. Все 2300 сотрудников предприятия
          перемещаются по нему на автобусах. Пешком здесь далеко не уйдешь –
          МНПЗ раскинулся на площади 286 гектаров.
        </Text>
        <Text>
          Ребята поспешили надеть спецовки, каски и защитные очки – экскурсантов
          уже ждал Сергей Кузнецов, директор по реализации крупных проектов
          Московского НПЗ.
        </Text>
        <Message name="Директор" image={engineerImg}>
          Нефть к нам поступает с Западно-Сибирских месторождений по
          нефтепроводу. Завод был построен в 1938 году, но оборудование того
          времени я вам уже не покажу, потому что пять старых установок мы
          заменили вот этой красавицей.
        </Message>
        <Image src={factoryImg} alt="НПЗ" />
        <Message name="Директор" image={engineerImg}>
          Это установка полного цикла переработки нефти «Евро+», ее запустили с
          участием президента!
        </Message>

        <VaryaMessage>И что она делает?</VaryaMessage>
        <Message name="Директор" image={engineerImg}>
          В общем-то очень простую вещь – разделяет нефть на составляющие. Это
          можно сделать с помощью нагрева – разные фракции кипят и испаряются
          при разных температурах. Мы их собираем и в итоге получаем бензин,
          дизельное топливо и авиационный керосин. Еще мы используем особые
          вещества, которые помогают перерабатывать нефть еще лучше.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          Новая установка позволяет перерабатывать нефть на 85%, а строящийся
          комплекс позволит добиться почти 100%, то есть каждая капля пойдет в
          дело!
        </p>
        <VaryaDialogue className={cn.block_bottom__varya} />
        <img src={carsImg} alt="Вагоны" className={cn.block_bottom__cars} />
      </div>
    </div>
  );
};

export default Slide;
