import { ComponentProps, FC } from "react";

import Message from "./Message";

const VaryaMessage: FC<ComponentProps<"div">> = ({
  children,
  ...restProps
}) => {
  return (
    <Message {...restProps} type="Varya">
      {children}
    </Message>
  );
};

export default VaryaMessage;
