import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaStatic } from "games/Kits/Characters";
import { FC } from "react";

import backImg from "./img/back.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={backImg} alt="Фон" className={cn.image}>
          <VaryaStatic className={cn.image__varya} />
        </Image>
        <VaryaMessage>
          Смотри, Витя, на этой марке пионеры ставят химические опыты. А ты был
          пионером?
        </VaryaMessage>
        <VityaMessage>
          Нет. Но химию я очень любил. У меня даже был набор «Юный химик» – мы с
          другом сделали невидимые чернила и писали друг другу секретные
          послания.
        </VityaMessage>
        <VaryaMessage>
          А почему у меня такого нет? Может, я тоже хочу секретные послания.
        </VaryaMessage>
        <RobyMessage>
          Варя, это легко поправить. Едем в «Технополис Москва» – это огромный
          производственный комплекс, где под одной крышей собраны больше 200
          предприятий. Так вот, одно из них – компания «Научные развлечения»,
          они делают такие наборы и многое другое.
        </RobyMessage>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
