import Finish from "components/Finish/Finish";
import Start from "components/Start/Start";
import AgroHolding from "games/agroHolding/AgroHolding";
import AgroHoldingIcon from "games/agroHolding/icon.png";
import AgroHoldingPoststamp from "games/agroHolding/postageStamp.png";
import AirCraft from "games/airCraft/AirCraft";
import AirCraftIcon from "games/airCraft/icon.png";
import AirCraftPoststamp from "games/airCraft/postageStamp.png";
import CottageCheese from "games/cottageCheese/CottageCheese";
import CottageCheeseIcon from "games/cottageCheese/icon.png";
import CottageCheesePoststamp from "games/cottageCheese/postageStamp.png";
import Dymov from "games/dymov/Dymov";
import DymovIcon from "games/dymov/icon.png";
import DymovPoststamp from "games/dymov/postageStamp.png";
import Ecopolis from "games/ecopolis/Ecopolis";
import EcopolisIcon from "games/ecopolis/icon.png";
import EcopolisPoststamp from "games/ecopolis/postageStamp.png";
import ForaRobotics from "games/foraRobotics/ForaRobotics";
import ForaRoboticsIcon from "games/foraRobotics/icon.png";
import ForaRoboticsPoststamp from "games/foraRobotics/postageStamp.png";
import IceCream from "games/iceCream/IceCream";
import IceCreamIcon from "games/iceCream/icon.png";
import IceCreamPoststamp from "games/iceCream/postageStamp.png";
import IceCreamPoststamp2 from "games/iceCream/postageStamp2.png";
import IceCreamPoststamp3 from "games/iceCream/postageStamp3.png";
import KhrunichevIcon from "games/khrunichev/icon.png";
import Khrunichev from "games/khrunichev/Khrunichev";
import KhrunichevPoststamp from "games/khrunichev/postageStamp.png";
import MkmIcon from "games/mkm/icon.png";
import Mkm from "games/mkm/Mkm";
import MkmPoststamp from "games/mkm/postageStamp.png";
import MorIcon from "games/mor/icon.png";
import Mor from "games/mor/Mor";
import MorPoststamp from "games/mor/postageStamp.png";
import NauraIcon from "games/naura/icon.png";
import Naura from "games/naura/Naura";
import NauraPoststamp from "games/naura/postageStamp.png";
import ParisianCommuneIcon from "games/parisianCommune/icon.png";
import ParisianCommune from "games/parisianCommune/ParisianCommune";
import ParisianCommunePoststamp from "games/parisianCommune/postageStamp.png";
import Picaso3dIcon from "games/picaso3d/icon.png";
import Picaso3d from "games/picaso3d/Picaso3d";
import Picaso3dPoststamp from "games/picaso3d/postageStamp.png";
import VdohniIcon from "games/vdohni/icon.png";
import VdohniPoststamp from "games/vdohni/postageStamp.png";
import Vdohni from "games/vdohni/Vdohni";
import { router_type } from "types";

export const router = [
  {
    id: 0,
    meta: {
      title: "Заводные путешествия",
      description:
        "Детский интерактивно-образовательный проект Департамент инвестиционной и промышленной политики города Москвы о промышленности столицы",
    },
    title: "Старт",
    link: "/",
    type: "start",
    component: Start,
  },
  {
    id: 1,
    meta: {
      title: "Экскурсия в Космический центр имени Хруничева",
      description:
        "Глава, в которой Витя, Варя и Роби видят ракету, которая долетит до Луны",
    },
    title: "Глава 1",
    name: "Укрощение огня",
    link: "/khrunichev/",
    type: "game",
    component: Khrunichev,
    icon: KhrunichevIcon,
    poststamps: [{ image: KhrunichevPoststamp, text: "СССР, 1962 год" }],
  },
  {
    id: 2,
    meta: {
      title: "Экскурсия на PICASO 3D",
      description:
        "Глава, в которой Витя, Варя и Роби узнают, что умеют 3D-принтеры",
    },
    title: "Глава 2",
    name: "Нарисуем - будем жить",
    link: "/picaso-3d/",
    type: "game",
    component: Picaso3d,
    icon: Picaso3dIcon,
    poststamps: [{ image: Picaso3dPoststamp, text: "Монголия, 1973 год" }],
  },
  {
    id: 3,
    meta: {
      title: "Экскурсия на Московский нефтеперерабатывающий завод",
      description:
        "Глава, в которой Витя, Варя и Роби узнают, зачем нефтеперерабатывающему заводу был нужен фанерный двойник",
    },
    title: "Глава 3",
    name: "Полный бак",
    link: "/mor/",
    type: "game",
    component: Mor,
    icon: MorIcon,
    poststamps: [
      {
        image: MorPoststamp,
        text: "Автомобиль Chevelle SS 1970 года выпуска. США, 2013 год.",
      },
    ],
  },
  {
    id: 4,
    meta: {
      title: "Экскурсия в лабораторию «Фора Роботикс»",
      description: "Глава, в которой Роби знакомится со своими собратьями",
    },
    title: "Глава 4",
    name: "Шоу двойников",
    link: "/forarobotics/",
    type: "game",
    component: ForaRobotics,
    icon: ForaRoboticsIcon,
    poststamps: [
      {
        image: ForaRoboticsPoststamp,
        text: "Технические достижения России. Год науки и технологий. Россия, 2021 год.",
      },
    ],
  },
  {
    id: 5,
    meta: {
      title: "Экскурсия на Лианозовский молочный комбинат",
      description:
        "Глава, в которой Витя, Варя и Роби узнают секрет детских творожков и понимают, почему их так любят взрослые",
    },
    title: "Глава 5",
    name: "Творим творог",
    link: "/cottage-cheese/",
    type: "game",
    component: CottageCheese,
    icon: CottageCheeseIcon,
    poststamps: [
      {
        image: CottageCheesePoststamp,
        text: "Куба, 1984 год",
      },
    ],
  },
  {
    id: 6,
    meta: {
      title: "Экскурсия в агрохолдинг «Московский»",
      description:
        "Глава, в которой Роби угощает Витю и Варю капустными чипсами",
    },
    title: "Глава 6",
    name: "Зеленая энергия",
    link: "/agroholding/",
    type: "game",
    component: AgroHolding,
    icon: AgroHoldingIcon,
    poststamps: [{ image: AgroHoldingPoststamp, text: "Румыния, 1963 год." }],
  },
  {
    id: 7,
    meta: {
      title: "Экскурсия на завод «Москабельмет»",
      description:
        "Глава, в которой Витя, Варя и Роби узнают, как производят медный кабель и что скрывает Эйфелева башня",
    },
    title: "Глава 7",
    name: "Войти в сеть",
    link: "/mkm/",
    type: "game",
    component: Mkm,
    icon: MkmIcon,
    poststamps: [{ image: MkmPoststamp, text: "СССР, 1967 год" }],
  },
  {
    id: 8,
    meta: {
      title: "Экскурсия на завод колбас и деликатесов «Дымов»",
      description:
        "Глава, в которой Витя, Варя и Роби узнали секрет приготовления молочных сосисок на заводе «Дымов»",
    },
    title: "Глава 8",
    name: "Расколбас",
    link: "/dymov/",
    type: "game",
    component: Dymov,
    icon: DymovIcon,
    poststamps: [
      { image: DymovPoststamp, text: "Рекламный плакат. СССР, 1937 год." },
    ],
  },
  {
    id: 9,
    meta: {
      title: "Экскурсия в московскую корпорацию «Экополис»",
      description:
        "Глава, в которой Варя, Витя и Роби узнают, как перерабатывается мусор",
    },
    title: "Глава 9",
    name: "Золотоискатели",
    link: "/ecopolis/",
    type: "game",
    component: Ecopolis,
    icon: EcopolisIcon,
    poststamps: [{ image: EcopolisPoststamp, text: "Сирия, 1971 год." }],
  },
  {
    id: 10,
    meta: {
      title: "Экскурсия на фабрику «Парижская коммуна»",
      description:
        "Глава, в которой Роби моделирует обувь, а Варя с Витей приклеивают подошвы",
    },
    title: "Глава 10",
    name: "Весело шагать",
    link: "/parisian-commune/",
    type: "game",
    component: ParisianCommune,
    icon: ParisianCommuneIcon,
    poststamps: [
      {
        image: ParisianCommunePoststamp,
        text: "Финляндия, 2009 год.",
      },
    ],
  },
  {
    id: 11,
    meta: {
      title: "Экскурсия на завод «Айкрафт»",
      description:
        "Глава, в которой Витя и Варя выбирают Роби модные очки на заводе «Айкрафт»",
    },
    title: "Глава 11",
    name: "Лето на носу",
    link: "/aircraft/",
    type: "game",
    component: AirCraft,
    icon: AirCraftIcon,
    poststamps: [
      {
        image: AirCraftPoststamp,
        text: "Махатма Ганди. Кипр, 1970 год.",
      },
    ],
  },
  {
    id: 12,
    meta: {
      title: "Экскурсия в лабораторию парфюмерной марки VDOHNI",
      description:
        "Глава, в которой Витя, Варя и Роби узнают, как рождается аромат",
    },
    title: "Глава 12",
    name: "Мир духов",
    link: "/vdohni/",
    type: "game",
    component: Vdohni,
    icon: VdohniIcon,
    poststamps: [
      { image: VdohniPoststamp, text: "Коко Шанель. Танзания, 1999 год." },
    ],
  },
  {
    id: 13,
    meta: {
      title: "Экскурсия в компанию «Научные развлечения»",
      description:
        "Глава, в которой Витя, Варя и Роби делают батарейку из яблока, видят звук и управляют воздушным шаром с помощью магнита",
    },
    title: "Глава 13",
    name: "Семь раз измерь",
    link: "/naura/",
    type: "game",
    component: Naura,
    icon: NauraIcon,
    poststamps: [
      {
        image: NauraPoststamp,
        text: "СССР, 1972 год.",
      },
    ],
  },
  {
    id: 14,
    meta: {
      title: "Экскурсия на фабрику «Московское мороженое»",
      description:
        "Глава, в которой Витя, Варя и Роби учатся делать правильное мороженое",
    },
    title: "Глава 14",
    name: "Холодное сердце",
    link: "/ice-cream/",
    type: "game",
    component: IceCream,
    icon: IceCreamIcon,
    poststamps: [
      {
        image: IceCreamPoststamp,
        text: "Герои мультфильма «Ненси и Слагго». США, 1995 год.",
      },
      {
        image: IceCreamPoststamp2,
        text: "70 лет советскому цирку. СССР, 1989 год.",
      },
      {
        image: IceCreamPoststamp3,
        text: "Первый международный конкурс артистов балета. СССР, 1969 год.",
      },
    ],
  },
  {
    id: 15,
    meta: {
      title: "Результаты игры «заводные путешествия» ",
      description:
        "Пройди все задания «Заводных путешествий» и получи подарок от департамента инвестиционной и промышленной политики Москвы",
    },
    title: "Финиш",
    link: "/finish/",
    type: "finish",
    component: Finish,
  },
] as Array<router_type>;
