import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VityaWow } from "games/Kits/Characters";
import { FC } from "react";

import engineerImg from "../../assets/img/engineer.png";
import imageSrc from "./img/image.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          Они подошли к конвейеру, на котором рабочие в спецовках отделяли
          опасные элементы: аккумуляторы, ртутные лампы, картриджи с тонером.
        </Text>
        <Message name="Главный инженер" image={engineerImg}>
          Теперь «разминированная» техника отправится в дробилку. Видите этот
          курган? Здесь приблизительно 150 тонн электрохлама. Так вот, через 15
          часов от него ничего не останется.
        </Message>
        <VityaMessage>
          А дальше что? Что делать с этими кусками? Сортировать вручную?
        </VityaMessage>
        <Message name="Главный инженер" image={engineerImg}>
          Совсем немного. Мы отделим микросхемы, электромоторы, а остальное
          отправим в шредер, который разобьет сырье на мелкие кусочки. Ведь наша
          задача – полностью разобрать его на составляющие. Давайте посмотрим,
          как это делается. Вот здесь магнит отделяет черные металлы, затем
          потоки воздуха выдувают весь мусор. А потом сырье попадает в вихревой
          сепаратор, из него в одну сторону вылетает пластик, а в другую – медь
          и алюминий. Их мы тоже потом разделим – глаз оптического сепаратора
          умеет отличать одно от другого. Имейте в виду, что, например, в этом
          году биржевые цены на медь доходили до 600 тысяч рублей за тонну.
        </Message>
        <VaryaMessage>И такая куча денег могла гнить на свалке!</VaryaMessage>
        <Text>Каждый год на свалках теряется до 5 тонн золота.</Text>
        <Image src={imageSrc} alt="Роби" />
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          Цепная дробилка за час может разорвать на кусочки 10 тонн сломанной
          техники.
        </p>
        <VityaWow className={cn.block_bottom__vitya} />
      </div>
    </div>
  );
};

export default Slide;
