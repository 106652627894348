import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import image from "./img/image.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={image} alt="Опыты" />
        <RobyMessage> А лаборатория электричества у вас есть?</RobyMessage>
        <Message name="Директор" image={directorImg}>
          Конечно! Хочешь, покажу, как сделать батарейку из яблока? Только надо
          выбрать самое кислое.
        </Message>
        <VaryaMessage>А можно яблоком зарядить телефон?</VaryaMessage>
        <Message name="Директор" image={directorImg}>
          Одним не получится. Для этого придется соединить штук шесть. Вот
          смотрите: берем яблоко, втыкаем в него с одной стороны стальную
          пластинку, с другой – медную, подсоединяем контакты и – видите? –
          почти 0,7 вольта.
        </Message>
        <RobyMessage>
          Здорово! Буду теперь путешествовать с корзиной яблок! Главное, чтобы
          Варя их все не съела.
        </RobyMessage>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
