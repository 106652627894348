import { FC } from "react";
import { color } from "types";

import cn from "./Clips.module.scss";

type ownProps_type = {
  topColor: color;
  bottomColor: color;
};

const Clip: FC<ownProps_type> = ({ topColor, bottomColor }) => {
  return (
    <div className={cn.clip}>
      <span
        className={cn.clip__top}
        style={{ backgroundColor: topColor }}
      ></span>
      <span
        className={cn.clip__bottom}
        style={{ backgroundColor: bottomColor }}
      ></span>
    </div>
  );
};
export default Clip;
