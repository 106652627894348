import Book from "components/Blocks/Book/Book";
import { FC, useEffect } from "react";

import arrow from "./img/arrow.svg";
import bookImg from "./img/book.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = ({
  changeBackColor,
  changeCloudsColor,
  ...restProps
}) => {
  useEffect(() => {
    changeBackColor("#1C5F74", "#1C5F74");
    changeCloudsColor("#0F4F63 ");

    return () => {
      changeBackColor();
      changeCloudsColor();
    };
  }, [changeBackColor, changeCloudsColor]);

  return (
    <div className={cn.slide}>
      <Book {...restProps} arrowImg={arrow} bookImg={bookImg} />
    </div>
  );
};

export default Slide;
