import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaDialogue } from "games/Kits/Characters/index";
import { FC } from "react";

import engineerImg from "../../assets/img/engineer.png";
import factoryImg from "./img/factory.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VityaMessage>
          Как же всем этим управлять? Сюда же просто не пролезть!
        </VityaMessage>
        <Message name="Директор" image={engineerImg}>
          Всем управляют из операторной, сейчас мы до нее доберемся.
        </Message>
        <Image src={factoryImg} alt="Операторская" />
        <Text>
          Вскоре ребята вошли в просторное прохладное помещение, где сидели
          операторы и сосредоточенно вглядывались в мониторы. И этих мониторов
          было у каждого по четыре, а у некоторых – даже по восемь.
        </Text>
        <RobyMessage>
          Это напоминает центр управления полетами. Даже экран на стене похож.
        </RobyMessage>
        <Message name="Директор" image={engineerImg}>
          На экран выводятся данные о качестве воздуха на заводе и соседних
          территориях. Мы берем пробы в 11 точках два раза в день. А прямо в
          трубах у нас работают роботы (да, Роби), которые непрерывно мониторят
          состав воздуха.
        </Message>
        <VaryaMessage>
          Да, такой завод рядом с жилыми кварталами – это очень волнительно.
        </VaryaMessage>
        <Message name="Директор" image={engineerImg}>
          Давайте я вас познакомлю с Юрием Ерохиным, он у нас заведует всей
          экологией. Я уверен, он вас успокоит.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          Установка для переработки нефти «Евро-5» – это 700 единиц
          оборудования, соединенные 1700 км кабелей. Ее работу контролируют 15
          000 датчиков, передающие информацию в центральную операторную.
        </p>
        <VaryaDialogue className={cn.block_bottom__varya} />
      </div>
    </div>
  );
};

export default Slide;
