import classNames from "classnames";
import AllMessage from "components/Blocks/Chat/Message/AllMessage";
import Message from "components/Blocks/Chat/Message/Message";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import imageSrc from "./img/image.jpg";
import sausagesImg from "./img/sausages.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={imageSrc} alt="Друзья" />
        <Message image={directorImg} name="Директор">
          Дальше аппарат выдавливает фарш в оболочку, причем вес каждой сосиски
          контролирует компьютер, человек только загружает фарш. А теперь
          отправим наши сосиски в камеру, где они сначала подсушатся, потом
          подкоптятся и сварятся.
        </Message>
        <Text>
          Не без труда ребята затолкали тележку с гирляндами сосисок в камеру,
          где она проведет полтора часа.
        </Text>
        <VityaMessage>А аромат?</VityaMessage>
        <Message image={directorImg} name="Директор">
          Это самый главный наш секрет. Вот в этой печи мы нагреваем щепу бука,
          и ароматный дым по трубам поступает в камеру, где томятся сосиски.
          Хотите попробовать?
        </Message>
        <AllMessage>Очень!</AllMessage>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#A84147 " />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          ЭТО ИНТЕРЕСНО!
        </p>
        <p>
          Для изготовления своих копченостей комбинат ежедневно расходует более
          400 кг буковой щепы.
        </p>

        <img
          src={sausagesImg}
          alt="Сосиски"
          className={cn.block_bottom__sausages}
        />
      </div>
    </div>
  );
};

export default Slide;
