import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyIcecream, VityaWow } from "games/Kits/Characters";
import { FC } from "react";

import Animate from "@charlesvien/react-animatecss";

import iceCream from "../../assets/img/ice-cream.png";
import technologistImg from "../../assets/img/technologist.png";
import factoryImg from "./img/factory.png";
import scoop4Img from "./img/scoop_blue.png";
import scoop5Img from "./img/scoop_ice.png";
import scoop2Img from "./img/scoop_lightgreen.png";
import scoop3Img from "./img/scoop_pink.png";
import scoop1Img from "./img/scoop_yellow.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <Animate animationIn="fadeIn" inDuration={1000} visible>
        <Text className={cn.text}>
          Технолог Светлана подвела ребят к агрегату с цифровым дисплеем.
        </Text>
      </Animate>
      <Animate animationIn="fadeIn" inDuration={1000} visible>
        <Image src={factoryImg} alt="Сливки" className={cn.block_top}>
          <VityaWow className={cn.block_top__vitya} />
        </Image>
      </Animate>
      <WhiteBlock className={cn.white}>
        <img src={iceCream} alt="Мороженое" className={cn.white__iceCream} />
        <Message name="Технолог" image={technologistImg}>
          Позвольте представить вам нашего главного героя. Это фризер – этакая
          смесь морозильника и стиральной машины. Он одновременно перемешивает,
          насыщает воздухом и охлаждает смесь до –5 градусов. За час он может
          заморозить 2 тонны смеси. Это уже почти готовое мороженое. Кстати, вот
          вам еще одна задачка, которая кажется очень простой: как вставить в
          мороженое палочку?
        </Message>
        <VityaMessage>
          Если мороженое мягкое, то палочка просто вывалится, а если слишком
          твердое, то сломается. Что же делать?
        </VityaMessage>
        <Message name="Технолог" image={technologistImg}>
          Вот, смотрите. Мягкое мороженое выдавливается из этой трубы, порция
          нужного размера отрезается, в нее вставляется палочка, и все это почти
          мгновенно попадает в туннель закаливания, где у нас арктический холод:
          –40. Там эскимо проведет 40–50 минут, и палочка вмерзнет в него
          накрепко.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D2D2D2" bottomColor="#18446C" doubled />
      <div className={classNames("block", cn.block_bottom)}>
        <RobyIcecream className={cn.block_bottom__image} />
        <p
          style={{ fontSize: 48, lineHeight: "61px", marginBottom: 30 }}
          className={classNames("title", cn.title)}
        >
          А вы знаете чем отличаются разные виды мороженого?
        </p>
        <div className={cn.scoopes}>
          <div className={cn.scoopes__item}>
            <div className={cn.scoopes__item__image}>
              <img src={scoop1Img} alt="Мороженое" />
            </div>
            <span>
              Пломбир делают на основе цельного молока и жирных сливок, в нем от
              12 до 20% жира.
            </span>
          </div>
          <div className={cn.scoopes__item}>
            <div className={cn.scoopes__item__image}>
              <img src={scoop2Img} alt="Мороженое" />
            </div>
            <span>
              Сливочное мороженое тоже состоит из молока и сливок, но оно менее
              жирное (от 8 до 11,5% жира)
            </span>
          </div>
          <div className={cn.scoopes__item}>
            <div className={cn.scoopes__item__image}>
              <img src={scoop3Img} alt="Мороженое" />
            </div>
            <span>
              Молочное мороженое – самое диетическое. Для его изготовления
              используют обезжиренное и цельное молоко, содержание жира в нем от
              0,5 до 7,5%
            </span>
          </div>
          <div className={cn.scoopes__item}>
            <div className={cn.scoopes__item__image}>
              <img src={scoop4Img} alt="Мороженое" />
            </div>
            <span>
              Сорбет делают на основе натуральных соков, фруктовых пюре
            </span>
          </div>
          <div className={cn.scoopes__item}>
            <div className={cn.scoopes__item__image}>
              <img src={scoop5Img} alt="Мороженое" />
            </div>
            <span>
              Фруктовый лед – обычная замороженная сосулька из сока, йогурта или
              фруктового чая
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slide;
