import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import guideImg from "../../assets/img/guide.png";
import carImg from "./img/car.png";
import factoryImg from "./img/factory.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          В этот раз их экскурсоводом была приветливая женщина, отвечающая на
          заводе за приемку свежего молока.
        </Text>
        <Message name="Экскурсовод" image={guideImg}>
          Каждый день мы получаем около 1200 тонн молока, это больше 50
          молоковозов. Вот эти фуры сначала отправляются на мойку и потом –
          чистые и блестящие, без единой пылинки – заезжают к нам.
        </Message>
        <Image src={factoryImg} alt="Фабрика" className={cn.factory} />
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#1D99C0" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          Каждый день на Лианозовский молочный комбинат приезжает более 50
          молоковозов с 80 окрестных ферм. Машины прибывают круглосуточно. Всего
          завод перерабатывает 1200 тонн молока в день.
        </p>
        <img src={carImg} alt="Молоко" className={cn.block_bottom__car} />
      </div>
    </div>
  );
};

export default Slide;
