import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaGlassesStar } from "games/Kits/Characters";
import { FC } from "react";

import doctorImg from "../../assets/img/doctor.png";
import robyImg from "./img/roby.png";
import shoreImg from "./img/shore.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VityaMessage>
          Так, про линзы понятно. А как они превращаются в очки?
        </VityaMessage>
        <Message name="Главный инженер" image={doctorImg}>
          Тут без людей не обойтись – прости, Роби. Давайте посмотрим на сборку.
          Здесь очки не только собирают, но и чинят. Через руки мастера за день
          может пройти больше двухсот пар очков, представляете?
        </Message>
        <Text>
          В просторном помещении трудилось множество людей. Одни разогревали
          пластиковые оправы, вставляли в них линзы, а затем остужали, чтобы
          пластик плотно обхватил стекла. Другие разбирали и собирали
          металлические оправы, ловко управляясь с крошечными винтиками.
        </Text>
        <VaryaMessage>А солнечные очки для меня у вас есть?</VaryaMessage>
        <Message name="Главный инженер" image={doctorImg}>
          У нас есть всё! А хочешь специальные желтые очки, в которых каждый
          день будет солнечным? Наши мастера сами носят их в пасмурные дни, в
          них совсем не устают глаза.
        </Message>
        <Image src={shoreImg} alt="Пляж">
          <VaryaGlassesStar className={cn.shore_varya} />
        </Image>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#500A73" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          Очки «Айкрафт» поставляются в 500 магазинов, 150 из которых находятся
          в Москве и Подмосковье.
        </p>
        <img src={robyImg} alt="Роби" className={cn.block_bottom__roby} />
      </div>
    </div>
  );
};

export default Slide;
