import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import imageSrc from "./img/image.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <VaryaMessage>
          Какая трогательная картина – Роби с цветами!
        </VaryaMessage>
        <Image className={cn.image} src={imageSrc} alt="Роби нюхает цветы" />
        <VityaMessage>
          Да, очень мило. Хочешь кофе с молоком? Я только что сварил.
        </VityaMessage>
        <RobyMessage>Арабика. Слабой обжарки, мелкий помол.</RobyMessage>
        <VityaMessage>Роби, ты у нас Шерлок Холмс!</VityaMessage>
        <RobyMessage>
          Я недавно проапгрейдил свой спектральный анализатор и теперь уверен,
          что могу раскладывать запахи на составляющие не хуже профессиональных
          парфюмеров. Кстати, позвольте представить вам Коко Шанель,
          создательницу знаменитых духов «Шанель № 5», – вот ее портрет на
          марке.
        </RobyMessage>
        <Text>
          С марки на Витю и Варю смотрела дама в шляпке и жемчужном ожерелье.
        </Text>
        <VaryaMessage>Красивая. И строгая.</VaryaMessage>
        <RobyMessage>
          А вы знаете, что у нас в Москве есть своя Шанель? Это Полина Казакова,
          создатель парфюмерной марки VDOHNI.
        </RobyMessage>
        <VaryaMessage>
          Ой, Роби, а давай к ней съездим! Там, где делают духи, должно быть
          много цветов, и ягод, и апельсинов, и ванильных булочек. Иначе откуда
          же берутся все эти чудесные запахи?
        </VaryaMessage>
        <RobyMessage>Звоню Полине.</RobyMessage>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
