import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyStatic } from "games/Kits/Characters";
import { FC } from "react";

import constructorImg from "../../assets/img/constructor.png";
import rocketImg from "./img/rocket.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={rocketImg} alt="Ракета" className={cn.image} />
        <Message name="Конструктор" image={constructorImg}>
          В этом цеху происходит сборка ракет-носителей «Ангара». Мы как раз
          идем вдоль частей тяжелой ракеты «Ангара-5».
        </Message>
        <VityaMessage>И что это за части?</VityaMessage>
        <Message name="Конструктор" image={constructorImg}>
          Четыре боковых блока – это первая ступень, и центральный – это вторая.
          Первая ступень самая большая, хотя проработает она всего 120 секунд.
          Ведь ее задача – оторвать от земли махину массой 780 тонн. Затем в
          дело вступает вторая ступень, она поменьше, а потом, уже в космосе, –
          третья.
        </Message>
        <VaryaMessage>А это что за сияющая пещера?</VaryaMessage>
        <Message name="Конструктор" image={constructorImg}>
          Это головной обтекатель, причем только его половина. Вторая – вон там.
          Изнутри он покрыт элементами, которые стабилизируют температуру в
          космическом аппарате, и материалом, похожим на фольгу. В космосе эти
          «скорлупки» отстреливаются, и дальше разгонный блок выводит полезную
          нагрузку на нужную орбиту. Эта красавица может доставить 24 тонны
          груза на низкую орбиту! А скоро появится еще одна модификация, которая
          будет поднимать до 38 тонн.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          Первый испытательный пуск «Ангары-А5» состоялся в декабре 2014 года.
          Все прошло без происшествий, и через 9 часов 2 тонны полезного груза
          (правда, всего лишь его макет) были успешно доставлены на
          геостационарную орбиту – 35 тысяч километров над Землей.
        </p>
        <RobyStatic className={cn.block_bottom__roby} />
      </div>
    </div>
  );
};

export default Slide;
