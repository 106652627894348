import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow-big.svg";
import { ReactComponent as CrossIcon } from "assets/images/icons/cross.svg";
import { ReactComponent as FinishIcon } from "assets/images/icons/finish.svg";
import { ReactComponent as GameIcon } from "assets/images/icons/game.svg";
import { ReactComponent as StartIcon } from "assets/images/icons/home.svg";
import classNames from "classnames";
import { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import { getChapters, getChaptersCount } from "redux/selectors/app_selector";
import { AppStateType } from "redux/store";

import cn from "./Menu.module.scss";

type ownProps_type = {
  isVisible: boolean;
  completedChaptersCount: number;
  setIsMenuVisible: (isMenuVisible: boolean) => void;
};

const Menu: FC<reduxProps_type & ownProps_type> = ({
  isVisible,
  chaptersCount,
  chapters,
  completedChaptersCount,
  setIsMenuVisible,
}) => {
  return (
    <div className={classNames(cn.menu, { [cn.menu_active]: isVisible })}>
      <div className={cn.menu__top}>
        <div className={cn.menu__progress}>
          <span>
            Пройдено игр{" "}
            <span className={cn.menu__progress__count}>
              ({completedChaptersCount}/{chaptersCount} игр)
            </span>
          </span>
          <div className={cn.menu__progress__line}>
            <div
              className={cn.menu__progress__line__back}
              style={{
                width:
                  Math.floor((completedChaptersCount / chaptersCount) * 100) +
                  "%",
              }}
            ></div>
          </div>
        </div>
        <div
          className={cn.menu__closer}
          onClick={() => setIsMenuVisible(false)}
        >
          <CrossIcon />
        </div>
      </div>
      <div className={cn.menu__body}>
        <div className={cn.menu__body__inner}>
          {chapters.map((chapter, idx) => (
            <Link
              to={chapter.link}
              className={classNames(cn.menu__item, {
                [cn.menu__item_gray]: chapter.isCompleted,
                [cn.menu__item_active]: chapter.isActive,
                [cn.menu__item_game]: chapter.type === "game",
              })}
              key={idx}
              onClick={() => setIsMenuVisible(false)}
            >
              <span className={cn.menu__item__icon}>
                {chapter.type === "start" ? (
                  <StartIcon />
                ) : chapter.type === "game" ? (
                  <GameIcon />
                ) : (
                  <FinishIcon />
                )}
              </span>
              <span className={cn.menu__item__link}>
                {chapter.name
                  ? chapter.title + ". " + chapter.name
                  : chapter.title}
              </span>
              <span className={cn.menu__item__arrow}>
                <ArrowIcon />
              </span>
            </Link>
          ))}
        </div>
      </div>
      <div className={cn.menu__bottom}>
        <p>© Департамент инвестиционной и промышленной политики Москвы, 2022</p>
        <p className={cn.menu__bottom__items}>
          <span>Shutterstock/FOTODOM</span>
          <a href="https://www.freepik.com" target="_blank" rel="noreferrer">
            Image by Freepik
          </a>
          <span>Александр Вильф/РИА Новости</span>
        </p>
        <div
          className={classNames(cn.menu__bottom__links, cn.menu__bottom__items)}
        >
          <p>
            При участии{" "}
            <a href="https://vashagazeta.com/" target="_blank" rel="noreferrer">
              «Люди.People»
            </a>
          </p>
          <a href="mailto:pressprom@mos.ru">pressprom@mos.ru</a>
          <p>Аудитория: 6+</p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  chapters: getChapters(state),
  chaptersCount: getChaptersCount(state),
});

const connector = connect(mapStateToProps, {});
type reduxProps_type = ConnectedProps<typeof connector>;

export default connector(Menu);
