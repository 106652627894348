import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist2.png";
import specialist2Img from "../../assets/img/specialist4.png";
import imageSrc from "./img/image.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          Самым интересным ребятам показался пошивочный цех, где прямо на глазах
          заготовки превращались в туфли, ботинки, сапоги и кроссовки. Там их
          ждали формовщики Людмила и Никита.
        </Text>
        <VaryaMessage>
          Представьте себе, как в этих туфельках девушка пойдет на свидание, а
          может, на экзамен…
        </VaryaMessage>
        <Message name="Формовщица" image={specialistImg}>
          В нашей обуви ходят не только на свидания! Мы шьем по индивидуальным
          меркам сапоги для роты почетного караула, так что наша продукция
          каждый год участвует в параде Победы.
        </Message>
        <Message name="Формовщик" image={specialist2Img}>
          А еще мы выпускаем ботинки для сварщиков и металлургов – в них можно
          полчаса без опаски простоять на поверхности, разогретой до 160
          градусов, а от жара 300 градусов они защищают целую минуту. Есть
          супертеплая обувь для нефтяников, работающих на Севере, а есть сапоги
          для лесников – они оснащены ловушкой для энцефалитных клещей и тканью
          с пропиткой, которая парализует этих опасных насекомых. Так что наша
          обувь не только для парадов годится.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#A84147" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          До 1938 года День Парижской коммуны был в нашей стране государственным
          праздником и нерабочим днем.
        </p>
        <img src={imageSrc} className={cn.block_bottom__image} alt="Марка" />
      </div>
    </div>
  );
};

export default Slide;
