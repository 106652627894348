import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaYogurt } from "games/Kits/Characters";
import VaryaInvitation from "games/Kits/Game/Invitation/Varya/Varya";
import { FC } from "react";

import technologistImg from "../../assets/img/technologist.png";
import factoryImg from "./img/factory.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <Text>
        Но Вари рядом не оказалось. Нашли ее немного позже, возле больших
        блестящих бочек, от которых растекался манящий клубничный аромат.
      </Text>
      <WhiteBlock className={cn.white}>
        <VaryaMessage>
          Простите меня, пожалуйста. Меня запах заманил. У меня от него даже
          голова закружилась.
        </VaryaMessage>
        <Message name="Технолог" image={technologistImg}>
          Ничего удивительного. Это же бочки с вареньем, которое мы добавляем в
          творожки и йогурты! Вот в этих котлах – осторожно, они горячие! – мы
          можем сварить за час целых три тонны варенья, причем используем только
          настоящие ягоды и фрукты. В детской продукции вообще все должно быть
          натуральным, консерванты и красители полностью запрещены.
        </Message>
        <VaryaMessage>А кто придумывает новые вкусы творожка?</VaryaMessage>
        <Message name="Технолог" image={technologistImg}>
          Этим занимается отдел исследований и разработок. Есть даже
          экспериментальный завод, где технологи и дегустаторы создают новые
          продукты. Кстати, дегустационный зал совсем рядом. Обычно экскурсии
          для школьников заканчиваются именно там. Вы же не откажетесь
          перекусить?
        </Message>
        <Text>
          И Варе наконец удалось попробовать клубничное чудо, которое вскружило
          ей голову в цеху.
        </Text>
        <Image src={factoryImg} alt="Фабрика">
          <VaryaYogurt className={cn.factory_varya} />
        </Image>
      </WhiteBlock>
      <VaryaInvitation />
    </div>
  );
};

export default Slide;
