import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import robyImg from "./img/roby.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={robyImg} alt="Луноход" />
        <VityaMessage>Что за шум? Роби, что ты тут устроил?!</VityaMessage>
        <RobyMessage>
          Что тут непонятного? Готовлюсь к лунной экспедиции. Как ты думаешь,
          далеко я на Луне уеду на одном колесе? Я нашел в альбоме марку с
          советским «Луноходом-1» и взял за образец его ходовую часть, а у
          твоего прадедушки (вот удача!) и фрезерный станок нашелся. Еще
          пара-тройка дней – и будут у меня колеса не хуже.
        </RobyMessage>
        <VityaMessage>
          Роби, опомнись! Кто же делает такое на фрезерном станке? Это задача
          для 3D-принтера. На нем, кстати, твой корпус распечатан, так что тебе
          стыдно не знать. Надо немедленно ликвидировать этот пробел!
        </VityaMessage>
        <Text>
          Поэтому уже на следующий день друзья отправились в Зеленоград, на
          предприятие компании PICASO 3D, где их встретил генеральный директор
          Андрей, по возрасту ненамного старше Виктора.
        </Text>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
