import { useEffect, useState } from "react";

function preloadVideo(src: string) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", src, true);
    xhr.responseType = "blob";

    xhr.addEventListener(
      "load",
      function () {
        if (xhr.status === 200) {
          var URL = window.URL || window.webkitURL;
          var blob_url = URL.createObjectURL(xhr.response);

          resolve(blob_url);
        } else {
          reject(src);
        }
      },
      false
    );

    xhr.send();
  });
}

export default function useVideoPreloader(videoSrc: string) {
  const [videoPreloadedSrc, setVideoPreloadedSrc] = useState<any>(false);

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      if (isCancelled) {
        return;
      }

      const src = await preloadVideo(window.location.origin + videoSrc);

      if (isCancelled) {
        return;
      }

      setVideoPreloadedSrc(src);
    }

    effect();

    return () => {
      isCancelled = true;
    };
  }, [videoSrc]);

  return { videoPreloadedSrc };
}
