import classNames from "classnames";
import { ComponentProps, FC } from "react";

import cn from "./Image.module.scss";

type ownProps_type = {
  src: string;
  alt: string;
};

const Image: FC<ownProps_type & ComponentProps<"div">> = ({
  src,
  alt,
  children,
  ...restProps
}) => {
  return (
    <div {...restProps} className={classNames(cn.wrapper, restProps.className)}>
      <img src={src} alt={alt} className={cn.wrapper__image} />
      {children}
    </div>
  );
};

export default Image;
