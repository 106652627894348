import classNames from "classnames";
import { FC } from "react";
import { color } from "types";

import Clip from "./Clip";
import cn from "./Clips.module.scss";

type ownProps_type = {
  doubled?: boolean;
  topColor: color;
  bottomColor: color;
  [x: string]: any;
};

const Clips: FC<ownProps_type> = ({ topColor, bottomColor, doubled }) => {
  return (
    <div className={classNames(cn.clips, { [cn.clips__doubled]: doubled })}>
      <Clip topColor={topColor} bottomColor={bottomColor} />
      {doubled && <Clip topColor={topColor} bottomColor={bottomColor} />}
    </div>
  );
};

export default Clips;
