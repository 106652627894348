import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import RobyInvitation from "games/Kits/Game/Invitation/Roby/Roby";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import friendsImg from "./img/friends.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>Юрий подвел ребят к большому монитору.</Text>
        <Message name="Эколог" image={specialistImg}>
          Кстати, вот здесь вы можете увидеть цифрового двойника этой установки.
          Он позволяет контролировать работу всех систем. Между прочим, у нас
          богатый опыт изготовления двойников. Во время войны, чтобы сбить с
          толку немецкие бомбардировщики, мы в трех километрах от завода
          построили его близнеца – из фанеры. За три месяца полностью
          скопировали корпуса, а из соседнего леса привезли два десятка деревьев
          и высадили среди муляжей. Уловка сработала: фашисты старательно
          бомбили нашу подделку, а настоящий завод не пострадал.
        </Message>
        <VityaMessage>Здорово придумано!</VityaMessage>
        <Message name="Эколог" image={specialistImg}>
          Хотите напоследок забраться вот на эту башню?
        </Message>
        <VityaMessage>А можно?</VityaMessage>
        <Message name="Эколог" image={specialistImg}>
          Со мной можно.
        </Message>
        <Text>
          С высоты птичьего полета открывался грандиозный вид на завод и
          Капотню.
        </Text>
        <Message name="Эколог" image={specialistImg}>
          Видите пруд? Там живет семья лебедей, а если повезет, то встретите
          черепаху. Теперь верите в эффективность наших очистных сооружений?
        </Message>
        <VaryaMessage>
          Ну если даже черепахи тут живут, то людям точно можно!
        </VaryaMessage>
        <Image src={friendsImg} alt="Пруд" />
      </WhiteBlock>
      <RobyInvitation />
    </div>
  );
};

export default Slide;
