import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import doctorImg from "../../assets/img/doctor.png";
import glassesImg from "./img/glasses.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <Text style={{ color: "#ffffff" }}>
        Первая линза разлетелась от одного удара, а вот над второй Витя и Роби
        трудились по очереди, но она так и не поддалась.
      </Text>
      <WhiteBlock className={cn.white}>
        <VityaMessage>Упрямая какая!</VityaMessage>
        <Message name="Главный инженер" image={doctorImg}>
          Не упрямая, а безопасная. Кот может сбрасывать ваши очки со стола, вы
          можете кататься в них на скейте и не бояться, что при падении они
          разобьются.
        </Message>
        <Text>
          На заводе «Айкрафт» знают, как дети любят бегать и прыгать. Поэтому
          делают очки из силикона, титана и прочного пластика. Они не сломаются,
          если их уронить и не повредят ребенку, если он упадет.
        </Text>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#09726D" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          ЭТО ИНТЕРЕСНО!
        </p>
        <p>В месяц предприятие выпускает больше 70 тысяч очков.</p>
        <img src={glassesImg} alt="Очки" className={cn.block_bottom__glasses} />
      </div>
    </div>
  );
};

export default Slide;
