import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaDialogue } from "games/Kits/Characters";
import { FC } from "react";

import guideImg from "../../assets/img/guide.png";
import analyzerImg from "./img/analyzer.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <Text className={cn.text}>
        Рабочий в фирменной спецовке ловко ступил на борт молоковоза, пристегнул
        карабин к страховочному тросу и уверенно, точно моряк по палубе, зашагал
        по верху цистерны. В руках у него был большой, похоже, тяжелый миксер.
        Открыв крышку люка и запустив миксер внутрь, человек начал гонять молоко
        по всему цилиндру.
      </Text>
      <WhiteBlock className={cn.white}>
        <VityaMessage>Это чтобы молоко стало однородным?</VityaMessage>
        <VaryaMessage>
          А страховка нужна, чтобы он не свалился в цис­терну и не утонул?
        </VaryaMessage>
        <Message name="Экскурсовод" image={guideImg}>
          Вы оба правы.
        </Message>
        <Message name="Экскурсовод" image={guideImg}>
          Он отнесет пробу в лабораторию, где мои коллеги проверят молоко на
          наличие антибиотиков, мик­робов и прочих загрязнителей.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D2D2D2" bottomColor="#6E168D" doubled />
      <div className={classNames("block", cn.block_interesting)}>
        <p className={classNames("title", cn.block_interesting__title)}>
          ЭТО ИНТЕРЕСНО!
        </p>
        <p>
          Аппарат «Милкоскан» может оценить качество молока всего за 15 минут.
        </p>
        <VaryaDialogue className={cn.block_interesting__varya} />
        <img
          src={analyzerImg}
          alt="Анализатор"
          className={cn.block_interesting__analyzer}
        />
      </div>
    </div>
  );
};

export default Slide;
