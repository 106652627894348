import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaDialogue } from "games/Kits/Characters";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          Назавтра компания друзей уже была в Хамовниках, в лаборатории Полины.
        </Text>
        <VaryaMessage>А вы добрая.</VaryaMessage>
        <Message name="Парфюмер" image={specialistImg}>
          Вот это начало! Ты думала, здесь сидит старая колдунья, заговаривает
          травы и варит зелье?
        </Message>
        <VaryaMessage>
          Ну не так, конечно. Просто мы видели портрет Коко Шанель на марке, и
          мне она показалась очень строгой.
        </VaryaMessage>
        <Message name="Парфюмер" image={specialistImg}>
          Вот оно что… Знаешь, Коко Шанель жила в совсем другое время.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          У нас в носу около 400 типов обонятельных рецепторов. Приблизительно
          350 из них у всех одинаковые, а вот 50 – у каждого свои. Именно
          поэтому каждый воспринимает запахи по-своему.
        </p>
        <VaryaDialogue className={cn.block_bottom__varya} />
      </div>
    </div>
  );
};

export default Slide;
