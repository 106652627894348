import classNames from "classnames";
import {
  VityaDialogue,
  VityaFailure,
  VityaWinner
} from "games/Kits/Characters";
import { FC } from "react";

import GameCharacter from "../GameCharacter";
import cn from "./Vitya.module.scss";

type stateData_type = {
  start?: string;
  static: string;
  failure: string;
  finish: string;
};

type ownProps_type = {
  gameState: keyof stateData_type;
  stateData: stateData_type;
  nextGameLink: string;
  returnBack: () => void;
};

const VityaGame: FC<ownProps_type> = ({
  gameState,
  stateData,
  nextGameLink,
  returnBack,
}) => {
  return (
    <GameCharacter
      className={classNames(cn.vitya, cn[`vitya_${gameState}`])}
      gameState={gameState}
      stateData={stateData}
      nextGameLink={nextGameLink}
      returnBack={returnBack}
    >
      <VityaDialogue />
      <VityaFailure />
      <VityaWinner />
    </GameCharacter>
  );
};

export default VityaGame;
