import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import image2 from "./img/friends.png";
import image from "./img/image.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={image} alt="Роби" />
        <RobyMessage>
          А еще робот, управляемый человеком, может выполнять задания в опасных
          условиях, как в фильме «Аватар».
        </RobyMessage>
        <Message name="Директор" image={directorImg}>
          Мы такое как раз делали – для работы в открытом космосе. Человек
          внутри корабля надевал специальный костюм с датчиками, которые
          передавали все его движения роботу. И вряд ли найдется оператор,
          способный управлять, например, шестиногим роботом с хвостом и
          клешнями.
        </Message>
        <VaryaMessage>
          Ой, а что у вас вон на том экране? Мы что-то похожее видели на заводе,
          который делает бензин. Они называли это, кажется... да, цифровой
          копией.
        </VaryaMessage>
        <Message name="Директор" image={directorImg}>
          Молодец, Варя, это она и есть. – Возможно, вы даже видели образец
          нашей работы. Мы делаем цифровые двойники заводов.
        </Message>
        <VaryaMessage>
          А зачем они нужны? А то я в прошлый раз не очень поняла.
        </VaryaMessage>
        <Message name="Директор" image={directorImg}>
          Это же не просто картинки. Наши двойники живые, там работают станки,
          перемещаются грузы, течет вода по трубам. В цехах стоят датчики,
          которые передают информацию обо всем происходящем. Например, вы сразу
          увидите, что станок прекратил работу, и направите к нему ремонтную
          бригаду или доставите необходимые заготовки. Таким цифровым заводом
          может управлять всего несколько человек.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#7A6519" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          Для создания цифровой копии настоящее предприятие тщательно обмеряют
          лазерным сканером – он может измерять расстояния в сотни метров с
          точностью до трех миллиметров.
        </p>
        <img src={image2} alt="Робот" className={cn.block_bottom__image} />
      </div>
    </div>
  );
};

export default Slide;
