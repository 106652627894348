import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import Clips from "components/Blocks/Clips/Clips";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VityaWow } from "games/Kits/Characters";
import { FC } from "react";

import specialistImg from "../../assets/img/specialist.png";
import specialist2Img from "../../assets/img/specialist3.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Message name="Начальник раскройного цеха" image={specialistImg}>
          Дальше раскроенные детали отправляются в цех сборки заготовки.
        </Message>
        <Text>
          И Елена провела компанию к Надежде, начальнику цеха сборки. Надежда
          прихватила из корзины целую стопку кусочков кожи, которым предстояло
          превратиться в туфли.
        </Text>
        <Message name="Начальник цеха сборки" image={specialist2Img}>
          Видите штамп на каждой детали? Первые две цифры – размер будущей
          обуви, затем – номер партии (ведь кожа – натуральный материал, и ее
          цвет и фактура могут слегка отличаться в разных партиях), а последние
          цифры – индивидуальный номер раскройщика. Так что мы все здесь лично
          отвечаем за свою работу.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          Производство обуви включает 300 операций – от раскроя кожи до пошива и
          полировки.
        </p>

        <VityaWow className={cn.block_bottom__vitya} />
      </div>
    </div>
  );
};

export default Slide;
