import classNames from "classnames";
import { AppContext } from "context/app-context";
import { ComponentProps, FC, useContext } from "react";

import cn from "./Intro.module.scss";

type ownProps_type = {
  factoryName: string;
  factoryNameMobile?: string;
  title: string;
  intro: React.ReactNode;
  withShadow?: boolean;
  withCircle?: boolean;
  titleClassname?: string;
};

const Intro: FC<ownProps_type & ComponentProps<"div">> = ({
  factoryName,
  factoryNameMobile = "",
  title,
  intro,
  children,
  withShadow = false,
  withCircle = true,
  titleClassname = "",
  ...restProps
}) => {
  const { isMobile } = useContext(AppContext);

  return (
    <div {...restProps} className={classNames(restProps.className, cn.slide)}>
      <div className={cn.factory}>
        {factoryNameMobile && isMobile ? factoryNameMobile : factoryName}
      </div>
      <h1 className={classNames(cn.game__title, titleClassname)}>{title}</h1>
      <div className={cn.game__description}>{intro}</div>
      <div
        className={classNames(cn.game__image, {
          [cn.game__image_shadow]: withShadow,
          [cn.game__image_circle]: withCircle,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Intro;
