import classNames from "classnames";
import { FC } from "react";
import { color } from "types";

import cn from "./Background.module.scss";
import { ReactComponent as Figure1 } from "./img/figure1.svg";
import { ReactComponent as Figure2 } from "./img/figure2.svg";
import { ReactComponent as Figure3 } from "./img/figure3.svg";
import { ReactComponent as Figure4 } from "./img/figure4.svg";
import { ReactComponent as Figure5 } from "./img/figure5.svg";

type ownProps_type = {
  colorFrom: color;
  colorTo: color;
  baseCloudColor: color;
  cloudColor?: color | string;
  children?: React.ReactNode;
};

const Background: FC<ownProps_type> = ({
  colorFrom,
  colorTo,
  cloudColor,
  baseCloudColor,
  children,
}) => {
  const style = {
    "--color-from": colorFrom,
    "--color-to": colorTo,
  } as React.CSSProperties;

  return (
    <div className={cn.background}>
      {children}
      <Figure1
        className={classNames(cn.background__figure, cn.background__figure_1)}
        style={{ fill: cloudColor || baseCloudColor }}
      />
      <Figure2
        className={classNames(cn.background__figure, cn.background__figure_2)}
        style={{ fill: cloudColor || baseCloudColor }}
      />
      <Figure3
        className={classNames(cn.background__figure, cn.background__figure_3)}
        style={{ fill: cloudColor || baseCloudColor }}
      />
      <Figure4
        className={classNames(cn.background__figure, cn.background__figure_4)}
        style={{ fill: cloudColor || baseCloudColor }}
      />
      <Figure5
        className={classNames(cn.background__figure, cn.background__figure_5)}
        style={{ fill: cloudColor || baseCloudColor }}
      />
      <div className={cn.background__color} style={style}></div>
    </div>
  );
};

export default Background;
