import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import friendsImg from "./img/friends.png";
import handImg from "./img/hand.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={friendsImg} alt="Друзья" />
        <RobyMessage>
          А что еще можно напечатать? Вот Витя отправил меня к вам за колесами
          для лунохода.
        </RobyMessage>
        <Message name="Директор" image={directorImg}>
          С колесами мы точно справимся. Бывали у нас задачи и посложнее. Как вы
          думаете, что это такое?
        </Message>
        <Text>Директор указал на странный объект в углу.</Text>
        <VaryaMessage>Скелет динозавра?</VaryaMessage>
        <VityaMessage>Современная скульптура?</VityaMessage>
        <Message name="Директор" image={directorImg}>
          Не угадали! Это напечатанная на 3D-принтере точная копия позвоночника
          человека, точнее, пациента. Перед операцией спинальные хирурги
          отрабатывают все этапы вот на такой модели. И это не единственное
          применение наших устройств в медицине. Например, в РГУ им. Косыгина на
          них изготовили протез руки для пятилетнего мальчика.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#46646A" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          На 3D-принтерах печатают ортезы – современную замену гипса. Они не
          размокают, поэтому в них можно купаться.
        </p>
        <img src={handImg} alt="Протез" className={cn.block_bottom__image} />
      </div>
    </div>
  );
};

export default Slide;
