import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import Clips from "components/Blocks/Clips/Clips";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyGuard } from "games/Kits/Characters";
import { FC } from "react";

import technologistImg from "../../assets/img/technologist.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Text>
          В здешнем серьезном отношении к стерильности ребята убедились сразу
          же. Для начала им выдали ботинки, одноразовые халаты и шапочки, а
          потом отправили мыть руки. И не просто так, а под контролем
          автоматики, пока не загорится зеленая лампочка. Окончательно обработав
          руки антисептиком, ребята наконец смогли войти в цех, где их встретил
          технолог.
        </Text>
        <RobyMessage>
          Это правда, что немалую часть детской молочной продукции покупают
          взрослые?
        </RobyMessage>
        <Message name="Технолог" image={technologistImg}>
          Чистая правда. Мы выяснили, что почти треть наших взрослых покупателей
          берут в магазинах «Агушу» для себя. А знаете почему? Потому что к
          детской продукции предъявляются более строгие требования, и все
          прекрасно об этом знают. Так, например, творожки «Агуша» мы проверяем
          70 раз на разных этапах производства, чтобы подтвердить их качество и
          безопасность. И самое главное, для производства детской продукции
          используются отдельные линии, не связанные с основным производством. У
          нас на Лианозовском молочном комбинате это целый отдельный завод
          детского питания. Кстати, именно с него начиналась история завода,
          который с момента своего создания поставлял продукцию на молочные
          кухни Москвы.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#1D99C0" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          Качество творожков «Агуша» в процессе производства проверяют 70 раз.
        </p>
        <RobyGuard className={cn.block_bottom__roby} />
      </div>
    </div>
  );
};

export default Slide;
