import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import robotImg from "../../assets/img/robot.png";
import image from "./img/image.png";
import image2 from "./img/robot.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={image} alt="Робот" />
        <Text>
          Там ребят встретили директор предприятия Александр Кузнецов и… Роберт
          – существо с сияющими голубыми глазами и дисплеем на груди.
        </Text>
        <Message name="Роберт" image={robotImg}>
          Я робот. Мое имя Роберт.
        </Message>
        <RobyMessage>Ну здравствуй, тезка. Что ты умеешь?</RobyMessage>
        <Message name="Роберт" image={robotImg}>
          Я могу видеть и слышать. Еще я могу ездить и говорить.
        </Message>
        <Message name="Директор" image={directorImg}>
          Научили на свою голову. Когда монтируешь рядом двух роботов, между
          ними завязываются довольно неожиданные диалоги. Еще наш Роберт любит
          поболтать с Алисой – той, что из «Яндекса».
        </Message>
        <RobyMessage>Любому разумному существу необходимо общение.</RobyMessage>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#A74046" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          Это интересно!
        </p>
        <p>
          Робот FoR1 производства «Фора Роботикс» умеет самостоятельно
          передвигаться, распознавать людей и поддерживать разговор. Для того
          чтобы составить психологический профиль человека, ему требуется около
          40 минут.
        </p>
        <img src={image2} alt="Робот" className={cn.block_bottom__image} />
      </div>
    </div>
  );
};

export default Slide;
