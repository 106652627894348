import "./index.scss";

import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow.svg";
import classNames from "classnames";
import Modal from "components/Kits/Modals/Modal/Modal";
import { AppContext } from "context/app-context";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { connect, ConnectedProps } from "react-redux";
import { Link } from "react-router-dom";
import {
  getActiveChapterIndex,
  getGameChapters
} from "redux/selectors/app_selector";
import { AppStateType } from "redux/store";

import cn from "./Book.module.scss";

type ownProps_type = {
  arrowImg: string;
  bookImg: string;
  [x: string]: any;
};

type pageOwnProps_type = {
  title: string;
  link: string;
  poststamp?: {
    image: string;
    text: string;
  };
  showLink?: boolean;
};

const Page = React.forwardRef(
  (
    { title, link, poststamp, showLink = true }: pageOwnProps_type,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div className="page" ref={ref} data-density="hard">
        <div className={cn.page}>
          <p className={cn.page__title}>{title}</p>
          {poststamp && (
            <div className={cn.page__poststamp}>
              <img src={poststamp.image} alt={title} />
              <p className={cn.page__poststamp__text}>{poststamp.text}</p>
            </div>
          )}
          {showLink && (
            <Link to={link} className={cn.page__button}>
              Перейти
            </Link>
          )}
        </div>
      </div>
    );
  }
);

const Book: FC<ownProps_type & reduxProps_type> = ({
  arrowImg,
  bookImg,
  chapters,
  activeIndex,
  ...restProps
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { isMobile } = useContext(AppContext);

  const [page, setPage] = useState(0);
  const leftArrowDisabled = useMemo(() => {
    return page === 0;
  }, [page]);
  const [Pages, setPages] = useState<Array<any>>([]);
  const rightArrowDisabled = useMemo(() => {
    if (isMobile) {
      return page === Pages.length - 1;
    }

    return page === Pages.length - 2;
  }, [page, Pages, isMobile]);

  useEffect(() => {
    chapters.forEach((chapter, idx) => {
      if (chapter.id === activeIndex) {
        setPage(
          isMobile ? chapter.id - 1 : Math.floor((chapter.id - 1) / 2) * 2
        );
      }

      if (chapter.poststamps?.length) {
        chapter.poststamps.forEach((poststamp, pidx) => {
          setPages((pages) => {
            const newPages = [...pages];

            newPages.push(
              <Page
                key={`${idx}.${pidx}`}
                title={chapter.title}
                link={chapter.link}
                poststamp={poststamp}
                showLink={activeIndex !== chapter.id}
              />
            );

            return newPages;
          });
        });
      }
    });
  }, [activeIndex, chapters]);

  return (
    <div {...restProps} className={classNames(restProps.className, cn.slide)}>
      <div className={cn.image}>
        <div className={cn.image__circle}></div>
        <img
          src={bookImg}
          className={cn.image__book}
          onClick={() => {
            setIsModalVisible(true);
          }}
          alt="Книга"
        />
      </div>

      <img
        src={arrowImg}
        className={classNames(cn.arrow, cn.arrow_1)}
        alt="Стрелка"
      />
      <img
        src={arrowImg}
        className={classNames(cn.arrow, cn.arrow_2)}
        alt="Стрелка"
      />
      <img
        src={arrowImg}
        className={classNames(cn.arrow, cn.arrow_3)}
        alt="Стрелка"
      />
      <img
        src={arrowImg}
        className={classNames(cn.arrow, cn.arrow_4)}
        alt="Стрелка"
      />
      <img
        src={arrowImg}
        className={classNames(cn.arrow, cn.arrow_5)}
        alt="Стрелка"
      />
      <img
        src={arrowImg}
        className={classNames(cn.arrow, cn.arrow_6)}
        alt="Стрелка"
      />
      <Modal
        visible={isModalVisible}
        closeIconColor="#9D6B2D"
        closeModal={() => {
          setIsModalVisible(false);
        }}
      >
        <div className={cn.book}>
          <div
            className={classNames(cn.book__arrow, cn.book__arrow_prev, {
              [cn.book__arrow_disable]: leftArrowDisabled,
            })}
          >
            <ArrowIcon />
          </div>
          {
            //@ts-ignore
            <HTMLFlipBook
              width={300}
              height={500}
              showPageCorners={false}
              flippingTime={1000}
              onFlip={(n) => {
                setPage(n.data);
              }}
              startPage={page}
            >
              {Pages}
            </HTMLFlipBook>
          }
          <div
            className={classNames(cn.book__arrow, cn.book__arrow_next, {
              [cn.book__arrow_disable]: rightArrowDisabled,
            })}
          >
            <ArrowIcon />
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  chapters: getGameChapters(state),
  activeIndex: getActiveChapterIndex(state),
});

const connector = connect(mapStateToProps, {});
type reduxProps_type = ConnectedProps<typeof connector>;

export default connector(Book);
