import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyStatic } from "games/Kits/Characters";
import { FC } from "react";

import directorImg from "../../assets/img/director.png";
import image from "./img/image.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={image} alt="Наураша" />
        <Text>
          На предприятии их встретил директор компании Олег Поваляев, который и
          стал их экскурсоводом.
        </Text>
        <Message name="Директор" image={directorImg}>
          Что ж, коллеги, я рад приветствовать вас в Академии Наураши. А вот,
          кстати, и сам наш герой. Он умеет весело рассказывать про науку и
          помогает ребятам проводить множество интересных опытов. Хотите
          познакомиться с ним поближе?
        </Message>
        <VaryaMessage>
          Конечно. А он научит меня делать невидимые чернила?
        </VaryaMessage>
        <Message name="Директор" image={directorImg}>
          Обязательно. А еще при помощи танцующего маятника расскажет о
          магнетизме, измерит вам пульс и покажет, как выглядят звуковые волны.
          С наборами «Научные развлечения» можно устроить лазерное шоу, а можно
          – шоу мыльных пузырей, вырастить колонию бактерий и рассмотреть их под
          микроскопом.
        </Message>
        <VityaMessage>
          Позвольте, как я понимаю, Наураша – это компьютерный персонаж. Как же
          он узнает, правильно ли проведен опыт?
        </VityaMessage>
        <Message name="Директор" image={directorImg}>
          Все данные он получает вот с этого датчика, надо только подсоединить
          его к компьютеру.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8" />
      <div className={classNames("block", cn.block_bottom)}>
        <p
          style={{ fontSize: 48, lineHeight: "61px", marginBottom: 30 }}
          className="title"
        >
          Справка
        </p>
        <p>
          За прошлый год «Научные развлечения» выпустили 30 тысяч цифровых
          лабораторий. Сейчас их наборы используют 60 тысяч детских садов, школ
          и университетов России и мира.
        </p>
        <RobyStatic className={cn.block_bottom__roby} />
      </div>
    </div>
  );
};

export default Slide;
