import classNames from "classnames";
import { FC } from "react";

import robyImg from "./img/Roby.png";
import varyaImg from "./img/Varya.png";
import vityaImg from "./img/Vitya.png";
import cn from "./Message.module.scss";

type ownProps_type = {
  children: React.ReactNode;
  type?: "Varya" | "Roby" | "Vitya";
  image?: string;
  name?: string;
  [x: string]: any;
};

const Message: FC<ownProps_type> = ({
  type,
  image,
  name,
  children,
  ...restProps
}) => {
  let imageSrc: string | undefined = "";
  let imageName: string | undefined = "";

  switch (type) {
    case "Varya":
      imageSrc = varyaImg;
      imageName = "Варя";

      break;

    case "Roby":
      imageSrc = robyImg;
      imageName = "Роби";

      break;

    case "Vitya":
      imageSrc = vityaImg;
      imageName = "Витя";

      break;

    default:
      imageSrc = image;
      imageName = name;
      break;
  }

  return (
    <div {...restProps} className={classNames(cn.message, restProps.className)}>
      <div className={cn.message__author}>
        <img
          src={imageSrc}
          className={cn.message__author__image}
          alt={type || imageName}
        />
        <span className={cn.message__author__name}>{imageName}</span>
      </div>
      <div className={cn.message__body}>{children}</div>
    </div>
  );
};

export default Message;
