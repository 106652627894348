import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyGlasses } from "games/Kits/Characters";
import VaryaInvitation from "games/Kits/Game/Invitation/Varya/Varya";
import { FC } from "react";

import doctorImg from "../../assets/img/doctor.png";
import factoryImg from "./img/factory.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <RobyMessage>
          Простите. Могу ли я переговорить с коллегой, который заведует складом?
        </RobyMessage>
        <VaryaMessage>
          Зачем тебе очки, Роби? У тебя же стопроцентное зрение!
        </VaryaMessage>
        <RobyMessage>
          Прости, но у меня к нему просьба личного характера.
        </RobyMessage>
        <Text>
          И инженер повел Роби в соседнее помещение, остановившись у огромного,
          до потолка, шкафа-колонны.
        </Text>
        <Message name="Главный инженер" image={doctorImg}>
          Вот знакомьтесь, это наш новичок, он хранит линзы и оправы и может за
          пару минут найти и доставить вам нужные.
        </Message>
        <Text>
          Роби склонился над компьютерным терминалом, робот поморгал цветными
          индикаторами, и вскоре небольшой лифт доставил Роби стильные темные
          очки, которые тот с гордостью надел.
        </Text>
        <RobyMessage>
          Попрошу без комментариев. Мы с моей подругой Viki-Viki собираемся на
          рок-концерт, и мне не хотелось бы ударить в грязь… Чем там роботы
          могут ударить в грязь?..
        </RobyMessage>
        <Image src={factoryImg} alt="Фабрика">
          <RobyGlasses className={cn.factory_roby} />
        </Image>
      </WhiteBlock>
      <VaryaInvitation />
    </div>
  );
};

export default Slide;
