import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { FC } from "react";

import imageSrc from "./img/image.jpg";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={imageSrc} alt="Друзья" />
        <VaryaMessage>
          Смотрите, сколько марок с первыми компьютерами!
        </VaryaMessage>
        <RobyMessage>Очень приятно познакомиться с пращурами.</RobyMessage>
        <VaryaMessage>
          Допустим, что-то из этих раритетов попало в музеи, что-то пылится на
          чердаках. А остальное куда?
        </VaryaMessage>
        <VityaMessage>
          На переработку. Тебе как экоактивистке полезно познакомиться с
          московской корпорацией «Экополис». В ее составе три предприятия: на
          «Экотехпроме» в огромных шредерах перемалывают все, что втыкается в
          розетку и работает от батарейки, крошево пластика отправляют на
          «Экопласт» в Печатниках, а измельченные печатные платы, в которых
          содержатся драгоценные металлы, – на завод «Аурус» в Орловскую
          область. На Орловщину мы не поедем, а два московских предприятия
          предлагаю посетить.
        </VityaMessage>
        <RobyMessage>
          Витя, когда я устарею, ты сдашь меня на переработку? И я вернусь в
          виде капота, бампера и микросхем Вариного электромобиля.
        </RobyMessage>
        <VityaMessage>
          Не волнуйся, Роби. Я тебя модернизирую, и ты снова будешь с нами. А
          сейчас едем в «Экотехпром».
        </VityaMessage>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
