import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaDialogue } from "games/Kits/Characters";
import { FC } from "react";

import doctorImg from "../../assets/img/doctor.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <Text className={cn.text}>
        В просторном помещении с окнами до самого потолка компанию встретил
        главный инженер предприятия по имени Алексей – высокий дяденька, похожий
        на доброго учителя.
      </Text>
      <WhiteBlock className={cn.white}>
        <Message name="Главный инженер" image={doctorImg}>
          Вот, Роби, ты можешь гордиться: на нашем производстве работают пятеро
          твоих коллег – четверо обтачивают линзы, а пятый заведует складом.
        </Message>
        <RobyMessage>
          Приятно слышать. Мне бы хотелось познакомиться с ними поближе, но,
          похоже, они очень заняты.
        </RobyMessage>
        <Message name="Главный инженер" image={doctorImg}>
          Боюсь, что им не до разговоров. Вот по этому конвейеру едут линзы.
          Датчик считывает штрих-код на упаковке, передает информацию в
          компьютер, и тот направляет заказ конкретному роботу, который
          приступает к обточке линз. Она, кстати, длится всего 55 секунд.
        </Message>
        <VityaMessage>Так быстро?</VityaMessage>
        <Message name="Главный инженер" image={doctorImg}>
          Да, скорость станка составляет 30 тысяч оборотов в минуту. Роботы
          работают не только быстро, но и очень точно. За последний год они
          совершили всего одну ошибку, а ведь мы выпускаем почти 2500 пар очков
          в день.
        </Message>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#500A73" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>
          ЭТО ИНТЕРЕСНО!
        </p>
        <p>
          От получения заказа до появления готовых очков проходит всего полтора
          часа.
        </p>
        <VaryaDialogue className={cn.block_bottom__varya} />
      </div>
    </div>
  );
};

export default Slide;
