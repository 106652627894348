import { ReactComponent as PlayIcon } from "assets/images/icons/play.svg";
import classNames from "classnames";
import Message from "components/Blocks/Chat/Message/Message";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import Text from "components/Blocks/Text/Text";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import Modal from "components/Kits/Modals/Modal/Modal";
import { RobyStatic } from "games/Kits/Characters";
import { FC, useCallback, useRef, useState } from "react";
import { BigPlayButton, Player } from "video-react";

import directorImg from "../../assets/img/director.png";
import image from "./img/image.png";
import tvImg from "./img/tv.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false),
    videoRef = useRef<HTMLVideoElement | null>(null);

  const closeModal = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.pause();
    }

    setIsModalVisible(false);
  }, [videoRef, setIsModalVisible]);

  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={image} alt="Наураша" />
        <Message name="Директор" image={directorImg}>
          Большинство роботов у нас похожи на людей, но есть и R2D2 из «Звездных
          войн. Он двигается, поворачивает голову, моргает индикаторами и
          чирикает на своем языке. Его мы отправляем на детские праздники, дети
          от него без ума. А для более серьезных задач у нас есть TrueBot. Он
          умеет анализировать голос и манеру речи человека по восьми параметрам
          и определять, говорит ли он правду. Почти как детектор лжи, только не
          нужно опутывать испытуемого проводами.
        </Message>
        <VaryaMessage>А может он понять характер человека?</VaryaMessage>
        <Message name="Директор" image={directorImg}>
          Есть программы, которые оценивают психологическую совместимость. Это
          важно выяснить, если людям предстоит долго работать вместе в
          изолированном пространстве, например в космосе. Однажды один из наших
          сотрудников позвал робота, оснащенного такой программой, на свою
          свадьбу. И буквально за минуту до росписи робот подтвердил, что жених
          и невеста подходят друг другу.
        </Message>
        <VaryaMessage>
          А не боитесь ли вы, что когда-нибудь все роботы станут такими же
          умными, как наш Роби, и полностью заменят людей?
        </VaryaMessage>
        <Message name="Директор" image={directorImg}>
          Роботы уже сейчас заменяют людей на тяжелой и монотонной работе. Вы же
          не переживаете, что прачки – наверняка очень достойные женщины –
          полностью вытеснены стиральными машинами? Не боитесь конкуренции с
          роботом-пылесосом?
        </Message>
        <Text>Промышленные приключения Вити и Роби</Text>
        <div className={cn.block_tv}>
          <img src={tvImg} className={cn.block_tv__img} alt="Телевизор" />
          <div className={cn.block_tv__inner}>
            <span className="title">Смотреть видео</span>
            <div className={cn.block_tv__play}>
              <PlayIcon onClick={() => setIsModalVisible(true)} />
            </div>
          </div>
        </div>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>Справка</p>
        <p>
          За время своего существования компания «Фора Роботикс» создала 18
          роботов.
        </p>
        <RobyStatic className={cn.block_bottom__roby} />
      </div>
      <Modal
        visible={isModalVisible}
        closeModal={closeModal}
        closeIconColor="#ffffff"
      >
        <div className={cn.block_video}>
          <Player ref={videoRef}>
            <BigPlayButton position="center" />
            <source src="https://children.prommoscow.info/video/7.mp4" />
          </Player>
        </div>
      </Modal>
    </div>
  );
};

export default Slide;
