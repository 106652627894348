import classNames from "classnames";
import React, { FC } from "react";

import cn from "./Button.module.scss";

type ownProps_type = {
  children: React.ReactNode;
  variant?: "red";
  [x: string]: any;
};

const Button: FC<ownProps_type> = ({ children, variant, ...restProps }) => {
  return (
    <button
      {...restProps}
      className={classNames(
        cn.button,
        { [cn.button_red]: variant === "red" },
        restProps.className
      )}
    >
      {children}
    </button>
  );
};

export default Button;
