import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import Clips from "components/Blocks/Clips/Clips";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaDialogue } from "games/Kits/Characters";
import { FC } from "react";

import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <RobyMessage>
          Все про кабели знают на заводе «Москабельмет». – Ведь там их выпускают
          с 1895 года. Давайте туда съездим, это всего в шести километрах от
          Кремля.
        </RobyMessage>
        <VaryaMessage>Поехали. Все равно пока интернета нет.</VaryaMessage>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#4D4D4D" />
      <div className={classNames("block", cn.block_bottom)}>
        <p>
          «Москабельмет» – наследник первого кабельного производства в России.
          Сегодня это целая группа компаний – пять заводов, торговый дом,
          лаборатории, служба поддержки покупателей и центр здоровья!
        </p>
        <VaryaDialogue className={cn.block_bottom__varya} />
      </div>
    </div>
  );
};

export default Slide;
