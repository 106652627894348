import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import { AppContext } from "context/app-context";
import { RobyWinner, VaryaHello, VityaHello } from "games/Kits/Characters";
import { FC, useContext } from "react";

import backImg from "./img/back.png";
import backImgMob from "./img/back_mob.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  const { isMobile } = useContext(AppContext);

  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <p className={classNames("title", cn.title)}>Здравствуй, друг!</p>
      <p>
        Ты, возможно, уже знаком с инженером Витей, его сестренкой Варей и
        роботом Роби, которые совершают увлекательные экскурсии по московским
        предприятиям.
      </p>
      {isMobile && (
        <div className={cn["dialog-mobile"]}>
          <VaryaMessage>Привет!</VaryaMessage>
          <RobyMessage>Здорово, что ты пришел к нам в гости!</RobyMessage>
          <VityaMessage>Рад тебя видеть!</VityaMessage>
        </div>
      )}
      <Image
        src={isMobile ? backImgMob : backImg}
        alt="Привет!"
        className={cn.image}
      >
        <VaryaHello className={cn.image__varya} />
        <RobyWinner className={cn.image__roby} />
        <VityaHello className={cn.image__vitya} />
      </Image>
    </div>
  );
};

export default Slide;
