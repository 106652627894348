import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Clips from "components/Blocks/Clips/Clips";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { RobyStatic } from "games/Kits/Characters";
import { FC } from "react";

import backImg from "./img/back.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={backImg} alt="Фон" />
        <VaryaMessage>
          Когда я получу права, все уже будут ездить на электромобилях.
        </VaryaMessage>
        <VityaMessage>
          Скорее всего. Но пока бензин никто не отменял. А вот обеспечить
          автопарк столицы качественным и здоровым питанием – с этой задачей
          отлично справляется Московский нефтеперерабатывающий завод, сокращенно
          МНПЗ.
        </VityaMessage>
        <RobyMessage>
          Хм... Надеюсь, мне двигатель внутреннего сгорания никогда не
          понадобится, но было бы любопытно посмотреть.
        </RobyMessage>
        <VityaMessage>Так за чем дело стало? Завтра и посмотрим!</VityaMessage>
      </WhiteBlock>
      <Clips topColor="#D0D0D0" bottomColor="#2970A8" />
      <div className={classNames("block", cn.block_bottom)}>
        <p className={classNames("title", cn.block_bottom__title)}>СПРАВКА</p>
        <p>
          Московский НПЗ за год перерабатывает 11 миллионов тонн нефти. На
          произведенном топливе ездит каждый третий автомобиль региона. А из
          битума, выпущенного МНПЗ, построена каждая вторая дорога Москвы и
          области.
        </p>
        <RobyStatic className={cn.block_bottom__roby} />
      </div>
    </div>
  );
};

export default Slide;
