import { ReactComponent as CloseIcon } from "assets/images/icons/close.svg";
import classNames from "classnames";
import { AppContext } from "context/app-context";
import RobyGame from "games/Kits/Game/Game/Roby/Roby";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { DragDropContainer, DropTarget } from "react-drag-drop-container";
import { chapter_type } from "types";

import light from "../assets/img/light.png";
import cn from "./Game.module.scss";
import arrowImg from "./img/arrow.png";
import arrowMobileImg from "./img/arrow_mobile.png";
import carrotImg from "./img/carrot.png";
import eggplantImg from "./img/eggplant.png";
import explanationImg from "./img/explanation.png";
import saladImg from "./img/salad.png";
import strawberryImg from "./img/strawberry.png";

const items = [
  {
    img: strawberryImg,
    title: "Клубника",
    isError: false,
  },
  {
    img: saladImg,
    title: "Салат",
    isError: false,
  },
  {
    img: eggplantImg,
    title: "Баклажан",
    isError: false,
  },
  {
    img: carrotImg,
    title: "Морковь",
    isError: false,
  },
];

const initialMatrix = [
  [0, null, null, null],
  [3, 0, null, null],
  [null, null, 2, null],
  [2, 1, null, 3],
];

type ownProps_type = {
  nextChapter: chapter_type;
  onGameFinished: () => void;
  returnBack: () => void;
};

const Game: FC<ownProps_type> = ({
  nextChapter,
  onGameFinished,
  returnBack,
}) => {
  const { isMobile } = useContext(AppContext);

  const [list] = useState(items);
  const [matrix, setMatrix] = useState(initialMatrix);
  const [gameState, setGameState] = useState<"static" | "failure" | "finish">(
    "static"
  );
  const [hoveredPosition, setHoveredPosition] = useState<Array<null | number>>([
    null,
    null,
  ]);
  const [errorPosition, setErrorPosition] = useState<Array<null | number>>([
    null,
    null,
  ]);
  const [robyStateData] = useState({
    static:
      "Помоги разложить продукты на полке в магазине так, чтобы они не повторялись по вертикали и горизонтали.",
    failure: "Почти получилось! Попробуй еще! ",
    finish: "Молодец! У тебя отлично получается!",
  });

  const gameFinished = useMemo(() => {
    return !matrix.filter((row) => row.includes(null)).length;
  }, [matrix]);

  useEffect(() => {
    if (gameFinished) {
      setGameState("finish");
      onGameFinished();
    }
  }, [gameFinished, onGameFinished]);

  const setMatrixItem = (
    rowIndex: number,
    cellIndex: number,
    value: number | null
  ) => {
    setMatrix((matrix) => {
      const newMatrix = [...matrix];

      newMatrix[rowIndex][cellIndex] = value;

      return newMatrix;
    });
  };

  return (
    <div className={cn.game}>
      <div className={cn.background}>
        <div className={cn.background__color}></div>
        <img className={cn.background__light_top} src={light} alt="Свет" />
        <img className={cn.background__light_bottom} src={light} alt="Свет" />
      </div>
      <div className={cn.grid}>
        {matrix.map((row, rowIndex) => {
          return (
            <div className={cn.grid__row} key={rowIndex}>
              {row.map((cell, cellIndex) => {
                return (
                  <DropTarget
                    targetKey="drop"
                    key={`${rowIndex}.${cellIndex}`}
                    onDragEnter={() =>
                      setHoveredPosition([rowIndex, cellIndex])
                    }
                    onDragLeave={() => setHoveredPosition([null, null])}
                    onHit={(e: any) => {
                      const droppedIndex = e.dragData.index;
                      const droppedCellRow = matrix[rowIndex];
                      const droppedCellColumn = matrix.map(
                        (row) => row[cellIndex]
                      );

                      if (
                        droppedCellRow.includes(droppedIndex) ||
                        droppedCellColumn.includes(droppedIndex)
                      ) {
                        setErrorPosition([rowIndex, cellIndex]);
                        setGameState((gameState) =>
                          gameState === "finish" ? gameState : "failure"
                        );

                        setTimeout(() => {
                          setErrorPosition([null, null]);
                          setGameState((gameState) =>
                            gameState === "finish" ? gameState : "static"
                          );
                        }, 2000);
                        return false;
                      }

                      setMatrixItem(rowIndex, cellIndex, droppedIndex);
                      setErrorPosition([null, null]);
                      setGameState((gameState) =>
                        gameState === "finish" ? gameState : "static"
                      );
                    }}
                  >
                    <div
                      className={classNames(cn.grid__cell, {
                        [cn.grid__cell_hovered]:
                          rowIndex === hoveredPosition[0] &&
                          cellIndex === hoveredPosition[1],
                        [cn.grid__cell_error]:
                          rowIndex === errorPosition[0] &&
                          cellIndex === errorPosition[1],
                      })}
                    >
                      {cell !== null && (
                        <>
                          {gameState !== "finish" && (
                            <div
                              className={cn.grid__remove}
                              onClick={() =>
                                setMatrixItem(rowIndex, cellIndex, null)
                              }
                            >
                              <CloseIcon />
                            </div>
                          )}

                          <div className={cn.grid__item}>
                            <p className="title">{list[cell].title}</p>
                            <img src={list[cell].img} alt={list[cell].title} />
                          </div>
                        </>
                      )}
                    </div>
                  </DropTarget>
                );
              })}
            </div>
          );
        })}
      </div>
      <div
        className={classNames(cn.side, {
          [cn.side_hidden]: gameState === "finish",
        })}
      >
        <div className={cn.side__top}>
          <img
            src={isMobile ? arrowMobileImg : arrowImg}
            alt="Стрелка"
            className={cn.side__top__arrow}
          />
          <div className={cn.items}>
            {list.map((item, index) => (
              <div className={cn.items__item} key={index}>
                <DragDropContainer
                  targetKey="drop"
                  dragData={{ ...item, index }}
                  onDragEnd={() => setHoveredPosition([null, null])}
                >
                  <img src={item.img} alt={item.title} />
                </DragDropContainer>
              </div>
            ))}
          </div>
        </div>
        <div className={cn.game__explanation}>
          <img src={explanationImg} alt="Пояснение" />
          <p>Перемещай карточки перетягиванием</p>
        </div>
      </div>

      <RobyGame
        gameState={gameState}
        stateData={robyStateData}
        nextGameLink={nextChapter.link}
        returnBack={returnBack}
      />
    </div>
  );
};

export default Game;
