import Intro from "games/Kits/Intro/Intro";
import { FC } from "react";

import shoeImg from "./img/shoe.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div className={cn.slide}>
      <Intro
        factoryName="Фабрика «Парижская коммуна»"
        title="Весело шагать"
        intro="Глава, в которой Витя моделирует обувь, а Варя с Роби приклеивают подошвы"
      >
        <img src={shoeImg} className={cn.game__shoe} alt="Ботинок" />
      </Intro>
    </div>
  );
};

export default Slide;
