import { ComponentProps, FC } from "react";

import robyDialogueSrc from "./Roby/dialogue.gif";
import robyFailureSrc from "./Roby/failure.gif";
import robyGlassesSrc from "./Roby/glasses.gif";
import robyGuardSrc from "./Roby/guard.gif";
import robyIcecreamSrc from "./Roby/icecream.gif";
import robyInfoSrc from "./Roby/info.gif";
import robyStaticSrc from "./Roby/static.gif";
import robyWinnerSrc from "./Roby/winner.gif";
import varyaDialogueSrc from "./Varya/dialogue.gif";
import varyaFailureSrc from "./Varya/failure.gif";
import varyaGlassesSrc from "./Varya/glasses.gif";
import varyaGlassesStarSrc from "./Varya/glasses2.gif";
import varyaHellorSrc from "./Varya/hello.gif";
import varyaStaticSrc from "./Varya/static.gif";
import varyaWinnerSrc from "./Varya/winner.gif";
import varyaYogurtSrc from "./Varya/yogurt.gif";
import vityaDialogueSrc from "./Vitya/dialogue.gif";
import vityaFailureSrc from "./Vitya/failure.gif";
import vityaHellorSrc from "./Vitya/hello.gif";
import vityaStaticSrc from "./Vitya/static.gif";
import vityaWinnerSrc from "./Vitya/winner.gif";
import vityaWowrSrc from "./Vitya/wow.gif";

type imageProps_type = ComponentProps<"img">;

export const RobyStatic: FC<imageProps_type> = (props) => {
  return <img {...props} src={robyStaticSrc} alt="Роби" />;
};
export const RobyDialogue: FC<imageProps_type> = (props) => {
  return <img {...props} src={robyDialogueSrc} alt="Роби" />;
};
export const RobyFailure: FC<imageProps_type> = (props) => {
  return <img {...props} src={robyFailureSrc} alt="Роби" />;
};
export const RobyGlasses: FC<imageProps_type> = (props) => {
  return <img {...props} src={robyGlassesSrc} alt="Роби" />;
};
export const RobyGuard: FC<imageProps_type> = (props) => {
  return <img {...props} src={robyGuardSrc} alt="Роби" />;
};
export const RobyIcecream: FC<imageProps_type> = (props) => {
  return <img {...props} src={robyIcecreamSrc} alt="Роби" />;
};
export const RobyInfo: FC<imageProps_type> = (props) => {
  return <img {...props} src={robyInfoSrc} alt="Роби" />;
};
export const RobyWinner: FC<imageProps_type> = (props) => {
  return <img {...props} src={robyWinnerSrc} alt="Роби" />;
};

export const VaryaDialogue: FC<imageProps_type> = (props) => {
  return <img {...props} src={varyaDialogueSrc} alt="Варя" />;
};
export const VaryaFailure: FC<imageProps_type> = (props) => {
  return <img {...props} src={varyaFailureSrc} alt="Варя" />;
};
export const VaryaGlasses: FC<imageProps_type> = (props) => {
  return <img {...props} src={varyaGlassesSrc} alt="Варя" />;
};
export const VaryaGlassesStar: FC<imageProps_type> = (props) => {
  return <img {...props} src={varyaGlassesStarSrc} alt="Варя" />;
};
export const VaryaStatic: FC<imageProps_type> = (props) => {
  return <img {...props} src={varyaStaticSrc} alt="Варя" />;
};
export const VaryaWinner: FC<imageProps_type> = (props) => {
  return <img {...props} src={varyaWinnerSrc} alt="Варя" />;
};
export const VaryaYogurt: FC<imageProps_type> = (props) => {
  return <img {...props} src={varyaYogurtSrc} alt="Варя" />;
};
export const VaryaHello: FC<imageProps_type> = (props) => {
  return <img {...props} src={varyaHellorSrc} alt="Варя" />;
};

export const VityaDialogue: FC<imageProps_type> = (props) => {
  return <img {...props} src={vityaDialogueSrc} alt="Витя" />;
};
export const VityaFailure: FC<imageProps_type> = (props) => {
  return <img {...props} src={vityaFailureSrc} alt="Витя" />;
};
export const VityaStatic: FC<imageProps_type> = (props) => {
  return <img {...props} src={vityaStaticSrc} alt="Витя" />;
};
export const VityaWinner: FC<imageProps_type> = (props) => {
  return <img {...props} src={vityaWinnerSrc} alt="Витя" />;
};
export const VityaWow: FC<imageProps_type> = (props) => {
  return <img {...props} src={vityaWowrSrc} alt="Витя" />;
};
export const VityaHello: FC<imageProps_type> = (props) => {
  return <img {...props} src={vityaHellorSrc} alt="Витя" />;
};
