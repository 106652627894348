import Intro from "games/Kits/Intro/Intro";
import { FC } from "react";

import barrelImg from "./img/barrel.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div className={cn.slide}>
      <Intro
        factoryName="Московский нефтеперерабатывающий завод"
        factoryNameMobile="МНПЗ"
        title="Полный бак"
        intro="Глава, в которой Витя, Варя и Роби узнают, зачем нефтеперерабатывающему заводу был нужен фанерный двойник"
      >
        <img src={barrelImg} className={cn.game__barrel} alt="Бочка" />
      </Intro>
    </div>
  );
};

export default Slide;
