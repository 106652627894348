import classNames from "classnames";
import Carousel from "components/Blocks/Carousel/Carousel";
import Text from "components/Blocks/Text/Text";
import { FC, useEffect } from "react";

import cn from "./Slide.module.scss";

const slides = [
  "1-(54).jpg",
  "DSC_1278-r.jpg",
  "SAM06056.jpg",
  "SAM06663.jpg",
  "SAM07003.jpg",
  "slide_01.jpg",
  "slide_02.jpg",
  "slide_03.jpg",
];

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = ({
  changeBackColor,
  changeCloudsColor,
  ...props
}) => {
  useEffect(() => {
    changeBackColor("#612072", "#612072");
    changeCloudsColor("#4B1359");

    return () => {
      changeBackColor();
      changeCloudsColor();
    };
  }, [changeBackColor, changeCloudsColor]);

  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <Text className={classNames("title", cn.slide__title)}>
        Прогулки по космическому центру имени Хруничева
      </Text>
      <Carousel
        slides={slides}
        dir="Hrunicheva"
        arrowColor="rgba(75, 19, 89, 0.6)"
        arrowColorHover="rgba(75, 19, 89, 0.3)"
      />
    </div>
  );
};

export default Slide;
