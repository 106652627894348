import classNames from "classnames";
import RobyMessage from "components/Blocks/Chat/Message/RobyMessage";
import VaryaMessage from "components/Blocks/Chat/Message/VaryaMessage";
import VityaMessage from "components/Blocks/Chat/Message/VityaMessage";
import Image from "components/Blocks/Image/Image";
import WhiteBlock from "components/Blocks/White/WhiteBlock";
import { VaryaStatic } from "games/Kits/Characters";
import { FC } from "react";

import backImg from "./img/back.png";
import cn from "./Slide.module.scss";

type ownProps_type = {
  [x: string]: any;
};

const Slide: FC<ownProps_type> = (props) => {
  return (
    <div {...props} className={classNames(props.className, cn.slide)}>
      <WhiteBlock className={cn.white}>
        <Image src={backImg} alt="Фон" className={cn.image}>
          <VaryaStatic className={cn.image__varya} />
        </Image>
        <VaryaMessage>
          Сколько у нашего прадедушки марок с ракетами и космонавтами!
        </VaryaMessage>
        <VityaMessage>
          Еще бы, тогда все дети мечтали о космосе. Думаю, и сейчас мечтают,
          причем не только дети. А главные мечтатели идут на работу в Центр
          имени Хруничева на Новозаводской улице, чтобы строить ракеты.
        </VityaMessage>
        <VaryaMessage>Но в Москве же нет космодрома?</VaryaMessage>
        <VityaMessage>
          А ракеты есть. Прямо в сборочном цеху их грузят на железнодорожную
          платформу и отправляют в путешествие длиной 2,5 тысячи километров, на
          Байконур. Или почти 8 тысяч километров – до космодрома Восточный.
        </VityaMessage>
        <RobyMessage>
          Вот что, друзья. Предлагаю туда съездить. Я давно хотел побывать в
          Центре имени Хруничева. Вы же, надеюсь, понимаете, что будущее
          освоения космоса за роботами?
        </RobyMessage>
      </WhiteBlock>
    </div>
  );
};

export default Slide;
